import React, { useState } from 'react';
import { Toggler } from '../../common/Toggler';

const EVENT_FEE_TYPE_PLACEHOLDER = 'cena';
const EVENT_FEE_VALUE_PLACEHOLDER = 'PLN';

const NEGATIVE_PRICE_ERROR_MSG = 'Cena minimum to 2 PLN';

const feeTypes = [
    { display: 'Stała', value: 'FIXED_FEE' },
    //    {display: "Darowizna", value: 'DONATION'},
    { display: 'Nie wymagana', value: 'NOT_REQUIRED' },
];

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    nextStepCallback: () => void;
    previousStepCallback: () => void;
}

export const CollectEventPricing = ({
    cancelCallback,
    inputValues,
    inputHandler,
    previousStepCallback,
    nextStepCallback,
}: Props) => {
    const [errors, setErrors] = useState({});

    const validateInputAndCallNextStep = () => {
        let validationResult = validateInput(inputValues);
        setErrors(validationResult.errors);

        if (validationResult.isValid) {
            nextStepCallback();
        }
    };

    // this is a workaround to set the correct boolean value
    const toEvent = (name, flag) => {
        return { target: { name: name, value: flag } };
    };

    return (
        <>
            <div className="fs-5 fw-light">Płatność 1/2</div>

            {/************ EVENT FEE TYPE ************/}
            <div className="form-floating">
                <select
                    className={'form-select ' + (errors.feeType && 'is-invalid')}
                    id="feeTypeSelect"
                    name="feeType"
                    value={inputValues.feeType}
                    onChange={inputHandler}
                >
                    <option value="" disabled>
                        {EVENT_FEE_TYPE_PLACEHOLDER}
                    </option>
                    {feeTypes?.map((feeType) => (
                        <option key={feeType.value} value={feeType.value}>
                            {feeType.display}
                        </option>
                    ))}
                </select>
                <label htmlFor="feeTypeSelect">{EVENT_FEE_TYPE_PLACEHOLDER}</label>

                {/************ DISPLAY ERROR BELOW ************/}
                {errors.feeType && (
                    <span id="feeTypeError" className="invalid-feedback">
                        {errors.feeType}
                    </span>
                )}
            </div>

            <Toggler condition={inputValues.feeType === 'FIXED_FEE'}>
                <div className="d-flex flex-row">
                    <div className="form-floating">
                        <input
                            type="number"
                            className={'form-control ' + (errors.feeValue && 'is-invalid')}
                            id="feeValueInput"
                            min={2}
                            step={1}
                            name="feeValue"
                            value={inputValues.feeValue}
                            onChange={inputHandler}
                        />
                        <label htmlFor="feeValueInput">{EVENT_FEE_VALUE_PLACEHOLDER}</label>
                        {/************ DISPLAY ERROR BELOW ************/}
                        {errors.feeValue && (
                            <span id="feeValueError" className="invalid-feedback">
                                {errors.feeValue}
                            </span>
                        )}
                    </div>
                </div>
            </Toggler>

            <Toggler condition={inputValues.feeType === 'DONATION'}>
                <div className="mt-3 text-muted text-center">
                    <span>Cena dobrowolna - ustalana przez uczestnika zajęć</span>
                </div>
            </Toggler>

            <Toggler condition={inputValues.feeType === 'NOT_REQUIRED'}>
                <div className="mt-3 text-muted text-center">
                    <span>Cena nie będzie wymagana od uczestnika</span>
                </div>
            </Toggler>

            <hr />

            {/* REQUIRES ATTENDANCE CHECK SWITCH */}
            <div className="form-check form-switch">
                <input
                    className="form-check-input pointer"
                    type="checkbox"
                    role="switch"
                    id="requiresAttendanceCheckSwitch"
                    name="requiresAttendanceCheck"
                    onChange={() =>
                        inputHandler(toEvent('requiresAttendanceCheck', !inputValues.requiresAttendanceCheck))
                    }
                    checked={inputValues.requiresAttendanceCheck}
                />
                <label className="form-check-label pointer" htmlFor="requiresAttendanceCheckSwitch">
                    Sprawdzaj obecność
                </label>
            </div>

            <hr />

            <div className="d-grid gap-2 d-flex justify-content-center">
                {/************ BACK ************/}
                <button
                    type="button"
                    className="form-floating btn btn-secondary m-2"
                    onClick={() => previousStepCallback()}
                >
                    Cofnij
                </button>

                {/************ CONTINUE ************/}
                <button
                    type="button"
                    className="form-floating btn btn-primary m-2"
                    onClick={validateInputAndCallNextStep}
                >
                    Dalej
                </button>
            </div>
        </>
    );
};

const validateInput = (input) => {
    let validationResult = { isValid: true, errors: {} };

    if (input.feeType === 'FIXED_FEE' && (!input.feeValue || input.feeValue < 2.0)) {
        validationResult.errors.feeValue = NEGATIVE_PRICE_ERROR_MSG;
        validationResult.isValid = false;
    }

    return validationResult;
};
