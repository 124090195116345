import React from "react";

interface Props {
    children: any;
    theme?: string; // primary | secondary | success | danger | warning | info | light |dark 
}

export const Alert = ({theme = 'primary', children}: Props) => {
    const cn = theme ? `alert-${theme}` : 'alert-primary';

    return (
        <div className={`alert ${cn}`} role="alert">{children}</div>
    )
}
