import React, {useState} from "react";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {SPCard} from "../common/SPCard";

const errorState = {
    firstName: '',
    lastName: '',
    externalId: ''
};

const FIRST_NAME_PLACEHOLDER = 'imię ucznia';
const LAST_NAME_PLACEHOLDER = 'nazwisko ucznia';
const EXTERNAL_ID_PLACEHOLDER = 'numer identyfikacyjny';

interface Params {
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const LearnerLookup = ({cancelCallback, refreshViewCallback}: Params) => {
    const {getAccessTokenSilently} = useAuth0();

    const [pageState, setPageState] = useState('LEARNER-LOOKUP');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [externalId, setExternalId] = useState('');
    const [learner, setLearner] = useState(null);
    const [errors, setErrors] = useState(errorState);

    const handleSubmit = () => {
        const err = validate({
            firstName: firstName,
            lastName: lastName,
            externalId: externalId
        });
        setErrors(err);

        if (err.isValid) {
            getAccessTokenSilently()
                .then(accessToken => {
                    apiClient(accessToken).get(`/learners?firstName=${firstName}&lastName=${lastName}&externalId=${externalId}`)
                        .then(res => {
                            if (res.data && res.data.length === 1) {
                                setLearner(res.data[0]);
                                setPageState('CONFIRM-LEARNER');
                            } else {
                                setPageState('LEARNER-LOOKUP-ERROR');
                            }
                        });
                });
        }
    }

    const addLearnerAsMyDependant = (learner) => {
        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                  action: 'ADD_LEARNER_AS_DEPENDANT'
                }
                apiClient(accessToken).patch(`/learners/${learner.id}`, payload)
                    .then(() => refreshViewCallback());
            })
    }

    return <>
        {pageState === 'LEARNER-LOOKUP' && <>
            <div className="container text-center needs-validation">
                <div className="row justify-content-center m-5">
                    <div className="col-9 col-sm-6 col-md-4 col-lg-3 fw-bolder fs-5">Znajdź ucznia</div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-9 col-sm-6 col-md-4 col-lg-3">

                        {/************ LEARNER FIRST NAME ************/}
                        <div className="form-floating mb-3">
                            <input type="text"
                                   className={"form-control " + (errors.firstName && 'is-invalid')}
                                   id="firstName"
                                   placeholder={FIRST_NAME_PLACEHOLDER}
                                   value={firstName}
                                   onChange={(e) => setFirstName(e.target.value)}
                                   aria-describedby="firstName"/>
                            <label htmlFor="firstName">{FIRST_NAME_PLACEHOLDER}</label>

                            {/************ DISPLAY ERROR BELOW ************/}
                            {errors.firstName &&
                                <span className="invalid-feedback" id="firstNameFeedback">{errors.firstName}</span>}
                        </div>

                        {/************ LEARNER LAST NAME ************/}
                        <div className="form-floating mb-3">
                            <input type="text"
                                   className={"form-control " + (errors.lastName && 'is-invalid')}
                                   id="lastName"
                                   placeholder={LAST_NAME_PLACEHOLDER}
                                   value={lastName}
                                   onChange={(e) => setLastName(e.target.value)}
                                   aria-describedby="lastName"/>
                            <label htmlFor="lastName">{LAST_NAME_PLACEHOLDER}</label>

                            {/************ DISPLAY ERROR BELOW ************/}
                            {errors.lastName &&
                                <span className="invalid-feedback" id="lastNameFeedback">{errors.lastName}</span>}
                        </div>

                        {/************ LEARNER EXTERNAL ID ************/}
                        <div className="form-floating mb-3">
                            <input type="text"
                                   className={"form-control " + (errors.externalId && 'is-invalid')}
                                   id="externalId"
                                   placeholder={EXTERNAL_ID_PLACEHOLDER}
                                   value={externalId}
                                   onChange={(e) => setExternalId(e.target.value)}
                                   aria-describedby="externalId"/>
                            <label htmlFor="externalId">{EXTERNAL_ID_PLACEHOLDER}</label>

                            {/************ DISPLAY ERROR BELOW ************/}
                            {errors.externalId &&
                                <span className="invalid-feedback" id="externalIdFeedback">{errors.externalId}</span>}
                        </div>
                    </div>
                </div>

                <SPCard theme={"text-bg-warning"}
                        body={
                            <div className="w-100">Numer identyfikacyjny, który został Ci przekazany przez
                                nauczyciela/instruktora</div>}>
                </SPCard>

                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                    {/************ CANCEL SEARCHING FOR A LEARNER ************/}
                    <button type="button" className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}>Anuluj
                    </button>

                    {/************ FIND LEARNER ************/}
                    <button type="button" className="form-floating btn btn-primary m-2"
                            onClick={handleSubmit}>Znajdź ucznia
                    </button>
                </div>

            </div>
        </>}

        {pageState === 'CONFIRM-LEARNER' && learner && <>
            <SPCard body={<>
                <h2>{learner.firstName} {learner.lastName}</h2>
                <p className="small text-muted">Imię i nazwisko</p>
                <h3>{learner.externalId}</h3>
                <p className="small text-muted">Numer identyfikacyjny</p>
            </>}/>

            <div className="d-grid gap-2 d-flex justify-content-center m-3">
                {/************ CANCEL ADDING THE LEARNER ************/}
                <button type="button" className="form-floating btn btn-outline-secondary m-2"
                        onClick={cancelCallback}>Anuluj
                </button>
                {/************ ADD LEARNER ************/}
                <button type="button" className="form-floating btn btn-primary m-2"
                        onClick={() => addLearnerAsMyDependant(learner)}>Dodaj ucznia
                </button>
            </div>
        </>}

        {pageState === 'LEARNER-LOOKUP-ERROR' && <>
            <div className="d-flex m-3 flex-column gap-2">
                <div className="alert alert-warning text-center m-5">
                    <>
                        Brak danych ucznia - możesz poprawić kryteria wyszukiwania bądź skontaktuj się z placówką gdzie
                        uczeń uczęszcza w celu potwierdzenia danych.
                    </>
                </div>
                {/************ CANCEL ADDING THE LEARNER ************/}
                <div className="text-center">
                    <button type="button" className="btn btn-outline-secondary m-2 w-25"
                            onClick={cancelCallback}>Anuluj
                    </button>
                </div>
            </div>
        </>}
    </>
}

const validate = (data) => {
    const err = {isValid: true}

    if (!data.firstName || data.firstName.length < 2 || data.firstName.length > 50) {
        err.firstName = 'Proszę podać imię ucznia';
        err.isValid = false;
    }

    if (!data.lastName || data.lastName.length < 2 || data.lastName.length > 50) {
        err.lastName = 'Proszę podać nazwisko ucznia'
        err.isValid = false;
    }

    if (!data.externalId || data.externalId.trim().length === 0) {
        err.externalId = 'Proszę podać numer identyfikacyjny ucznia'
        err.isValid = false;
    }

    return err;
}
