import React, { useCallback, useEffect, useState } from 'react';
import { apiClient } from '../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import './studentsView.css';
import { useParams } from 'react-router';
import { StudentsTable } from '../components/studentmanagement/StudentsTable';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionGate } from '../components/common/PermissionGate';
import _ from 'lodash';
import { SendStudentsCodeViaSMS } from '../components/studentmanagement/SendStudentsCodeViaSMS';
import { Toggler } from '../components/common/Toggler';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { StudentsPaymentEntries } from '../components/studentmanagement/StudentsPaymentEntries';
import { AddSingleStudent } from '../components/studentmanagement/AddSingleStudent';
import { AddMultipleStudents } from '../components/studentmanagement/AddMultipleStudents';

interface Props {
    user: any;
}

export const StudentsView = ({ user }: Props) => {
    const { studentGroupId } = useParams();

    const { getAccessTokenSilently } = useAuth0();

    const [studentGroup, setStudentGroup] = useState(null);
    const [students, setStudents] = useState(null);
    const [pageState, setPageState] = useState('VIEW');
    const [tabKey, setTabKey] = useState('STUDENTS');

    const fetchStudentGroup = useCallback(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get(`/student-groups/${studentGroupId}`)
                .then((res) => setStudentGroup(res.data));
        });
    }, [getAccessTokenSilently, studentGroupId]);

    const fetchStudents = useCallback(() => {
        getAccessTokenSilently().then((accessToken) =>
            apiClient(accessToken)
                .get(`/students?student-group=${studentGroupId}`)
                .then((res) => {
                    let sorted = _.orderBy(res.data?.students, ['lastName', 'firstName'], ['asc', 'asc']);
                    setStudents(sorted);
                }),
        );
    }, [getAccessTokenSilently, studentGroupId]);

    const someCodesCanBeSent = (studentList) => {
        return studentList?.filter((s) => s.metadata?.canSendCodeViaSMS === true).length > 0;
    };

    const refreshView = () => {
        fetchStudentGroup();
        fetchStudents();
        setPageState('VIEW');
    };

    useEffect(() => {
        fetchStudentGroup();
        fetchStudents();
    }, [fetchStudentGroup, fetchStudents]);

    return studentGroup && user ? (
        <>
            {/************ VIEWING STUDENTS OF SELECTED STUDENT GROUP ************/}
            {pageState === 'VIEW' && (
                <>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/">
                                    <FontAwesomeIcon icon="fa-solid fa-home" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/student-groups">Grupy</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {studentGroup.displayName}
                            </li>
                        </ol>
                    </nav>

                    <div className="d-grid gap-2 d-flex justify-content-end">
                        <PermissionGate requires={user.permissions.canUpdateStudentGroups}>
                            <Toggler condition={someCodesCanBeSent(students)}>
                                <button
                                    className="btn btn-outline-primary"
                                    type="button"
                                    onClick={() => setPageState('SEND-CODE-TO-STUDENT')}
                                >
                                    Wyślij kod SMSem
                                </button>
                            </Toggler>

                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => setPageState('ADD-STUDENT')}
                            >
                                Dodaj ucznia do grupy
                            </button>

                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => setPageState('ADD-MULTIPLE-STUDENTS')}
                            >
                                Dodaj wielu
                            </button>
                        </PermissionGate>
                    </div>

                    <div className="mt-3 mb-4">
                        <span className="fs-1 fw-lighter">Nazwa: </span>
                        <span className="fs-1">{studentGroup.displayName}</span>
                    </div>

                    {/*TABS*/}
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                        className="mb-4"
                    >
                        {/* STUDENTS TAB */}
                        <Tab eventKey="STUDENTS" title="Lista">
                            <StudentsTable
                                studentGroup={studentGroup}
                                students={students}
                                user={user}
                                refreshViewCallback={refreshView}
                            />
                        </Tab>

                        {/* STUDENT PAYMENTS ENTRY TAB */}
                        <Tab eventKey="STUDENTS-PAYMENTS" title="Płatności">
                            <StudentsPaymentEntries
                                studentGroup={studentGroup}
                                students={students}
                                user={user}
                                refreshViewCallback={refreshView}
                            />
                        </Tab>
                    </Tabs>
                </>
            )}

            <PermissionGate requires={user.permissions.canUpdateStudentGroups}>
                {/************ ADD A STUDENT ************/}
                {pageState === 'ADD-STUDENT' && (
                    <>
                        <AddSingleStudent
                            cancelCallback={() => setPageState('VIEW')}
                            studentGroup={studentGroup}
                            refreshViewCallback={refreshView}
                        />
                    </>
                )}

                {/************ ADD MULTIPLE STUDENTS ************/}
                {pageState === 'ADD-MULTIPLE-STUDENTS' && (
                    <>
                        <AddMultipleStudents
                            cancelCallback={() => setPageState('VIEW')}
                            studentGroup={studentGroup}
                            refreshViewCallback={refreshView}
                        />
                    </>
                )}

                {/************ SEND-CODE-TO-STUDENT ************/}
                {pageState === 'SEND-CODE-TO-STUDENT' && (
                    <>
                        <SendStudentsCodeViaSMS
                            students={students}
                            refreshViewCallback={refreshView}
                            cancelCallback={() => setPageState('VIEW')}
                        />
                    </>
                )}
            </PermissionGate>
        </>
    ) : (
        //************ PLACEHOLDER ************
        <>
            <div className="placeholder-glow" aria-hidden="true">
                <span className="placeholder col-1"></span>
            </div>

            <div className="d-grid gap-2 d-sm-flex justify-content-sm-end m-3 placeholder-glow" aria-hidden="true">
                <span className="btn btn-primary disabled placeholder col-1"></span>
                <span className="btn btn-primary disabled placeholder col-1"></span>
            </div>

            <div className="placeholder-glow p-3" aria-hidden="true">
                <span className="placeholder col-4 m-1"></span>
                <span className="placeholder col-4 m-1"></span>
                <span className="placeholder col-4 m-1"></span>
                <span className="placeholder col-4 m-1"></span>
                <span className="placeholder col-4 m-1"></span>
                <span className="placeholder col-4 m-1"></span>
            </div>
        </>
    );
};
