import React, { useState } from 'react';
import { Alert } from '../../common/Alert';
import { Email } from '../../common/Email';
import { InputTextBox } from '../../common/InputTextBox';
import { InputTextArea } from '../../common/InputTextArea';

const ENROLMENT_NAME_PLACEHOLDER = 'nazwa';
const ENROLMENT_DESCRIPTION_PLACEHOLDER = 'opis/uwagi';
const ENROLMENT_LOCATION_PLACEHOLDER = 'miejsce zajęć/miejscowość';
const ENROLMENT_BUILDING_PLACEHOLDER = 'nazwa sali/budynku';

const ENROLMENT_NAME_MISSING_ERROR_MSG = 'Proszę podać nazwę';
const ENROLMENT_NAME_TOO_LONG_ERROR_MSG = 'Nazwa jest zbyt długa';
const ENROLMENT_DESCRIPTION_MISSING_ERROR_MSG = 'Proszę podać opis';
const ENROLMENT_DESCRIPTION_TOO_LONG_ERROR_MSG = 'Opis jest zbyt długi';

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    nextStepCallback: () => void;
}

export const CollectEnrolmentDetails = ({ cancelCallback, nextStepCallback, inputValues, inputHandler }: Props) => {
    const [errors, setErrors] = useState({});

    const validateInputAndCallNextStep = () => {
        let validationResult = validateInput(inputValues);
        setErrors(validationResult.errors);

        if (validationResult.isValid) {
            nextStepCallback();
        }
    };

    return (
        <>
            <div className="fs-5 fw-light">Podstawowe dane</div>
            {/************ ENROLMENT NAME INPUT ************/}
            <InputTextBox
                inputValues={inputValues}
                inputName="enrolmentName"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_NAME_PLACEHOLDER}
                errors={errors}
            />

            {/************ ENROLMENT DESCRIPTION INPUT ************/}
            <InputTextArea
                inputValues={inputValues}
                inputName="description"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_DESCRIPTION_PLACEHOLDER}
                errors={errors}
            />

            <InputTextBox
                inputValues={inputValues}
                inputName="locationName"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_LOCATION_PLACEHOLDER}
                errors={errors}
            />

            <InputTextBox
                inputValues={inputValues}
                inputName="buildingName"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_BUILDING_PLACEHOLDER}
                errors={errors}
            />

            <Alert theme="warning">
                <div>Proszę o kontakt w razie potrzeby umieszczenia ikony wyświetlanej podczas zapisów online.</div>
                <Email />
            </Alert>

            <hr />

            <div className="d-grid gap-2 d-flex justify-content-center">
                {/************ CANCEL ************/}
                <button type="button" className="form-floating btn btn-outline-secondary m-2" onClick={cancelCallback}>
                    Anuluj
                </button>

                {/************ CONTINUE ************/}
                <button
                    type="button"
                    className="form-floating btn btn-primary m-2"
                    onClick={validateInputAndCallNextStep}
                >
                    Dalej
                </button>
            </div>
        </>
    );
};

const validateInput = (input) => {
    let validationResult = { isValid: true, errors: {} };

    if (!input.enrolmentName || input.enrolmentName.trim().length === 0) {
        validationResult.errors.enrolmentName = ENROLMENT_NAME_MISSING_ERROR_MSG;
        validationResult.isValid = false;
    }

    if (input.enrolmentName && input.enrolmentName.trim().length > 200) {
        validationResult.errors.enrolmentName = ENROLMENT_NAME_TOO_LONG_ERROR_MSG;
        validationResult.isValid = false;
    }

    if (!input.description || input.description.trim().length === 0) {
        validationResult.errors.description = ENROLMENT_DESCRIPTION_MISSING_ERROR_MSG;
        validationResult.isValid = false;
    }

    if (input.description && input.description.trim().length > 1024) {
        validationResult.errors.description = ENROLMENT_DESCRIPTION_TOO_LONG_ERROR_MSG;
        validationResult.isValid = false;
    }

    return validationResult;
};
