import React from 'react';
import dayjs from 'dayjs';

interface Props {
    event: any;
    separator?: string;
    showWeekday?: boolean;
}

const POLISH_MONTHS = {
    0: 'Styczeń',
    1: 'Luty',
    2: 'Marzec',
    3: 'Kwiecień',
    4: 'Maj',
    5: 'Czerwiec',
    6: 'Lipiec',
    7: 'Sierpień',
    8: 'Wrzesień',
    9: 'Październik',
    10: 'Listopad',
    11: 'Grudzień',
};

const POLISH_WEEKDAYS = {
    0: 'Niedziela',
    1: 'Poniedziałek',
    2: 'Wtorek',
    3: 'Środa',
    4: 'Czwartek',
    5: 'Piątek',
    6: 'Sobota',
};

export const EventDate = ({ date, separator = '', showWeekday = true }: Props) => {
    const displayDate = (date, separator) => {
        const dday = dayjs(new Date(date));

        const day = dday.format('DD');
        const weekDayIndex = dday.day();
        const monthIndex = dday.month();
        const year = dday.year();

        const weekday = showWeekday ? POLISH_WEEKDAYS[weekDayIndex] : '';

        return `${weekday}${separator}${day} ${POLISH_MONTHS[monthIndex]} ${year}`.trim();
    };

    return <>{displayDate(date, separator)}</>;
};
