import React from "react";

export const SendTimeWarningCard = () => {
    return <div className="card border-warning">
            <div className="card-body text-bg-warning">
                <div className="card-title fs-5 fw-bolder">Uwaga!</div>
                <div className="card-text">Wiadomości mogą być wysłane ciągu najbliższych 10-15 minut.</div>
                <div className="card-text">Dostosuj czas według uznania.</div>
            </div>
        </div>
}