import React from 'react';

interface Props {
    inputType?: string;
    inputValues: any;
    inputName: string;
    inputHandler: (e) => void;
    placeholder: string;
    errors: any;
}

export const InputTextBox = ({
    inputType = 'text',
    inputValues,
    inputName,
    inputHandler,
    placeholder,
    errors,
}: Props) => {
    return (
        <div className="form-floating">
            <input
                type={inputType}
                className={'form-control ' + (errors[inputName] && 'is-invalid')}
                id={`input-text-for-${inputName}`}
                name={inputName}
                placeholder={placeholder}
                value={inputValues[inputName]}
                onChange={inputHandler}
                aria-describedby={`${inputName}-aria-describedby`}
            />
            <label htmlFor={`input-text-for-${inputName}`}>{placeholder}</label>

            {/************ DISPLAY ERROR BELOW ************/}
            {errors[inputName] && (
                <span id={`${inputName}-error`} className="invalid-feedback">
                    {errors[inputName]}
                </span>
            )}
        </div>
    );
};
