import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../../apiClientV1";
import {Toggler} from "../../common/Toggler";
import _ from "lodash";

interface Props {
    event: any;
    studentGroupId: string;
    students: any;
    refreshViewCallback: () => void;
}

export const InviteStudentsTable = ({
                                        event,
                                        studentGroupId,
                                        students,
                                        refreshViewCallback,
                                    }: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const handleInviteStudent = (studentIdList) => {
        const payload = {
            eventId: event.id,
            studentGroupId: studentGroupId,
            studentIds: studentIdList,
        };

        getAccessTokenSilently().then((accessToken) =>
            apiClient(accessToken)
                .post(`/event-register`, payload)
                .then(() => refreshViewCallback())
        );
    };

    function getStudentIds(studentList) {
        return studentList?.map((s) => s.id);
    }

    const sortStudents = (students) => {
        return _.orderBy(students, ["lastName", "firstName"], ["asc", "asc"]);
    };

    return studentGroupId && students ? (
        <>
            <Toggler condition={students.length !== 0}>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Uczeń</th>
                        <th scope="col">
                            <button
                                className="btn btn-sm btn-success"
                                onClick={() => handleInviteStudent(getStudentIds(students))}
                            >
                                Zaproś wszystkich
                            </button>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="table-group-divider">
                    {sortStudents(students).map((row, index) => (
                        <tr key={row.id}>
                            <td className="pt-2">{index + 1}</td>
                            <td className="pt-2">{row.displayName}</td>
                            <td>
                                <button className="btn btn-sm btn-outline-success"
                                        onClick={() => handleInviteStudent([row.id])}>
                                    Zaproś
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Toggler>
            <Toggler condition={students.length === 0}>
                <div className="parent">
                    <div className="child text-center display-6">
                        Brak studentów w tej grupie
                    </div>
                </div>
            </Toggler>
        </>
    ) : (
        <div className="placeholder-glow p-3" aria-hidden="true">
            <span className="placeholder col-4 m-1"></span>
            <span className="placeholder col-4 m-1"></span>
            <span className="placeholder col-4 m-1"></span>
            <span className="placeholder col-4 m-1"></span>
            <span className="placeholder col-4 m-1"></span>
            <span className="placeholder col-4 m-1"></span>
        </div>
    );
};
