import React, {useState} from "react";
import './../common.css';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Toggler} from "../components/common/Toggler";
import {IncomeBreakdownReport} from "../components/reports/IncomeBreakdownReport";
import {AttendanceBreakdownReport} from "../components/reports/AttendanceBreakdownReport";
import {CostBreakdownReport} from "../components/reports/CostBreakdownReport";
import {IncomeHistoryReport} from "../components/reports/IncomeHistoryReport";

export const ReportsView = () => {
    const [pageState] = useState('VIEW');
    const [reportType, setReportType] = useState('INCOME-BREAKDOWN');

    return <>
        {/************ VIEWING REPORTS ************/}
        {pageState === 'VIEW' && <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" aria-current="page">
                        <Link to="/"><FontAwesomeIcon icon="fa-solid fa-home"/></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Raporty</li>
                </ol>
            </nav>

            <div className="d-grid gap-2 d-flex justify-content-end m-3">
                <button className="btn btn-primary" onClick={() => setReportType('INCOME-BREAKDOWN')}>
                    Dochód
                </button>
                <button className="btn btn-primary" onClick={() => setReportType('INCOME-HISTORY')}>
                    Historia wpłat
                </button>
                <button className="btn btn-primary" onClick={() => setReportType('ATTENDANCE-BREAKDOWN')}>
                    Obecność
                </button>
                <button className="btn btn-primary" onClick={() => setReportType('COST-BREAKDOWN')}>
                    Koszty
                </button>
            </div>

            <Toggler condition={reportType === 'INCOME-BREAKDOWN'}>
                <IncomeBreakdownReport/>
            </Toggler>

            <Toggler condition={reportType === 'INCOME-HISTORY'}>
                <IncomeHistoryReport/>
            </Toggler>

            <Toggler condition={reportType === 'ATTENDANCE-BREAKDOWN'}>
                <AttendanceBreakdownReport/>
            </Toggler>

            <Toggler condition={reportType === 'COST-BREAKDOWN'}>
                <CostBreakdownReport/>
            </Toggler>

            {/*<Toggler condition={reportType === 'COST-BREAKDOWN'}>*/}
            {/*    <CostBreakdownReport/>*/}
            {/*</Toggler>*/}

        </>
        }
    </>
}
