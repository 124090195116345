import React from 'react';

interface Props {
    caption?: string;
    text?: string;
}

export const Tile = ({ caption = '', text = '' }: Props) => {
    return (
        <div className="card" style={{ width: '18rem' }}>
            <div className="card-body text-center">
                <h5 className="card-title">{text}</h5>
                <p className="card-text text-muted">{caption}</p>
            </div>
        </div>
    );
};
