import React from "react";
import {eventRegisterStatusToClass, eventRegisterStatusToDisplayText} from "../../lib/utils";

interface Props {
    status: any;
}

export const EventRegisterStatusBadge = ({status}: Props) => {
    return <div className={`badge rounded-pill ${eventRegisterStatusToClass(status)}`}>
        {eventRegisterStatusToDisplayText(status)}
    </div>
}