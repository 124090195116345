import React from "react";
import {enrolmentStatusToClass, enrolmentStatusToDisplayText} from "../../lib/utils";

interface Props {
    status: any;
}

export const EnrolmentStatusBadge = ({status}: Props) => {
    return <div className={`badge rounded-pill ${enrolmentStatusToClass(status)}`}>
        {enrolmentStatusToDisplayText(status)}
    </div>
}