import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    orgId: string;
    enrolment: any;
}

export const LearnerEnrolmentCard = ({ orgId, enrolment }: Props) => {
    return (
        enrolment && (
            <>
                <div className="card">
                    <div className="card-header fs-4">
                        {enrolment.locationName} {enrolment.buildingName && ':: ' + enrolment.buildingName}
                    </div>

                    <div className="card-body">
                        <div className="d-flex gap-4">
                            <img
                                src={enrolment.logoUrl}
                                className="img-fluid img-round-100 p-3"
                                alt={enrolment.enrolmentName}
                            />

                            <div>
                                <div className="card-title fs-5">{enrolment.enrolmentName}</div>
                                <div className="card-subtitle mb-2 text-body-secondary fs-6">
                                    {enrolment.buildingName}
                                </div>
                            </div>
                        </div>

                        <Link to={`/discover/org/${orgId}/e/${enrolment.id}`} className="btn btn-primary fa-pull-right">
                            Rejestracja
                        </Link>
                    </div>
                </div>
            </>
        )
    );
};
