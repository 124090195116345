import React, {useCallback, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../apiClientV1";
import dayjs from "dayjs";
import '../../common.css';
import {IncomeTable} from "./IncomeTable";

export const IncomeHistoryReport = () => {
    const {getAccessTokenSilently} = useAuth0();

    const [incomeData, setIncomeData] = useState(null);
    const monthAgo = dayjs().add(-1, 'month').startOf("day");
    const firstOfTheMonth = dayjs().startOf("month").startOf("day");
    const endOfDayToday = dayjs().endOf("day");

    const [startDate, setStartDate] = useState(monthAgo);
    const [endDate] = useState(endOfDayToday);

    const formatDate = (date) => {
        return date.format("YYYY-MM-DD");
    }

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                const fromDate = startDate.toISOString();
                const toDate = endDate.toISOString();

                apiClient(accessToken).get(`/reports/income-history?from=${fromDate}&to=${toDate}`)
                    .then(res => {
                        setIncomeData(res.data);
                    });
            })
    }, [endDate, getAccessTokenSilently, startDate]);

    const onStartDateChanged = (newStartDate) => {
        // setting incomeData to null as this causes to nicely reload the chart
        setIncomeData(null);
        setStartDate(newStartDate);
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <div className="d-flex flex-column align-items-center">
        <div className="fs-1">Historia Płatności</div>

        {/* DATE RANGE */}
        <div>{formatDate(startDate)} :: {formatDate(endDate)}</div>

        {/* BUTTONS */}
        <div className="d-flex gap-2 mt-3 mb-3">
            <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(monthAgo)}>
                Miesiąc temu
            </button>
            <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(firstOfTheMonth)}>
                Początek miesiąca
            </button>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center gap-5 mt-5 w-100">
            <IncomeTable incomeData={incomeData} />
        </div>
    </div>
}