import React, { useState } from 'react';
import './enrolmentCreatorWizard.css';
import { Toggler } from '../common/Toggler';
import Spinner from '../common/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';
import { EnrolmentCreatorWizardError } from './EnrolmentCreatorWizardError';
import { CollectEnrolmentDetails } from './wizard/CollectEnrolmentDetails';
import { CollectEnrolmentConsents } from './wizard/CollectEnrolmentConsents';
import { CollectEnrolmentAdditionalDetails } from './wizard/CollectEnrolmentAdditionalDetails';
import dayjs from 'dayjs';
import { toFee } from '../../lib/utils';

const TERMS_AND_CONDITIONS_URL = 'https://drive.google.com/file/d/1T9m0ed1KgcH1kRKEHWLCsaBxrGNlFsk9/view';

const defaultConsent = () => {
    return {
        id: '-1',
        title: 'Akceptuję regulamin serwisu szkolneplatnosci.pl',
        documentUrl: TERMS_AND_CONDITIONS_URL,
        isMandatory: true,
    };
};

interface Props {
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

const initialValues = () => {
    return {
        enrolmentName: '',
        description: '',
        consentList: [defaultConsent()],
        enrolmentType: 'ONLINE_ENROLMENT',
        locationName: '',
        buildingName: '',
        experienceLevel: '',
        capacity: '',
        termStartsOn: '',
        termEndsOn: '',
        fee: '',
        feeCurrency: 'PLN',
    };
};

export const EnrolmentCreatorWizard = ({ cancelCallback, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    // Order of these steps matter!
    const steps = ['COLLECT-ENROLMENT-DETAILS', 'COLLECT-ENROLMENT-ADDITIONAL-DETAILS', 'COLLECT-ENROLMENT-CONSENTS'];
    const [stepIndex, setStepIndex] = useState(0);
    const [pageState, setPageState] = useState(steps[0]);
    const [payload, setPayload] = useState(initialValues());

    const handleCreateEnrolment = () => {
        setPageState('LOADING');
        getAccessTokenSilently().then((accessToken) => {
            const createEventRequest = {
                ...payload,
                termStartsOn: payload.termStartsOn ? dayjs(payload.termStartsOn, 'YYYY-MM-DDTHH:mm') : null,
                termEndsOn: payload.termEndsOn ? dayjs(payload.termEndsOn, 'YYYY-MM-DDTHH:mm') : null,
                fee: toFee(payload.fee),
            };

            apiClient(accessToken)
                .post('/enrolments', createEventRequest)
                .then(() => refreshViewCallback())
                .catch(() => setPageState('ERROR'));
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            [name]: value,
        });
    };

    const progressValue = () => {
        const percent = ((stepIndex + 1) / steps.length) * 100;
        return percent + '%';
    };

    const stepForward = () => {
        const next = stepIndex + 1;
        setStepIndex(next);
        setPageState(steps[next]);
    };

    const stepBackward = () => {
        const prev = stepIndex - 1;
        setStepIndex(prev);
        setPageState(steps[prev]);
    };

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-10 col-md-8 col-lg-6">
                        <div className="d-flex flex-column gap-3 mt-4">
                            <div className="fs-4 fw-bold">Zapisy Online</div>

                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    style={{ width: `${progressValue()}` }}
                                    aria-label="Basic example"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                />
                            </div>

                            <Toggler condition={pageState === 'LOADING'}>
                                <div className="d-flex flex-column-reverse align-items-center">
                                    <Spinner />
                                    <p className="small text-muted">Proszę czekać</p>
                                </div>
                            </Toggler>

                            <Toggler condition={pageState === 'ERROR'}>
                                <EnrolmentCreatorWizardError refreshViewCallback={refreshViewCallback} />
                            </Toggler>

                            <Toggler condition={pageState === 'COLLECT-ENROLMENT-DETAILS'}>
                                <CollectEnrolmentDetails
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    nextStepCallback={stepForward}
                                />
                            </Toggler>

                            <Toggler condition={pageState === 'COLLECT-ENROLMENT-ADDITIONAL-DETAILS'}>
                                <CollectEnrolmentAdditionalDetails
                                    previousStepCallback={stepBackward}
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    nextStepCallback={stepForward}
                                />
                            </Toggler>

                            <Toggler condition={pageState === 'COLLECT-ENROLMENT-CONSENTS'}>
                                <CollectEnrolmentConsents
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    previousStepCallback={stepBackward}
                                    nextStepCallback={handleCreateEnrolment}
                                />
                            </Toggler>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
