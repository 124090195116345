import React from 'react';

interface Props {
    inputValues: any;
    inputName: string;
    inputHandler: (e) => void;
    placeholder: string;
    errors: any;
}

export const InputCheckBox = ({ inputValues, inputName, inputHandler, placeholder, errors }: Props) => {
    const toEvent = (name, flag) => {
        return { target: { name: name, value: flag } };
    };

    return (
        <>
            {/************ INPUT ************/}
            <div className="form-check form-switch">
                <input
                    type="checkbox"
                    role="switch"
                    className={'form-check-input ' + (errors[inputName] && 'is-invalid')}
                    id={`input-checkbox-for-${inputName}`}
                    name={inputName}
                    value={inputValues[inputName]}
                    required={false}
                    onChange={() => inputHandler(toEvent(inputName, !inputValues[inputName]))}
                    checked={inputValues[inputName]}
                    aria-describedby={`${inputName}-aria-describedby`}
                />
                <label htmlFor={`input-checkbox-for-${inputName}`}>{placeholder}</label>

                {/************ DISPLAY ERROR BELOW ************/}
                {errors[inputName] && (
                    <span id={`${inputName}-error`} className="invalid-feedback">
                        {errors[inputName]}
                    </span>
                )}
            </div>
        </>
    );
};
