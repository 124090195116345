import React, {useState} from "react";
import {Toggler} from "../../common/Toggler";

const EVENT_NAME_PLACEHOLDER = "tytuł";
const EVENT_DATE_PLACEHOLDER = "data & czas";
const EVENT_DURATION_PLACEHOLDER = "długość";

const EVENT_NAME_MISSING_ERROR_MSG = "Proszę podać tytuł wydarzenia";
const EVENT_NAME_TOO_LONG_ERROR_MSG = "Tytuł wydarzenia jest zbyt długi";
const EVENT_DATE_ERROR_MSG = "Proszę podać poprawny czas i datę wydarzenia";
const EVENT_DURATION_ERROR_MSG = "Proszę podać długość wydarzenia";

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    nextStepCallback: () => void;
}

export const CollectEventDetails = ({cancelCallback, nextStepCallback, inputValues, inputHandler}: Props) => {
    const [errors, setErrors] = useState({});

    const validateInputAndCallNextStep = () => {
        let validationResult = validateInput(inputValues);
        setErrors(validationResult.errors);

        if (validationResult.isValid) {
            nextStepCallback();
        }
    }

    const calculateDuration = (time) => {
        let parts = time.split(':');
        let hours = Number(parts[0]);
        let minutes = Number(parts[1]);
        let durationInMinutes = hours * 60 + minutes;

        let hoursLabel = hours === 0 || hours >= 5 ? 'godzin' : (hours === 1 ? 'godzina' : 'godziny');
        let minutesLabel = minutes === 0 || minutes >= 5 ? 'minut' : (minutes === 1 ? 'minuta' : 'minuty');

        return `${hours} ${hoursLabel}, ${minutes} ${minutesLabel} (${durationInMinutes} minut)`;
    }

    return <>
        <div className="fs-5 fw-light">Podstawowe dane</div>
        {/************ EVENT NAME INPUT ************/}
        <div className="form-floating">
            <input type="text"
                   className={"form-control " + (errors.eventName && 'is-invalid')}
                   id="eventName"
                   name="eventName"
                   placeholder={EVENT_NAME_PLACEHOLDER}
                   value={inputValues.eventName}
                   onChange={inputHandler}
                   aria-describedby="eventNameValidation"/>
            <label htmlFor="eventName">{EVENT_NAME_PLACEHOLDER}</label>

            {/************ DISPLAY ERROR BELOW ************/}
            {errors.eventName &&
                <span id="eventNameValidation"
                      className="invalid-feedback">{errors.eventName}</span>}
        </div>


        {/************ EVENT DATE INPUT ************/}
        <div className="form-floating">
            <input type="datetime-local"
                   className={"form-control " + (errors.eventDate && 'is-invalid')}
                   id="eventDate"
                   placeholder={EVENT_DATE_PLACEHOLDER}
                   name="eventDate"
                   value={inputValues.eventDate}
                   onChange={inputHandler}
                   aria-describedby="eventDateValidation"/>
            <label htmlFor="eventDate">{EVENT_DATE_PLACEHOLDER}</label>

            {/************ DISPLAY ERROR BELOW ************/}
            {errors.eventDate &&
                <span id="eventDateError" className="invalid-feedback">{errors.eventDate}</span>}
        </div>

        {/************ EVENT DURATION INPUT ************/}
        <div className="form-floating">
            <input type="time"
                   className={"form-control " + (errors.eventDuration && 'is-invalid')}
                   id="eventDuration"
                   placeholder={EVENT_DURATION_PLACEHOLDER}
                   name="eventDuration"
                   value={inputValues.eventDuration}
                   onChange={inputHandler}
                   aria-describedby="eventDurationValidation"/>
            <label htmlFor="eventDuration">{EVENT_DURATION_PLACEHOLDER}</label>

            {/************ DISPLAY ERROR BELOW ************/}
            {errors.eventDuration &&
                <span id="eventDurationError" className="invalid-feedback">{errors.eventDuration}</span>}

            <Toggler condition={!!inputValues.eventDuration}>
                <small className="text-muted">{calculateDuration(inputValues.eventDuration)}</small>
            </Toggler>
        </div>

        <hr/>

        <div className="d-grid gap-2 d-flex justify-content-center">
            {/************ CANCEL ************/}
            <button type="button" className="form-floating btn btn-outline-secondary m-2"
                    onClick={cancelCallback}>Anuluj
            </button>

            {/************ CONTINUE ************/}
            <button type="button" className="form-floating btn btn-primary m-2"
                    onClick={validateInputAndCallNextStep}>Dalej
            </button>
        </div>
    </>
}

const validateInput = (input) => {
    let validationResult = {isValid: true, errors: {}};

    if (!input.eventName || input.eventName.trim().length === 0) {
        validationResult.errors.eventName = EVENT_NAME_MISSING_ERROR_MSG;
        validationResult.isValid = false;
    }

    if (input.eventName && input.eventName.trim().length > 200) {
        validationResult.errors.eventName = EVENT_NAME_TOO_LONG_ERROR_MSG;
        validationResult.isValid = false;
    }

    if (!input.eventDate) {
        validationResult.errors.eventDate = EVENT_DATE_ERROR_MSG;
        validationResult.isValid = false;
    }

    if (!input.eventDuration || input.eventDuration === '00:00') {
        validationResult.errors.eventDuration = EVENT_DURATION_ERROR_MSG;
        validationResult.isValid = false;
    }

    return validationResult;
}