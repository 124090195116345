import React, {useState} from "react";
import {Toggler} from "../../common/Toggler";
import dayjs from "dayjs";

const EVENT_REPEAT_PLACEHOLDER = "powtarzalność";

const SCHEDULE_REPEAT_EVERY_COUNT_ERROR_MSG = "Wartość musi być pozytywna";
const SCHEDULE_ENDS_AFTER_OCCURENCE_ERROR_MSG = "Wartość musi być pozytywna";

const scheduleTypes = [
    {display: "nigdy", value: 'ONE_OFF'},
    {display: "tygodniowo", value: 'WEEKLY'}
];

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    nextStepCallback: () => void;
    previousStepCallback: () => void;
}

export const CollectEventSchedule = ({
                                         cancelCallback,
                                         inputValues,
                                         inputHandler,
                                         previousStepCallback,
                                         nextStepCallback
                                     }: Props) => {
    const [errors, setErrors] = useState({});

    const weekOrWeeks = (count) => {
        if (count > 0 && count < 2) return 'tydzień';
        if (count > 1 && count < 5) return 'tygodnie';
        return 'tygodni';
    }

    const validateInputAndCallNextStep = () => {
        let validationResult = validateInput(inputValues);
        setErrors(validationResult.errors);

        if (validationResult.isValid) {
            nextStepCallback();
        }
    }

    return <>
        <div className="fs-5 fw-light">Powtarzalność</div>

        {/************ EVENT SCHEDULE TYPE INPUT ************/}
        <div className="form-floating">
            <select className="form-select"
                    id="scheduleType"
                    name="scheduleType"
                    value={inputValues.scheduleType}
                    onChange={inputHandler}>
                <option value="" disabled>{EVENT_REPEAT_PLACEHOLDER}</option>
                {scheduleTypes?.map(option =>
                    <option key={option.value} value={option.value}>{option.display}</option>
                )}
            </select>
            <label htmlFor="scheduleType">{EVENT_REPEAT_PLACEHOLDER}</label>
        </div>

        <Toggler condition={inputValues.scheduleType === 'WEEKLY'}>

            <hr/>

            <div>
                <div className="d-flex flex-row align-items-center gap-2">
                    <div>Powtórz co</div>
                    <input type="number"
                           id="scheduleRepeatEveryCount"
                           className={"w-25 form-control " + (errors.scheduleRepeatEveryCount && 'is-invalid')}
                           min={1} max={100} step={1}
                           name="scheduleRepeatEveryCount"
                           value={inputValues.scheduleRepeatEveryCount}
                           onChange={inputHandler}
                           aria-describedby="scheduleRepeatEveryCountValidation"/>
                    <div>{weekOrWeeks(inputValues.scheduleRepeatEveryCount)}</div>
                </div>
                {/************ DISPLAY ERROR BELOW ************/}
                {errors.scheduleRepeatEveryCount &&
                    <span id="scheduleRepeatEveryCountValidation"
                          className="invalid-feedback d-block">{errors.scheduleRepeatEveryCount}</span>}
            </div>

            <hr/>

            <div className="">Kończąc:</div>

            <div className="d-flex flex-column gap-3" onChange={inputHandler}>
                <div className="d-flex flex-row align-items-center gap-3">
                    <input className="form-check-input"
                           type="radio"
                           id="scheduleEndId1"
                           name="scheduleEndType"
                           onChange={() => {/*NOOP*/
                           }}
                           checked={inputValues.scheduleEndType === 'ON_DATE'}
                           value="ON_DATE"/>
                    <label className="form-check-label" htmlFor="scheduleEndId1">dnia</label>
                    <input type="datetime-local"
                           className={"w-50 form-control " + (errors.scheduleEndsOn && 'is-invalid')}
                           disabled={inputValues.scheduleEndType !== 'ON_DATE'}
                           name="scheduleEndsOn"
                           value={inputValues.scheduleEndsOn}
                           onChange={inputHandler}/>
                </div>
                <div className="d-flex flex-row align-items-center gap-3 ">
                    <input className="form-check-input"
                           type="radio"
                           name="scheduleEndType"
                           id="scheduleEndId2"
                           checked={inputValues.scheduleEndType === 'AFTER_OCCURRENCE_COUNT'}
                           onChange={() => {/*NOOP*/
                           }}
                           value="AFTER_OCCURRENCE_COUNT"/>
                    <label className="form-check-label" htmlFor="scheduleEndId2">po</label>
                    <input type="number"
                           className={"w-25 form-control " + (errors.scheduleEndsAfterOccurrences && 'is-invalid')}
                           disabled={inputValues.scheduleEndType !== 'AFTER_OCCURRENCE_COUNT'}
                           min={1} max={26} step={1}
                           name="scheduleEndsAfterOccurrences"
                           value={inputValues.scheduleEndsAfterOccurrences}
                           onChange={inputHandler}/>
                    <div>zajęciach</div>
                </div>

                {/************ DISPLAY ERROR BELOW ************/}
                {errors.scheduleEndsOn &&
                    <span className="invalid-feedback d-block">{errors.scheduleEndsOn}</span>}
                {/************ DISPLAY ERROR BELOW ************/}
                {(errors.scheduleEndsAfterOccurrences) &&
                    <span className="invalid-feedback d-block">{errors.scheduleEndsAfterOccurrences}</span>}
            </div>

        </Toggler>

        <hr/>

        <div className="d-grid gap-2 d-flex justify-content-center">
            {/************ CANCEL ************/}
            <button type="button" className="form-floating btn btn-outline-secondary m-2"
                    onClick={cancelCallback}>Anuluj
            </button>

            {/************ BACK ************/}
            <button type="button" className="form-floating btn btn-secondary m-2"
                    onClick={previousStepCallback}>Cofnij
            </button>

            {/************ CONTINUE ************/}
            <button type="button" className="form-floating btn btn-primary m-2"
                    onClick={validateInputAndCallNextStep}>Dalej
            </button>
        </div>
    </>
}

const validateInput = (input) => {
    let validationResult = {isValid: true, errors: {}};

    if (input.scheduleType === 'WEEKLY') {
        if (!input.scheduleRepeatEveryCount || input.scheduleRepeatEveryCount <= 0) {
            validationResult.errors.scheduleRepeatEveryCount = SCHEDULE_REPEAT_EVERY_COUNT_ERROR_MSG;
            validationResult.isValid = false;
        }
        
        if (input.scheduleEndType === 'ON_DATE') {
            const oneYearFromEventDate = dayjs(input.eventDate).add(1, 'year');

            if (!input.scheduleEndsOn) {
                validationResult.errors.scheduleEndsOn = 'Proszę podać datę';
                validationResult.isValid = false;
            }

            if (input.scheduleEndsOn && dayjs(input.scheduleEndsOn).isAfter(oneYearFromEventDate)) {
                validationResult.errors.scheduleEndsOn = 'Podana data jest zbyt daleko w przyszłości';
                validationResult.isValid = false;
            }

            if (input.scheduleEndsOn && dayjs(input.scheduleEndsOn).isBefore(input.eventDate)) {
                validationResult.errors.scheduleEndsOn = 'Podana data musi być po dacie wydarzenia';
                validationResult.isValid = false;
            }
        }

        if (input.scheduleEndType === 'AFTER_OCCURRENCE_COUNT') {
            if (!input.scheduleEndsAfterOccurrences || input.scheduleEndsAfterOccurrences <= 0 || input.scheduleEndsAfterOccurrences > 100) {
                validationResult.errors.scheduleEndsAfterOccurrences = SCHEDULE_ENDS_AFTER_OCCURENCE_ERROR_MSG;
                validationResult.isValid = false;
            }
        }
    }

    return validationResult;
}