import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../apiClientV1";

export const PaymentSuccessView = () => {
    const {sessionId} = useParams();
    const {getAccessTokenSilently} = useAuth0();
    const [response, setResponse] = useState();

    useEffect(() => {
        getAccessTokenSilently().then(accessToken => {
            apiClient(accessToken).patch(`/stripe/payment-sessions/${sessionId}`, {})
                .then(res => setResponse(res.data))
        })
    }, [getAccessTokenSilently, sessionId])

    return response && <>
        <div className="container text-center">
            <div className="row justify-content-center m-5">
                <div className="col-12 col-sm-10 col-md-8 col-lg-8 fw-bolder fs-5">
                    <h1>Brawo!</h1>
                    <div className="alert alert-success mt-5" role="alert">
                        Płatność zakończona sukcesem!
                    </div>
                    {/* DELIBERATELY USING AN A HREF HERE TO REFRESH THE ENTIRE PAGE VIEW. */}
                    {/* GENERALLY, THIS SHOULD BE AVOIDED, BUT IT'S JUSTIFIED TO USE HERE */}
                    {/* IN OTHER CASES, PROBABLY BEST TO USE NavLink OR Link */}
                    <a href="/" className="btn btn-primary" >Wróć do strony głównej</a>
                </div>
            </div>
        </div>
    </>
}
