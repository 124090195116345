import React from 'react';
import { Email } from '../common/Email';

interface Props {
    selectUserTypeCallback: (userType) => void;
}

export const SelectUserType = ({ selectUserTypeCallback }: Props) => {
    function selectGuardian() {
        selectUserTypeCallback('GUARDIAN');
    }

    function selectInstructor() {
        selectUserTypeCallback('ADMIN_INSTRUCTOR');
    }

    return (
        <>
            <h1 className="text-center m-5">Wybierz typ użytkownika</h1>

            <p className="text-body-secondary m-5 text-center fs-5">Chcę zarejestrować się jako:</p>

            <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
                <div className="col">
                    <div className="card h-100" onClick={selectGuardian}>
                        <div className="card-body">
                            <p className="card-title fs-4 fw-bold">Rodzic / Opiekun / Student</p>
                            <p className="card-text text-muted">Wybierz jeśli:</p>
                            <ul>
                                <li>Jesteś rodzicem/opiekunem</li>
                                <li>Jesteś studentem</li>
                                <li>Instruktor/nauczyciel poprosił Cię o rejestrację</li>
                            </ul>
                        </div>

                        <div className="card-footer text-center">
                            <button className="btn btn-primary" onClick={selectGuardian}>
                                Wybierz
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card h-100" onClick={selectInstructor}>
                        <div className="card-body">
                            <p className="card-title fs-4 fw-bold">Instruktor / Nauczyciel</p>

                            <p className="card-text text-muted">Wybierz jeśli:</p>
                            <ul>
                                <li>Prowadzisz zajęcia/lekcje</li>
                                <li>Zarządzasz grupami uczniów</li>
                                <li>Zbierasz opłaty za zajęcia/składki</li>
                                <li>Jesteś właścicielem szkoły/firmy prowadzącej zajęcia dydaktyczne</li>
                            </ul>
                        </div>

                        <div className="card-footer text-center">
                            <button className="btn btn-primary" onClick={selectInstructor}>
                                Wybierz
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <p className="text-body-secondary m-5 text-center">
                Jeśli masz wątpliwości, skontaktuj się z nami <Email />.
            </p>
        </>
    );
};
