import React, {useCallback, useEffect, useState} from "react";
import './learnerEvents.css';
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../apiClientV1";
import _ from "lodash";
import {LearnerConfirmPaymentCard} from "./LearnerConfirmPaymentCard";
import {LearnerRejectPaymentCard} from "./LearnerRejectPaymentCard";
import {Toggler} from "../common/Toggler";
import {LearnerEventCard} from "./LearnerEventCard";
import {ListEnd} from "../common/ListEnd";
import Spinner from "../common/Spinner";

interface Props {
    learner: any;
}

export const LearnerEvents = ({learner}: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const [eventRegisterItems, setEventRegisterItems] = useState(null);
    const [eventRegisterItem, setEventRegisterItem] = useState(null);
    const [pageState, setPageState] = useState('VIEW');

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get(`/event-register?learnerId=${learner.id}`)
                    .then(res => {
                        const items = res.data?.items;
                        const sortFn = (st, nd) => st.event.eventDate < nd.event.eventDate ? 1 : -1;
                        const sortFnRev = (st, nd) => st.event.eventDate < nd.event.eventDate ? -1 : 1;

                        const itemsByStatus = _.groupBy(items, 'status');
                        const unpaidEvents = itemsByStatus['UNPAID']
                            ? itemsByStatus['UNPAID'].sort(sortFnRev)
                            : [];
                        const otherEvents = items
                            .filter(it => !unpaidEvents.map(uc => uc.id).includes(it.id))
                            .sort(sortFn);

                        const eventRegisterItems = [...unpaidEvents, ...otherEvents];

                        setEventRegisterItems(eventRegisterItems);
                    });
            });
    }, [learner.id, getAccessTokenSilently]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleAttemptingPayment = (eventRegisterItem) => {
        setEventRegisterItem(eventRegisterItem);
        setPageState('PAYING-FOR-EVENT');
    }

    const handleRejectingPaymentForEvent = (eventRegisterItem) => {
        setEventRegisterItem(eventRegisterItem);
        setPageState('REJECTING-PAYMENT');
    }

    const cancelCallback = () => {
        setPageState('VIEW');
        setEventRegisterItem(null);
    }

    const refreshViewCallback = () => {
        fetchData();
        setPageState('VIEW');
        setEventRegisterItem(null);
    }

    return <>
        {pageState === 'PAYING-FOR-EVENT' && eventRegisterItem &&
            <LearnerConfirmPaymentCard cancelCallback={cancelCallback}
                                       eventRegisterItem={eventRegisterItem}/>
        }

        {pageState === 'REJECTING-PAYMENT' && eventRegisterItem &&
            <LearnerRejectPaymentCard cancelCallback={cancelCallback}
                                      refreshViewCallback={refreshViewCallback}
                                      eventRegisterItem={eventRegisterItem}/>
        }

        {pageState === 'VIEW' && <>
            <div className="container mt-5">
                {/*SHOW CARDS IF THERE ARE ANY*/}
                <Toggler condition={eventRegisterItems && eventRegisterItems.length !== 0}>
                    <div className="row justify-content-center">
                        <div className="col-11 col-sm-10 col-md-9 col-lg-8">

                            <div className="d-flex flex-column gap-2">

                                {/*SHOW CARD FOR EACH EVENT REGISTER ITEM*/}
                                {eventRegisterItems?.map((item, index) => <div key={item.id}>
                                    {/*CARD*/}
                                    <LearnerEventCard eventRegisterItem={item}
                                                      rejectPaymentCallback={() => handleRejectingPaymentForEvent(item)}
                                                      attemptPaymentCallback={() => handleAttemptingPayment(item)} />

                                    {/* SEPARATOR */}
                                    <Toggler condition={index < eventRegisterItems.length - 1}>
                                        <div className="text-center mt-2">::</div>
                                    </Toggler>
                                </div>)}

                            </div>

                             <ListEnd content="koniec listy"/>
                        </div>
                    </div>
                </Toggler>

                {/*IF NO CARDS TO SHOW, DISPLAY THE MESSAGE*/}
                <Toggler condition={eventRegisterItems?.length === 0}>
                    <div className="parent">
                        <div className="child text-center display-6">Brak zajęć</div>
                    </div>
                </Toggler>

                {/*IF NO CARDS TO SHOW, DISPLAY THE MESSAGE*/}
                <Toggler condition={!eventRegisterItems}>
                    <div className="parent">
                        <Spinner />
                    </div>
                </Toggler>
            </div>
        </>
        }
    </>
}
