import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPln } from '../../lib/utils';
import { EventDate } from '../common/EventDate';
import { EventTime } from '../common/EventTime';
import { Toggler } from '../common/Toggler';
import { EventRegisterStatusBadge } from '../common/EventRegisterStatusBadge';
import { EventStatusBadge } from '../common/EventStatusBadge';

interface Props {
    eventRegisterItem: any;
    rejectPaymentCallback: () => void;
    attemptPaymentCallback: () => void;
}

export const LearnerEventCard = ({ eventRegisterItem: item, rejectPaymentCallback, attemptPaymentCallback }: Props) => {
    const attendanceIcon = (attendanceType) => {
        switch (attendanceType) {
            case 'PRESENT':
                return <FontAwesomeIcon icon="fa-solid fa-user" size="1x" />;
            case 'ABSENT':
                return <FontAwesomeIcon icon="fa-solid fa-user-slash" size="1x" />;
            default:
                return <></>;
        }
    };

    function getPreferredPaymentType(item) {
        const paymentType = item?.event?.additionalDetails?.preferredPaymentType;

        if (!paymentType) {
            return '';
        }

        switch (paymentType) {
            case 'CASH':
                return 'gotówkę';
            case 'ONLINE':
                return 'płatność online';
            case 'TRANSFER':
                return 'przelew';
            default:
                return '';
        }
    }

    function isTrue(item, fieldName) {
        // compare 'true' as string as that's how it comes back form the backend
        return item?.event?.additionalDetails[fieldName] === 'true';
    }

    function extractBankAccount(item) {
        return item?.event?.additionalDetails?.bankAccount;
    }

    function displayAcceptedPaymentTypes(item) {
        const acceptedPaymentTypes = [];

        if (isTrue(item, 'acceptCash')) {
            acceptedPaymentTypes.push('gotówka');
        }

        if (isTrue(item, 'acceptOnlinePayment')) {
            acceptedPaymentTypes.push('online');
        }

        if (isTrue(item, 'acceptTransfer')) {
            acceptedPaymentTypes.push(`przelew: ${extractBankAccount(item)}`);
        }

        return acceptedPaymentTypes.join(' | ');
    }

    return (
        <div
            className={`card ${item?.metadata?.requiresPayment && 'border-danger'} ${
                item?.metadata?.hasPaidButEventIsCancelled && 'text-bg-warning'
            }`}
        >
            {/*HEADER*/}
            <div className="card-header">
                <div className="d-flex justify-content-between">
                    {/*SHOW DATE AND TIME*/}
                    <div className="d-flex flex-row gap-2 fs-6 fw-light">
                        {/*STUDENT ATTENDANCE*/}
                        <div>{attendanceIcon(item.attendanceType)}</div>

                        {/*EVENT DATE*/}
                        <EventDate date={item?.event?.eventDate} separator=" :: " />

                        <div>::</div>

                        {/*EVENT TIME*/}
                        <div className="fw-bold">
                            <EventTime event={item?.event} />
                        </div>
                    </div>

                    {/*SHOW STATUS*/}
                    <div>
                        <Toggler condition={item?.event?.status === 'CANCELLED'}>
                            <EventStatusBadge status={item.event.status} />
                        </Toggler>
                        <Toggler condition={item?.status !== 'CANCELLED'}>
                            <EventRegisterStatusBadge status={item.status} />
                        </Toggler>
                    </div>
                </div>
            </div>

            {/*BODY*/}
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="card-title fs-5">{item?.event?.displayName}</div>
                        <p className="card-text">{item?.event?.description}</p>

                        <p className="card-text small text-muted">
                            {item?.organisation?.name} / {item?.studentGroup?.groupName}
                        </p>
                    </div>

                    <div>
                        <div className="fw-bold fs-2">
                            <Toggler condition={item?.fee?.value !== 0}>{formatPln(item?.fee.value)}</Toggler>
                        </div>
                    </div>
                </div>

                <Toggler condition={item?.metadata?.requiresPayment && item?.metadata?.acceptsOnlinePayment}>
                    <div className="d-flex justify-content-end gap-2">
                        {/*DISABLE REJECTING PAYMENT BY THE GUARDIAN. NOT SURE IF THIS IS USEFUL AT ALL*/}

                        {/*<button className="btn btn-sm btn-warning"*/}
                        {/*        onClick={rejectPaymentCallback}>Odrzuć*/}
                        {/*</button>*/}
                        <button className="btn btn-sm btn-success" onClick={attemptPaymentCallback}>
                            Zapłać online
                        </button>
                    </div>
                </Toggler>
            </div>

            {/*FOOTER*/}
            <Toggler condition={!!item?.metadata?.requiresPayment}>
                <div className="card-footer">
                    <div className="text-muted fw-light">
                        {displayAcceptedPaymentTypes(item)}
                        {getPreferredPaymentType(item) !== '' && (
                            <>
                                {' '}
                                | <span className="text-primary">preferujemy {getPreferredPaymentType(item)}</span>
                            </>
                        )}
                    </div>
                </div>
            </Toggler>
        </div>
    );
};
