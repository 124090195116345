import React from 'react';
import { apiClient } from '../../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import './addStudentGroup.css';
import { ConfirmationModal } from '../common/ConfirmationModal';

interface Props {
    showModal: boolean;
    student: any;
    studentGroup: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const DeleteStudentFromGroup = ({
    showModal,
    student,
    studentGroup,
    refreshViewCallback,
    cancelCallback,
}: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const handleRemoveStudentFromGroup = (studentId) => {
        getAccessTokenSilently().then((accessToken) => {
            const removeStudentFromStudentGroupRequest = {
                action: 'REMOVE_STUDENTS_FROM_STUDENT_GROUP',
                studentIds: [studentId],
            };

            apiClient(accessToken)
                .patch(`/student-groups/${studentGroup.id}`, removeStudentFromStudentGroupRequest)
                .then(() => refreshViewCallback());
        });
    };

    return (
        student && (
            <ConfirmationModal
                showModal={showModal}
                onClose={cancelCallback}
                onConfirm={() => handleRemoveStudentFromGroup(student.id)}
            >
                <p className="text-danger fw-bold fs-5">Czy na pewno chcesz wykreślić ucznia</p>
                <div className="fs-3">{student.displayName}</div>
                <div className="small text-muted m-3">z grupy</div>
                <div className="fs-3 mb-3">{studentGroup.displayName}</div>

                <div className="small text-muted">
                    <div>Uczeń nie zostanie usunięty, jedynie wykreślony z grupy.</div>
                    <div>
                        Jeśli chcesz <b>przenieść</b> ucznia do innej grupy, najpierw <b>edytuj</b> i dodaj go do
                        właściwej grupy,
                    </div>
                    <div>
                        a później <b>wykreśl</b> go z listy na tej grupie.
                    </div>
                </div>
            </ConfirmationModal>
        )
    );
};
