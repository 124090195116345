import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {apiClient} from "../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import './organisationSwitcher.css';
import {refreshPage} from "../lib/utils";
import {useNavigate} from "react-router";
import {Toggler} from "./common/Toggler";
import {PermissionGate} from "./common/PermissionGate";
import _ from "lodash";

interface Props {
    user: any;
}

export const OrganisationSwitcher = ({user}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [organisations, setOrganisations] = useState(null);
    const [pageStatus, setPageStatus] = useState('VIEW');
    const navigate = useNavigate();

    useEffect(() => {
        if (user.permissions && user.permissions.canReadOrganisations) {
            getAccessTokenSilently()
                .then(accessToken => {
                    apiClient(accessToken).get('/organisations/_self')
                        .then(res => setOrganisations(res.data));
                });
        }
    }, [getAccessTokenSilently, user])

    function sortOrganisations(organisastions) {
        const orgDisplayNameSorter = (o) => o.displayName.toLowerCase();
        return _.orderBy(organisastions, [orgDisplayNameSorter], ['asc'])
    }

    const handleOrgChange = (org) => {
        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    action: 'UPDATE_USER_ORGANISATION',
                    organisationId: org.id
                };

                apiClient(accessToken).patch('/users/_self', payload)
                    .then(() => {
                        // NB. THIS SHOULD BE USED CAREFULLY, ONLY IN SPECIAL CIRCUMSTANCES
                        // It is OK to use in this case as we want to refresh the entire page
                        // to reflect the user changing the organisation
                        // We also have to use navigate, as we may currently be on a org specific page (e.g. events/xyz123)
                        navigate('/');
                        refreshPage();
                    });
            });
    }

    return user.organisation &&
        <div className="banner">
            <div className="d-flex flex-row align-items-center mb-3 gap-3">

                {/* VIEWING */}
                <Toggler condition={pageStatus === 'VIEW'}>
                    <FontAwesomeIcon icon="fa-solid fa-school"/>
                    <div className="fw-light">{user.organisation.displayName}</div>
                    
                    <PermissionGate requires={user.permissions.canReadOrganisations}>
                        <PermissionGate requires={user.permissions && user.permissions.canUpdateUsers}>
                            <FontAwesomeIcon
                                className="pointer"
                                icon="fa-solid fa-pencil"
                                onClick={() => setPageStatus('UPDATE_ORGANISATION')}/>
                        </PermissionGate>
                    </PermissionGate>
                </Toggler>

                {/* UPDATING */}
                {pageStatus === 'UPDATE_ORGANISATION' && organisations && <>
                    <div className="dropdown d-flex flex-row gap-3">
                        <button className="btn btn-outline-primary btn-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                            Wybierz szkołę
                        </button>

                        <button className="btn btn-sm btn-outline-secondary"
                                onClick={() => setPageStatus('VIEW')}>Anuluj
                        </button>

                        <ul className="dropdown-menu">
                            {sortOrganisations(organisations).map(org =>
                                <li key={org.id}>
                                    <div className={`dropdown-item ${org.id === user.organisation.id && 'disabled'}`}
                                         onClick={() => handleOrgChange(org)}>{org.displayName}
                                    </div>
                                </li>)}
                        </ul>
                    </div>
                </>}

            </div>
        </div>
}
