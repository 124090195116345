import * as React from "react";
import {Outlet} from "react-router-dom";
import './fullScreenLayout.css';
import {GenericNavbar} from "../components/GenericNavbar";

export const FullScreenLayout = () => {
    return (
        <div className="layout-container">
            <GenericNavbar />
            {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
            <div className="outlet">
                <Outlet/>
            </div>
        </div>
    );
};
