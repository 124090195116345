import React, {useCallback, useEffect, useState} from "react";
import "./studentsTable.css";
import _ from 'lodash';
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../apiClientV1";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DANGER_COLOUR, SECONDARY_COLOUR, SUCCESS_COLOUR} from "../../lib/constants";
import Spinner from "../common/Spinner";
import {ListEnd} from "../common/ListEnd";

interface Props {
    students: [],
    events: [];
}

const PAID_ONLINE_ICON = <FontAwesomeIcon icon="fa-solid fa-coins" size="2x" style={{color: SUCCESS_COLOUR}}/>;
const PAID_CASH_ICON = <FontAwesomeIcon icon="fa-solid fa-money-bill-wave" size="2x" style={{color: SUCCESS_COLOUR}}/>;
const PAID_BANK_TRANSFER_ICON = <FontAwesomeIcon icon="fa-solid fa-money-bill-transfer" size="2x" style={{color: SUCCESS_COLOUR}}/>;
const PAID_OTHER_ICON = <FontAwesomeIcon icon="fa-solid fa-comments-dollar" size="2x" style={{color: SUCCESS_COLOUR}}/>;
const UNPAID_ICON = <FontAwesomeIcon icon="fa-solid fa-circle-xmark" size="2x" style={{color: DANGER_COLOUR}}/>;
const PAYMENT_NOT_REQUIRED_ICON = <FontAwesomeIcon icon="fa-solid fa-circle-minus" size="2x" style={{color: SECONDARY_COLOUR}}/>;
const DEFAULT_PAYMENT_ICON = <FontAwesomeIcon icon="fa-solid fa-question-circle" size="2x" style={{color: SECONDARY_COLOUR}}/>;

const PRESENT_ICON = <FontAwesomeIcon icon="fa-solid fa-user" size="2x"/>;
const ABSENT_ICON = <FontAwesomeIcon icon="fa-solid fa-user-slash" size="2x"/>;
const UNKNOWN_PRESENCE_ICON = <FontAwesomeIcon icon="fa-solid fa-question-circle" size="2x"/>;

export const StudentsPaymentEntryTable = ({events}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    
    const [eventRegisterItemMapByKey, setEventRegisterItemMapByKey] = useState();
    
    const toKey = (event, student) => {
        return `${event?.id}::${student?.id}`;
    }
    
    const fetchEventRegisterData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                events.forEach(event => {
                    apiClient(accessToken).get(event?._links?.eventRegisterByEvent?.href)
                        .then(eventRegisterResult => {
                            const items = eventRegisterResult?.data?.items || [];

                            items.forEach(i => {
                                updateMap(toKey(i.event, i.student), i);
                            });
                        });
                });
            })
        }, [events, getAccessTokenSilently]);

    
    useEffect(() => {
        fetchEventRegisterData();
    }, [fetchEventRegisterData]);
    
    
    const sortStudents = (students) => {
        const firstNameSorter = (s) => s.firstName.toLowerCase();
        const lastNameSorter = (s) => s.lastName.toLowerCase();

        return _.orderBy(students, [lastNameSorter, firstNameSorter], ['asc', 'asc']);
    }
    
    const getStudents = (registerItemsMap) => {
        const studentMap = new Map();
        const sts = [];

        registerItemsMap.forEach((v, _k, _i) => {
            const studentId = v.student?.id;
            if (studentId) {
                studentMap.set(studentId, v.student);
            }
        });

        studentMap.forEach((v, _k) => sts.push(v));
        return sts;
    }
    
    
    const attendanceIcon = (attendanceType) => {
        switch (attendanceType) {
            case 'PRESENT': return PRESENT_ICON
            case 'ABSENT': return ABSENT_ICON
            default: return UNKNOWN_PRESENCE_ICON
        }
    };
    
    
    const paymentType = (eventRegisterItem) => {
        switch (eventRegisterItem?.status) {
            case 'PAID': return PAID_ONLINE_ICON
            case 'PAID_CASH': return PAID_CASH_ICON
            case 'PAID_BANK_TRANSFER': return PAID_BANK_TRANSFER_ICON
            case 'PAID_OTHER': return PAID_OTHER_ICON
            case 'UNPAID': return UNPAID_ICON
            case 'COMPLETED_UNPAID': return PAYMENT_NOT_REQUIRED_ICON
            case 'WONT_PAY': return PAYMENT_NOT_REQUIRED_ICON
            default: return DEFAULT_PAYMENT_ICON
        }
    };
    
    const updateMap = (key, value) => {
        setEventRegisterItemMapByKey(map => new Map(map?.set(key, value)));
    }
    
    const handleAttendanceChange = (eventRegister, attendance) => {
        const placeholderRegister = {
            ...eventRegister,
            'internalStatus': 'PENDING',
            'attendanceType': attendance
        };

        updateMap(toKey(eventRegister.event, eventRegister.student), placeholderRegister);
        
        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    'action': 'MARK_ATTENDANCE',
                    'attendanceType': attendance
                }

                apiClient(accessToken).patch(`/event-register/${eventRegister.id}`, payload)
                    .then(() => {
                        fetchEventRegisterData();
                    });
            });
    }

    const handlePaymentTypeChange = (eventRegister, nextStatus) => {
        const placeholderRegister = {
            ...eventRegister,
            'internalStatus': 'PENDING',
            'status': nextStatus
        };
        updateMap(toKey(eventRegister.event, eventRegister.student), placeholderRegister);

        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    action: getNextAction(nextStatus),
                    status: nextStatus,
                    amount: eventRegister.fee
                }

                apiClient(accessToken).patch(`/event-register/${eventRegister.id}`, payload)
                    .then(() => {
                        fetchEventRegisterData();
                    });
            });
    }
    
    const getNextAction = (status) => {
        switch (status) {
            case 'PAYMENT_NOT_REQUIRED': return 'MARK_AS_COMPLETED_UNPAID';
            case 'PAID_CASH': return 'MARK_AS_PAID';
            case 'PAID_BANK_TRANSFER': return 'MARK_AS_PAID';
            case 'PAID_OTHER': return 'MARK_AS_PAID';
            case 'UNPAID': return 'MARK_AS_UNPAID';
            default: return 'MARK_AS_UNPAID';
        }
    }

    const getNextAttendance = (currentAttendance) => {
        switch (currentAttendance) {
            case 'PRESENT': return 'ABSENT';
            case 'ABSENT': return 'UNKNOWN';
            default: return 'PRESENT';
        }
    }

    const getNextStatus = (currentStatus) => {
        switch (currentStatus) {
            case 'UNPAID': return 'PAID_CASH';
            case 'PAID_CASH': return 'PAID_BANK_TRANSFER';
            case 'PAID_BANK_TRANSFER': return 'PAID_OTHER';
            case 'PAID_OTHER': return 'PAYMENT_NOT_REQUIRED';
            case 'PAYMENT_NOT_REQUIRED': return 'UNPAID';
            default: return 'UNPAID';
        }
    }
    
    const actionControll = (eventRegisterItem) => {
        if (!eventRegisterItem) {
            return;
        }
        
        if (eventRegisterItem.internalStatus === 'PENDING') {
            return <Spinner />
        }
        
        const nextAttendance = getNextAttendance(eventRegisterItem.attendanceType);
        const nextStatus = getNextStatus(eventRegisterItem.status);
        
        return <div className="d-flex gap-4">
            <div onClick={() => handleAttendanceChange(eventRegisterItem, nextAttendance)}>
                {attendanceIcon(eventRegisterItem.attendanceType)}
            </div>
            
            <div onClick={() => handlePaymentTypeChange(eventRegisterItem, nextStatus)}>
                {paymentType(eventRegisterItem)}
            </div>
        </div>
    }
    
    return eventRegisterItemMapByKey && <>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Uczeń</th>
                        {events.map(e => (
                            <th key={e.id} scope="col">{e.eventName}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody className="table-group-divider">

                        {sortStudents(getStudents(eventRegisterItemMapByKey)).map((student, index) =>
                            <tr key={student.id + '-' + index}>
                                <td className="pt-2">{index + 1}</td>
                                <td className="pt-2">{student.lastName}, {student.firstName}</td>
                                
                                {events.map(e =>
                                    <td key={toKey(e, student)} className="pt-2">
                                        {actionControll(eventRegisterItemMapByKey.get(toKey(e, student)))}
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>

                <ListEnd content="koniec danych" />
        </>
}
