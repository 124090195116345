import React, { useState } from 'react';
import { InputTextBox } from '../../common/InputTextBox';

const ENROLMENT_LEVEL_PLACEHOLDER = 'poziom';
const ENROLMENT_CAPACITY_PLACEHOLDER = 'wielkość grupy';
const ENROLMENT_START_PLACEHOLDER = 'rozpoczęcie zajęć';
const ENROLMENT_END_PLACEHOLDER = 'zakończenie zajęć';
const ENROLMENT_FEE_PLACEHOLDER = 'koszt zajęć';

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    nextStepCallback: () => void;
    previousStepCallback: () => void;
}

export const CollectEnrolmentAdditionalDetails = ({
    previousStepCallback,
    nextStepCallback,
    inputValues,
    inputHandler,
}: Props) => {
    const [errors, setErrors] = useState({});

    const validateInputAndCallNextStep = () => {
        let validationResult = validateInput(inputValues);
        setErrors(validationResult.errors);

        if (validationResult.isValid) {
            nextStepCallback();
        }
    };

    return (
        <>
            <div className="fs-5 fw-light">Dodatkowe dane</div>

            {/************ EXPERIENCE LEVEL INPUT ************/}
            <InputTextBox
                inputValues={inputValues}
                inputName="experienceLevel"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_LEVEL_PLACEHOLDER}
                errors={errors}
            />

            {/************ CAPACITY INPUT ************/}
            <InputTextBox
                inputType="number"
                inputValues={inputValues}
                inputName="capacity"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_CAPACITY_PLACEHOLDER}
                errors={errors}
            />

            {/************ TERM START INPUT ************/}
            <InputTextBox
                inputType="date"
                inputValues={inputValues}
                inputName="termStartsOn"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_START_PLACEHOLDER}
                errors={errors}
            />

            {/************ TERM END INPUT ************/}
            <InputTextBox
                inputType="date"
                inputValues={inputValues}
                inputName="termEndsOn"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_END_PLACEHOLDER}
                errors={errors}
            />

            {/************ FEE INPUT ************/}
            <InputTextBox
                inputType="number"
                inputValues={inputValues}
                inputName="fee"
                inputHandler={inputHandler}
                placeholder={ENROLMENT_FEE_PLACEHOLDER}
                errors={errors}
            />

            <hr />

            <div className="d-grid gap-2 d-flex justify-content-center">
                {/************ CANCEL ************/}
                <button
                    type="button"
                    className="form-floating btn btn-outline-secondary m-2"
                    onClick={previousStepCallback}
                >
                    Cofnij
                </button>

                {/************ CONTINUE ************/}
                <button
                    type="button"
                    className="form-floating btn btn-primary m-2"
                    onClick={validateInputAndCallNextStep}
                >
                    Dalej
                </button>
            </div>
        </>
    );
};

const validateInput = (input) => {
    let validationResult = { isValid: true, errors: {} };

    return validationResult;
};
