import React from 'react';
import { formatPln } from '../../lib/utils';
import './eventSummaryCards.css';
import './../../common.css';
import ClampLines from 'react-clamp-lines';
import { EventStatusBadge } from '../common/EventStatusBadge';
import { EventDate } from '../common/EventDate';
import { EventTime } from '../common/EventTime';

interface Props {
    event: any;
    eventRegisterSummary: any;
}

export const EventSummaryCards = ({ event, eventRegisterSummary }: Props) => {
    return event && eventRegisterSummary ? (
        <>
            <div className="d-flex flex-row gap-3 mb-2 fs-4 fw-light">
                <EventDate date={event.eventDate} separator=", " />
                <div>|</div>
                <EventTime event={event} />
            </div>

            <div className="fs-2 fw-bold">
                <ClampLines
                    id="event-name-clamp"
                    lines="1"
                    moreText="więcej"
                    lessText="mniej"
                    delay="1"
                    text={event.eventName}
                />
            </div>

            <div className="fs-4">
                <ClampLines
                    id="event-description-clamp"
                    lines="1"
                    moreText="więcej"
                    lessText="mniej"
                    delay="1"
                    text={event.description || ''}
                />
            </div>

            <div className="text-muted fst-italic">
                {eventRegisterSummary?.instructorName || '...'} :: {eventRegisterSummary?.studentGroupName || '...'}
            </div>

            <div className="container text-center mt-5">
                <div className="row gap-4">
                    <div className="col">
                        {/*CARD*/}
                        <div className="card text-center bg-light">
                            <div className="card-body">
                                <div className="card-title fs-5 fw-bold">
                                    {formatPln(eventRegisterSummary.feeCollectedNet, '-')}
                                </div>
                                <p className="card-text small text-muted">zebrano (net)</p>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        {/*CARD*/}
                        <div className="card text-center bg-light">
                            <div className="card-body">
                                <div className="card-title fs-5 fw-bold">
                                    {formatPln(eventRegisterSummary.feeToBeCollected, '-')}
                                </div>
                                <p className="card-text small text-muted">do zebrania</p>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        {/*CARD*/}
                        <div className="card text-center bg-light">
                            <div className="card-body">
                                <div className="card-title fs-5 fw-bold">
                                    {formatPln(eventRegisterSummary.feePerPerson, '-')}
                                </div>
                                <p className="card-text small text-muted">kwota na osobę</p>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        {/*CARD*/}
                        <div className="card text-center bg-light">
                            <div className="card-body">
                                <div className="card-title fs-5 fw-bold">
                                    <EventStatusBadge status={event.status} />
                                </div>
                                <p className="card-text small text-muted">status</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="parent">
            <div className="child text-center display-6">Brak danych</div>
        </div>
    );
};
