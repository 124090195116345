import {useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
    promptLoginOnRender: boolean;
}

export const LoginButton = ({promptLoginOnRender}: Props) => {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (promptLoginOnRender === true) {
            loginWithRedirect();
        }
    }, [loginWithRedirect, promptLoginOnRender]);

    return <button className="btn btn-primary" onClick={() => loginWithRedirect()}>Zaloguj się</button>;
};
