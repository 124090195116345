import {useCallback, useEffect, useState} from "react";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import Spinner from "../../components/common/Spinner";
import {Toggler} from "../common/Toggler";

interface Props {
    pageTitle: string;
}

export const SubscriptionDetails = ({pageTitle}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [subscription, setSubscription] = useState(null);

    const fetchSubscription = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/subscriptions')
                    .then(res => setSubscription(res.data?.subscriptions?.[0]));
            });
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetchSubscription();
    }, [fetchSubscription]);

    const onOffBadge = (enabled, enabledText, disabledText) => {
        const badgeClass = enabled ? 'text-bg-success' : 'text-bg-warning';
        const badgeText = enabled ? enabledText : disabledText;

        return <div className={`badge rounded-pill ${badgeClass}`}>{badgeText}</div>
    }
    
    const statusBadge = (status) => {
        switch (status) {
            case 'ACTIVE': return onOffBadge(true, 'aktywna', 'zawieszona');
            case 'SUSPENDED': return onOffBadge(false, 'aktywna', 'zawieszona');
            default: return onOffBadge(false, '...', '...');
        }
    }
    
    return (
        <div className="card">
            <div className="card-header">
                <span>{pageTitle}</span>
                <span className="m-2">{statusBadge(subscription?.status)}</span>
            </div>
            <div className="card-body">
                {subscription ? (
                    <div className="d-flex flex-column gap-3">
                        <div className="fs-4">Plan: {subscription.plan?.displayName}</div>
                        <div className="small">Automatyczne odnawianie: {onOffBadge(subscription.autoRenew, 'włączone', 'wyłączone')}</div>
                        <Toggler condition={!!subscription.renewsOn}>
                            <div className="small">Data odnowienia: {subscription.renewsOn}</div>
                        </Toggler>
                    </div>
                ) : (
                    <div className="text-center">
                        <Spinner/>
                    </div>
                )}
            </div>
        </div>
    )
}
