import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {LogoutButton} from "./LogoutButton";
import {LoginButton} from "./LoginButton";
import './userLoginControl.css';

interface Props {
    promptLoginOnRender: boolean;
}

export const UserLoginControl = ({promptLoginOnRender}: Props) => {
    const {isAuthenticated, isLoading} = useAuth0();

    if (isLoading) {
        return <>
            <div className="m-3 placeholder-glow" aria-hidden="true">
                <span className="btn btn-primary disabled placeholder btn-placeholder"></span>
            </div>
        </>
    }

    return isAuthenticated ? <LogoutButton/> : <LoginButton promptLoginOnRender={promptLoginOnRender}/>
};
