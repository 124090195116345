import React, {useCallback, useEffect, useState} from "react";
import {apiClient} from "../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import './studentGroupsView.css'
import {StudentGroupsTable} from "../components/studentmanagement/StudentGroupsTable";
import {AddStudentGroup} from "../components/studentmanagement/AddStudentGroup";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PermissionGate} from "../components/common/PermissionGate";
import {UpdateStudentGroup} from "../components/studentmanagement/UpdateStudentGroup";
import {DeleteStudentGroup} from "../components/studentmanagement/DeleteStudentGroup";

interface Props {
    user: any;
}

export const StudentGroupsView = ({user}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [studentGroupList, setStudentGroupList] = useState(null);
    const [pageStatus, setPageStatus] = useState('VIEW');
    const [selectedStudentGroup, setSelectedStudentGroup] = useState();

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/student-groups')
                    .then(res => {
                        setStudentGroupList(res.data)
                    });
            });
    }, [getAccessTokenSilently]);

    const refreshView = () => {
        fetchData();
        setPageStatus('VIEW');
    }

    const selectStudentGroupForEdit = (studentGroup) => {
        setSelectedStudentGroup(studentGroup);
        setPageStatus('EDIT-STUDENT-GROUP-NAME');
    }

    const selectStudentGroupToDelete = (studentGroup) => {
        setSelectedStudentGroup(studentGroup);
        setPageStatus('DELETE-STUDENT-GROUP');
    }

    const cancel = () => {
        setSelectedStudentGroup(null);
        setPageStatus('VIEW');
    }

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return studentGroupList && user && user.permissions ? <>

            {/************ ADDING NEW STUDENT GROUP ************/}
            {pageStatus === 'ADD NEW STUDENT GROUP' &&
                <PermissionGate requires={user.permissions.canCreateStudentGroups}>
                    <AddStudentGroup studentGroup={studentGroupList}
                                     refreshViewCallback={() => refreshView()}
                                     cancelCallback={() => setPageStatus('VIEW')}/>
                </PermissionGate>
            }

            {/************ EDIT-STUDENT-GROUP-NAME ************/}
            {pageStatus === 'EDIT-STUDENT-GROUP-NAME' && selectedStudentGroup &&
                <PermissionGate requires={user.permissions.canUpdateStudentGroups}>
                    <UpdateStudentGroup studentGroup={selectedStudentGroup}
                                        studentGroups={studentGroupList}
                                        refreshViewCallback={refreshView}
                                        cancelCallback={cancel}/>
                </PermissionGate>
            }

            {/************ DELETE-STUDENT-GROUP ************/}
            {pageStatus === 'DELETE-STUDENT-GROUP' && selectedStudentGroup &&
                <PermissionGate requires={user.permissions.canDeleteStudentGroups}>
                    <DeleteStudentGroup studentGroup={selectedStudentGroup}
                                        refreshViewCallback={refreshView}
                                        cancelCallback={cancel}/>
                </PermissionGate>
            }

            {/************ VIEWING STUDENT GROUPS ************/}
            {pageStatus === 'VIEW' && <>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" aria-current="page">
                            <Link to="/"><FontAwesomeIcon icon="fa-solid fa-home"/></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Grupy</li>
                    </ol>
                </nav>

                <div className="d-grid gap-2 d-flex justify-content-end m-3">
                    <PermissionGate requires={user.permissions.canCreateStudentGroups}>
                        <button type="button" className="btn btn-primary"
                                onClick={() => setPageStatus('ADD NEW STUDENT GROUP')}>+ Nowa grupa
                        </button>
                    </PermissionGate>
                </div>

                <StudentGroupsTable
                    data={studentGroupList}
                    selectStudentGroupForEditCallback={selectStudentGroupForEdit}
                    selectStudentGroupToDeleteCallback={selectStudentGroupToDelete} />
            </>}
        </>
        :
        // ************ PLACEHOLDER ************
        <>
            <div className="placeholder-glow" aria-hidden="true">
                <span className="placeholder col-1"></span>
            </div>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-end m-3 placeholder-glow" aria-hidden="true">
                <span className="btn btn-primary disabled placeholder col-1"></span>
            </div>
            <div className="placeholder-glow p-3" aria-hidden="true">
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
            </div>
        </>
}
