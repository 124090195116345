import * as React from "react";
import {Link} from "react-router-dom";
import './notFoundView.css';

export const NotFoundView = () => {
    return <div className="parent">
        <div className="child">
            <h3>404</h3>
            <hr/>
            <h1>Nie znaleziono danej strony</h1>
            <hr/>
            <p>
                <Link to="/">Wróć do strony głównej</Link>
            </p>
        </div>
    </div>
}
