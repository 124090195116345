import React, {useEffect, useState} from "react";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {Toggler} from "../common/Toggler";
import _ from 'lodash';
import Spinner from "../common/Spinner";
import {getLocale} from "../../lib/utils";

interface Props {
    students: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const SendStudentsCodeViaSMS = ({students, cancelCallback, refreshViewCallback}: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const [pageState, setPageState] = useState('VIEW');
    const [filteredStudents, setFilteredStudents] = useState(null);

    const handleSubmit = (recipients) => {
        setPageState('LOADING');

        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    studentIds: recipients.map(s => s.id),
                    deliveryMethod: 'SMS',
                    messageType: 'STUDENT_CODE',
                    languageCode: getLocale().languageCode
                }
                apiClient(accessToken).post('/messages', payload)
                    .then(() => setPageState('SEND-SUCCESS'));
            });
    }

    useEffect(() => {
        let studentList = students.filter(s => s.metadata?.canSendCodeViaSMS === true);
        let sorted = _.orderBy(studentList, ['lastName', 'firstName'], ['asc', 'asc']);
        setFilteredStudents(sorted);
    }, [students]);

    return filteredStudents && filteredStudents.length > 0 && <>
        <div className="container text-center mt-5">
            <div className="row justify-content-center">
                <div className="col-9 col-sm-6 col-md-4">

                    <Toggler condition={pageState === 'LOADING'}>
                        <div className="text-muted m-5">Wysyłanie...</div>
                        <Spinner/>
                    </Toggler>

                    <Toggler condition={pageState === 'SEND-SUCCESS'}>
                        <div className="fs-3">SMSy zostały zapisane i zostaną wysłane przy najbliższej okazji.</div>

                        <button type="button" className="form-floating btn btn-primary m-2"
                                onClick={refreshViewCallback}>OK
                        </button>
                    </Toggler>

                    <Toggler condition={pageState === 'VIEW'}>

                        <Toggler condition={filteredStudents.length === 1}>
                            <div className="fs-5">Wyślij kod <span
                                className="fw-bold">{filteredStudents[0].externalId}</span> do
                            </div>
                            <div className="fs-3 m-2">{filteredStudents[0].displayName}</div>
                            <div className="fs-5">na numer</div>
                            <div className="fs-3 m-2">{filteredStudents[0].phoneNumber}</div>
                        </Toggler>

                        <Toggler condition={filteredStudents.length > 1}>
                            <div className="mt-5">
                                <div
                                    className="fs-3">Wyślij {filteredStudents.length} {filteredStudents.length < 5 ? 'kody' : 'kodów'}</div>

                                <div className="mt-3">
                                    <button className="btn btn-secondary"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseExample"
                                            aria-expanded="false"
                                            aria-controls="collapseExample">
                                        Pokaż listę studentów
                                    </button>
                                </div>

                                <div className="collapse mt-3" id="collapseExample">
                                    <ul className="list-group">
                                        {filteredStudents.map(s => <li key={s.id}
                                                                       className="list-group-item">{s.displayName}</li>)}
                                    </ul>
                                </div>
                            </div>
                        </Toggler>

                    </Toggler>

                </div>
            </div>

            <Toggler condition={pageState === 'VIEW'}>

                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                    {/************ CANCEL ADDING NEW STUDENT ************/}
                    <button type="button" className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}>Anuluj
                    </button>

                    {/************ ADD NEW STUDENT ************/}
                    <button type="button" className="form-floating btn btn-primary m-2"
                            onClick={() => handleSubmit(filteredStudents)}>Wyślij {filteredStudents.length > 1 ? 'SMSy' : 'SMS'}
                    </button>
                </div>
            </Toggler>

        </div>
    </>
}
