import React, { useCallback, useEffect, useState } from 'react';
import { apiClient } from '../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import './../common.css';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggler } from '../components/common/Toggler';
import { PermissionGate } from '../components/common/PermissionGate';
import { EventRegister } from '../components/events/EventRegister';
import { AddStudentsToEvent } from '../components/events/eventManagement/AddStudentsToEvent';
import { EventSummaryCards } from '../components/events/EventSummaryCards';
import _ from 'lodash';
import { WithdrawMoney } from '../components/events/eventManagement/WithdrawMoney';
import { CustomMessageSender } from '../components/messages/CustomMessageSender';
import { EditEvent } from '../components/events/eventManagement/EditEvent';
import { EventDraftWarningCard } from '../components/events/EventDraftWarningCard';
import { ConfirmationModal } from '../components/common/ConfirmationModal';

interface Props {
    user: any;
}

export const EventRegisterView = ({ user }: Props) => {
    const { eventId } = useParams();

    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    const [event, setEvent] = useState(null);
    const [pageState, setPageState] = useState('VIEW');
    const [eventRegisterItems, setEventRegisterItems] = useState(null);
    const [eventRegisterSummary, setEventRegisterSummary] = useState(null);
    const [activeModal, setActiveModal] = useState();

    const fetchEventRegister = useCallback(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get(`/event-register?eventId=${eventId}`)
                .then((res) => {
                    const eventRegister = res?.data;
                    setEventRegisterSummary(eventRegister?.summary);

                    const sortedItems = _.orderBy(
                        eventRegister?.items,
                        ['student.lastName', 'student.firstName', 'student.id'],
                        ['asc', 'asc', 'asc'],
                    );
                    setEventRegisterItems(sortedItems);
                });
        });
    }, [eventId, getAccessTokenSilently]);

    const fetchEvent = useCallback(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get(`/events/${eventId}`)
                .then((res) => setEvent(res?.data));
        });
    }, [eventId, getAccessTokenSilently]);

    const refreshView = () => {
        fetchEvent();
        fetchEventRegister();
        setPageState('VIEW');
    };

    useEffect(() => {
        fetchEvent();
        fetchEventRegister();
    }, [fetchEvent, fetchEventRegister]);

    function handleStatusChange(action) {
        getAccessTokenSilently().then((accessToken) => {
            let payload = {
                action: action,
            };

            apiClient(accessToken)
                .patch(`/events/${eventId}`, payload)
                .then(() => refreshView());
        });
    }

    function handleDeleteEvent() {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .delete(`/events/${eventId}`)
                .then(() => navigate('/events'));
        });
    }

    function closeModal() {
        setActiveModal('');
    }

    return user && event && eventRegisterSummary ? (
        <>
            {/************ ADD-STUDENTS-TO-EVENT ************/}
            {pageState === 'ADD-STUDENTS-TO-EVENT' && (
                <PermissionGate
                    requires={user.permissions.canCreateEventRegister && user.permissions.canViewStudentGroups}
                >
                    <AddStudentsToEvent
                        event={event}
                        user={user}
                        refreshViewCallback={() => refreshView()}
                        cancelCallback={() => setPageState('VIEW')}
                    />
                </PermissionGate>
            )}

            {/************ WITHDRAW MONEY FROM THE EVENT ************/}
            {pageState === 'WITHDRAW-MONEY' && (
                <PermissionGate requires={user.permissions.canCreateStripePayouts}>
                    <WithdrawMoney
                        user={user}
                        event={event}
                        eventRegisterSummary={eventRegisterSummary}
                        refreshViewCallback={() => refreshView()}
                        cancelCallback={() => setPageState('VIEW')}
                    />
                </PermissionGate>
            )}

            {/************ SEND MESSAGE FOR THE EVENT PARTICIPANTS ************/}
            {pageState === 'SEND-MESSAGE' && (
                <PermissionGate requires={user.permissions.canCreateMessages}>
                    <CustomMessageSender
                        eventRegisterItems={eventRegisterItems}
                        refreshViewCallback={() => refreshView()}
                        cancelCallback={() => setPageState('VIEW')}
                    />
                </PermissionGate>
            )}

            {/************ SEND MESSAGE FOR THE EVENT PARTICIPANTS ************/}
            {pageState === 'UPDATE-EVENT' && (
                <PermissionGate requires={user.permissions.canUpdateEvents}>
                    <EditEvent
                        event={event}
                        refreshViewCallback={() => refreshView()}
                        cancelCallback={() => setPageState('VIEW')}
                    />
                </PermissionGate>
            )}

            {/************ VIEWING EVENTS ************/}
            {pageState === 'VIEW' && (
                <>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/">
                                    <FontAwesomeIcon icon="fa-solid fa-home" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/events">Zajęcia</Link>
                            </li>
                            <li className="breadcrumb-item active text-truncate truncated-200" aria-current="page">
                                {event.eventName}
                            </li>
                        </ol>
                    </nav>

                    <Toggler condition={event.status === 'DRAFT'}>
                        <div className="d-flex justify-content-center">
                            <EventDraftWarningCard />
                        </div>
                    </Toggler>

                    {/*<pre>{JSON.stringify(selectedStudents, null, 2)}</pre>*/}

                    <div className="d-grid gap-2 d-flex justify-content-end m-3 flex-wrap">
                        <PermissionGate requires={user.permissions.canUpdateEvents}>
                            <PermissionGate requires={user.permissions.canUpdateEvents}>
                                <Toggler condition={event.metadata.canEditEvent}>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => setPageState('UPDATE-EVENT')}
                                    >
                                        Edytuj
                                    </button>
                                </Toggler>
                            </PermissionGate>

                            <PermissionGate requires={user.permissions.canCreateMessages}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setPageState('SEND-MESSAGE')}
                                >
                                    Wyślij wiadomość
                                </button>
                            </PermissionGate>

                            <PermissionGate requires={user.permissions.canViewStudentGroups}>
                                <Toggler condition={event.metadata.canAddStudentToEvent}>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => setPageState('ADD-STUDENTS-TO-EVENT')}
                                    >
                                        Dodaj studentów
                                    </button>
                                </Toggler>
                            </PermissionGate>

                            <Toggler condition={event.metadata.canConfirmEvent}>
                                <ConfirmationModal
                                    showModal={activeModal === 'confirm_event'}
                                    onClose={closeModal}
                                    onConfirm={() => handleStatusChange('CONFIRM_EVENT')}
                                >
                                    <p className="text-danger fw-bold fs-5">Czy na pewno chcesz potwierdzić zajęcia?</p>
                                    <p className="text-muted">
                                        Rodzic/opiekun będzie mógł widzieć zajęcia po zalogowaniu.
                                    </p>
                                </ConfirmationModal>

                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => setActiveModal('confirm_event')}
                                >
                                    Potwierdź zajęcia
                                </button>
                            </Toggler>

                            <Toggler
                                condition={event.metadata.canFinishEvent && eventRegisterSummary.readyToFinishRegister}
                            >
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => handleStatusChange('FINISH_EVENT')}
                                >
                                    Zakończ
                                </button>
                            </Toggler>
                        </PermissionGate>

                        <PermissionGate requires={user.permissions.canDeleteEvents}>
                            <Toggler condition={event.metadata?.canDeleteEvent}>
                                <ConfirmationModal
                                    showModal={activeModal === 'delete_event'}
                                    onClose={closeModal}
                                    onConfirm={() => handleDeleteEvent()}
                                >
                                    <p className="text-danger fw-bold fs-5">Czy na pewno chcesz usunąć zajęcia?</p>
                                    <p className="text-muted">Dane zostaną usunięte bez możliwości przywrócenia.</p>
                                </ConfirmationModal>

                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => setActiveModal('delete_event')}
                                >
                                    Usuń zajęcia
                                </button>
                            </Toggler>

                            <Toggler condition={event.metadata?.canCancelEvent}>
                                <ConfirmationModal
                                    showModal={activeModal === 'cancel_event'}
                                    onClose={closeModal}
                                    onConfirm={() => handleStatusChange('CANCEL_EVENT')}
                                >
                                    <p className="text-danger fw-bold fs-5">Czy na pewno chcesz odwołać zajęcia?</p>
                                    <p className="text-muted">
                                        Możesz wziąć pod uwagę zmianę daty/czasu wydarzenia zamiast ich odwołania.
                                    </p>
                                </ConfirmationModal>

                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => setActiveModal('cancel_event')}
                                >
                                    Odwołaj
                                </button>
                            </Toggler>
                        </PermissionGate>

                        <PermissionGate requires={user.permissions.canCreateStripePayouts}>
                            <Toggler
                                condition={event.metadata.canWithdrawMoney && eventRegisterSummary.payoutAvailable}
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setPageState('WITHDRAW-MONEY')}
                                >
                                    Wypłać środki
                                </button>
                            </Toggler>
                        </PermissionGate>
                    </div>

                    <div className="mb-5">
                        <EventSummaryCards event={event} eventRegisterSummary={eventRegisterSummary} />

                        <EventRegister
                            event={event}
                            eventRegisterItems={eventRegisterItems}
                            user={user}
                            refreshViewCallback={refreshView}
                        />
                    </div>
                </>
            )}
        </>
    ) : (
        // ************ PLACEHOLDER ************
        <div aria-hidden="true">
            {/*BREADCRUMBS*/}
            <div className="placeholder-glow">
                <span className="placeholder col-1"></span>
            </div>

            {/*BUTTONS*/}
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-end m-3 placeholder-glow">
                <span className="btn btn-primary disabled placeholder col-1"></span>
                <span className="btn btn-secondary disabled placeholder col-1"></span>
            </div>

            <div className="placeholder-glow">
                <h1 className="placeholder-glow">
                    <span className="placeholder col-4"></span>
                </h1>
                <p className="placeholder-glow">
                    <span className="placeholder col-3"></span>
                </p>
            </div>

            {/*CARDS*/}
            <div className="placeholder-glow text-center p-3">
                <span className="placeholder col-3 m-1 p-5"></span>
                <span className="placeholder col-3 m-1 p-5"></span>
                <span className="placeholder col-3 m-1 p-5"></span>
            </div>

            {/*TABLE*/}
            <div className="placeholder-glow text-center p-3">
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
            </div>
        </div>
    );
};
