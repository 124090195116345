import * as React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { UserLoginControl } from "./login/UserLoginControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./homeNavbar.css";
import { Toggler } from "./common/Toggler";
import { PermissionGate } from "./common/PermissionGate";

const UI_VERSION = process.env.REACT_APP_UI_VERSION;

interface Props {
    userName: string;
    userPermissions: any;
    userMetadata: any;
}

export const ProtectedHomeNavbar = ({
    userName,
    userPermissions,
    userMetadata,
}: Props) => {
    const [showShelfMenu, setShowShelfMenu] = useState(false);

    const toggleShelfMenu = () => {
        setShowShelfMenu(!showShelfMenu);
    };

    return <>
        <nav className="home-navbar navbar sticky-top navbar-expand-sm bg-light col-span-full">

            {/* SHELF MENU ON SMALLER SCREENS */}
            <Toggler condition={showShelfMenu}>
                <div id="fullscreen" onClick={toggleShelfMenu}>
                    <div className="shelf-menu">
                        {/* SHOWS CURRENT USER */}
                        <div className="mb-3"><FontAwesomeIcon icon="fa-solid fa-xmark" size="2x"/></div>
                        <div className="btn btn-sm btn-outline-secondary">{userName}</div>

                        <hr />

                        <div className="shelf-menu-items">
                            {/* LEARNERS */}
                            <PermissionGate requires={userPermissions.canViewLearners}>
                                <NavLink className="nav-link" to="/learners">Uczniowie</NavLink>
                            </PermissionGate>

                            {/* EVENTS */}
                            <PermissionGate requires={userPermissions.canViewEvents}>
                                <NavLink className="nav-link" to="/events">Zajęcia</NavLink>
                            </PermissionGate>

                            {/* STUDENT GROUPS */}
                            <PermissionGate requires={userPermissions.canViewStudentGroups}>
                                <NavLink className="nav-link" to="/student-groups">Grupy</NavLink>
                            </PermissionGate>

                            {/* MESSAGES */}
                            <PermissionGate requires={userPermissions.canReadMessages}>
                                <NavLink className="nav-link" to="/messages">Wiadomości</NavLink>
                            </PermissionGate>

                            {/* REPORTS */}
                            <PermissionGate requires={userPermissions.canReadReports}>
                                <NavLink className="nav-link" to="/reports">Raporty</NavLink>
                            </PermissionGate>

                            {/* ENROLMENTS */}
                            <PermissionGate requires={userPermissions.canReadEnrolments && userMetadata?.hasOrganisationProfile}>
                                <NavLink className="nav-link" to="/enrolments">Zapisy</NavLink>
                            </PermissionGate>

                            {/* SETTIGNS ITEM */}
                            <PermissionGate requires={userPermissions.canReadSettings}>
                                <NavLink className="nav-link" to="/settings">Ustawienia</NavLink>
                            </PermissionGate>
                        </div>

                        <hr />

                        {/* LOGOUT BUTTON */}
                        <UserLoginControl promptLoginOnRender='true'/>
                        
                        <p className="text-muted m-1">{UI_VERSION || 'v1.0.0'}</p>
                    </div>
                </div>
            </Toggler>

            {/* NAV BAR */}
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <FontAwesomeIcon icon="fa-solid fa-coins"/>
                </Link>

                {/* WHEN THIS BUTTON IS VISIBLE (ON SMALL SCREEN), CAN TOGGLE THE SHELF MENU */}
                <button className="navbar-toggler" type="btn" onClick={toggleShelfMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* THIS IS COLLAPSED ON SMALLER SCREENS - IN FAVOUR OF SHELF MENU */}
                <div className="collapse navbar-collapse" id="navbarToggler">
                    {/* NAV BAR MENU ITEMS */}
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-4">

                        {/* LEARNERS */}
                        <PermissionGate requires={userPermissions.canViewLearners}>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/learners">Uczniowie</NavLink>
                            </li>
                        </PermissionGate>

                        {/* EVENTS */}
                        <PermissionGate requires={userPermissions.canViewEvents}>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/events">Zajęcia</NavLink>
                            </li>
                        </PermissionGate>

                        {/* STUDENT GROUPS */}
                        <PermissionGate requires={userPermissions.canViewStudentGroups}>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/student-groups">Grupy</NavLink>
                            </li>
                        </PermissionGate>

                        {/* MESSAGES */}
                        <PermissionGate requires={userPermissions.canReadMessages}>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/messages">Wiadomości</NavLink>
                            </li>
                        </PermissionGate>

                        {/* REPORTS */}
                        <PermissionGate requires={userPermissions.canReadReports}>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/reports">Raporty</NavLink>
                            </li>
                        </PermissionGate>

                        {/* ENROLMENTS */}
                        <PermissionGate requires={userPermissions.canReadEnrolments && userMetadata?.hasOrganisationProfile}>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/enrolments">Zapisy</NavLink>
                            </li>
                        </PermissionGate>
                    </ul>

                    {/* HIDDEN BY DEFAULT, DROPDOWN MENU ITEMS */}
                    <div className="dropdown">
                        {/* BUTTON WITH USER NAME */}
                        <button className="btn btn-sm btn-outline-secondary dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                            {userName}
                        </button>

                        {/* DROPDOWN ITEMS */}
                        <ul className="dropdown-menu">
                            {/* SETTIGNS ITEM */}
                            <PermissionGate requires={userPermissions.canReadSettings}>
                                <li className="dropdown-item">
                                    <NavLink className="nav-link" to="/settings">Ustawienia</NavLink>
                                </li>

                                <div className="dropdown-divider"></div>
                            </PermissionGate>


                            {/* LOGOUT BUTTON */}
                            <li>
                                <div className="text-center">
                                    <UserLoginControl promptLoginOnRender='true'/>
                                    <p className="text-muted m-1">{UI_VERSION || 'v1.0.0'}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </>
}
