import {useCallback, useEffect, useState} from "react";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import Spinner from "../../components/common/Spinner";
import {ListEnd} from "../common/ListEnd";
import {formatPln} from "../../lib/utils";
import {Toggler} from "../common/Toggler";
import _ from 'lodash';

interface Props {
    pageTitle: string;
}

export const SubscriptionInvoicesDetails = ({pageTitle}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [bills, setBills] = useState(null);

    const fetchBills = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/bills')
                    .then(res => {
                        const data = res?.data?.bills || [];
                        const sorted = _.orderBy(data, ['issuedOn', 'description', 'status'], ['desc', 'asc', 'asc']);
                        setBills(sorted);
                    });
            });
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetchBills();
    }, [fetchBills]);

    const badge = (text, badgeClass) => {
        return <div className={`badge rounded-pill ${badgeClass}`}>{text}</div>
    }

    const statusBadge = (status) => {
        switch (status) {
            case 'PENDING_PAYMENT':
                return badge('oczekuje', 'text-bg-warning');
            case 'PAYMENT_NOT_REQUIRED':
                return badge('nie wymagane', 'text-bg-success');
            case 'PAID':
                return badge('zapłacono', 'text-bg-success');
            default:
                return badge('...', 'text-bg-danger');
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                {pageTitle}
            </div>

            {bills ? (<>
                    <Toggler condition={bills.length === 0}>
                        <ListEnd content={"Brak danych"}/>
                    </Toggler>

                    <Toggler condition={bills.length > 0}>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Opis</th>
                                <th scope="col">Kwota</th>
                                <th scope="col">Status</th>
                            </tr>
                            </thead>
                            <tbody className="table-group-divider">

                            {bills.map((bill, index) =>
                                <tr key={bill.description + index}>
                                    <td className="pt-2">{index + 1}</td>
                                    <td className="pt-2">{bill.description}</td>
                                    <td className="pt-2">{formatPln(bill.feeNet.value)}</td>
                                    <td className="pt-2">{statusBadge(bill.status)}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        
                        <ListEnd content={"Koniec danych"}/>
                    </Toggler>
                </>
            ) : (
                <div className="text-center">
                    <Spinner/>
                </div>
            )}
        </div>
    )
}
