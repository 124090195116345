import {apiClient} from "../../apiClientV1";
import {navigateToExternalUrl} from "../../lib/utils";
import {useAuth0} from "@auth0/auth0-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AccountPaymentStatus} from "./AccountPaymentStatus";
import {toast} from "react-toastify";

interface Props {
    pageTitle: string;
    user: any;
    refreshViewCallback: () => void;
}

export const PaymentSettings = ({pageTitle, user, refreshViewCallback}: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const createStripeConnectedAccount = () => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).post('/stripe/connected-accounts', {})
                    .then(() => refreshViewCallback())
                    .catch(() => toast.error('Wystąpił błąd podczas tworzenia profilu bankowego'));
            });
    }

    const completeOnboarding = () => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/stripe/account-link?returnTo=/settings')
                    .then(res => navigateToExternalUrl(res.data));
            });
    }

    return user?.permissions?.canCreateStripePayouts === true && user?.metadata && <>
        <div className="card">
            <div className="card-header">
                {pageTitle}
            </div>

            <div className="card-body">
                {!user?.metadata?.hasPaymentAccount ? <>
                        <div>
                            <p className="small">
                                Aby pobierać opłaty online (np. kartą, BLIKiem), potrzebny jest profil bankowy.
                            </p>
                            <button className="btn btn-sm btn-primary" onClick={createStripeConnectedAccount}>
                                Załóż profil bankowy
                            </button>
                        </div>
                    </>
                    :
                    <div>
                        <p className="small">Aby mieć możliwość wypłacania środków z Portalu,
                            musisz podać dane do swojego konta.</p>
                        <p className="small">Niektóre dane personalne (np. adres czy data urodzenia) też mogą być
                            wymagane
                            na potrzeby weryfikacji tożsamości.</p>

                        <AccountPaymentStatus/>

                        <button className="btn btn-sm btn-primary gap-2 m-2" onClick={completeOnboarding}>
                            <div className="d-flex">
                                <div className="m-1">Dodaj/Zmień</div>
                                <FontAwesomeIcon className="m-2" icon="fa-solid fa-arrow-up-right-from-square"/>
                            </div>
                        </button>
                    </div>}
            </div>
        </div>
    </>
}
