import * as React from "react";
import {Outlet} from "react-router-dom";
import './homeLayout.css';
import {CourseDiscoveryNavbar} from "../components/CourseDiscoveryNavbar";

export const OrganisationDiscoveryHomeLayout = () => {
    return <>
        <div className="layout-container">
            <CourseDiscoveryNavbar/>
            <div className="outlet">
                <Outlet/>
            </div>
        </div>
    </>
};
