import React from "react";
import {Link} from "react-router-dom";

export const PaymentFailedView = () => {
    return <>
        <div className="container text-center">
            <div className="row justify-content-center m-5">
                <div className="col-12 col-sm-10 col-md-8 col-lg-8 fw-bolder fs-5">
                    <div className="alert alert-warning" role="alert">
                        Płatność została anulowana, przerwana bądź zakończyła się niepowodzeniem.
                    </div>
                    <Link className="btn btn-primary" to="/">Wróć do strony głównej</Link>
                </div>
            </div>
        </div>
    </>
}
