import React, { useEffect, useState } from 'react';
import { apiClientPublic } from '../../../apiClientV1';
import { useParams } from 'react-router';
import './organisationProfileView.css';
import './../../../common.css';
import { LearnerEnrolmentCard } from '../../../components/enrolments/publicEnrolments/LearnerEnrolmentCard';
import Spinner from '../../../components/common/Spinner';
import { ListEnd } from '../../../components/common/ListEnd';
import _ from 'lodash';
import { Toggler } from '../../../components/common/Toggler';
import Markdown from 'react-markdown';
import { Tile } from '../../../components/common/Tile';

const logoutUrl = process.env.REACT_APP_LOGOUT_URL;

export const OrganisationProfileView = () => {
    const { publicIdentifier } = useParams();

    const [profile, setProfile] = useState();
    const [enrolments, setEnrolments] = useState();
    const [notFoundError, setNotFoundError] = useState();

    useEffect(() => {
        apiClientPublic()
            .get(`/organisations/${publicIdentifier}`)
            .then((result) => {
                if (!result?.data) {
                    setNotFoundError({ id: publicIdentifier });
                } else {
                    setProfile(result.data);
                }
            })
            .catch(() => {
                // 404 = NOT FOUND
                // if (error.response.status === 404)
                setNotFoundError({ id: publicIdentifier });
            });

        apiClientPublic()
            .get(`/organisations/${publicIdentifier}/enrolments`)
            .then((result) => {
                setEnrolments(result.data?.enrolments);
            });
    }, [publicIdentifier]);

    return profile ? (
        <div className="d-flex flex-column mb-4">
            <h1 className="organisation-profile-name p-3">{profile.profileName}</h1>
            <div className="d-flex flex-row">
                <img className="img-fluid img-round-200 p-3" src={profile.logoUrl} alt={profile.profileName} />

                <div className="p-3">
                    <div className="fs-5">
                        <div className="display-linebreak">
                            <Markdown>{profile.description}</Markdown>
                        </div>
                    </div>
                </div>
            </div>

            <Toggler condition={enrolments?.length > 0}>
                <h2 className="mt-5 mb-5">Aktywne zapisy na zajęcia</h2>

                <div className="d-flex flex-column gap-4">
                    {_.orderBy(enrolments, ['locationName', 'enrolmentName'], ['asc', 'asc']).map((e, index) => (
                        <div key={index + '-' + e.id}>
                            <LearnerEnrolmentCard orgId={publicIdentifier} enrolment={e} />
                        </div>
                    ))}
                </div>

                <ListEnd content="koniec danych" />
            </Toggler>

            <Toggler condition={enrolments?.length === 0}>
                <div className="d-flex justify-content-center">
                    <Tile text="Brak aktywnych zapisów. Prosimy sprawdzić później." />
                </div>
            </Toggler>
        </div>
    ) : notFoundError ? (
        <div className="d-flex flex-column align-items-center m-5">
            <div className="fs-2">Nie znaleziono profilu o takim identyfikatorze</div>
            <div className="fs-5 text-muted mt-3">{notFoundError.id}</div>

            <a href={logoutUrl} className="mt-5">
                Wróć do strony głównej
            </a>
        </div>
    ) : (
        <div className="text-center m-5">
            <Spinner />
        </div>
    );
};
