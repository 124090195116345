import React, {useEffect, useState} from "react";
import {apiClient} from "../../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {formatPln} from "../../../lib/utils";

interface Props {
    user: any;
    event: any;
    eventRegisterSummary: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const WithdrawMoney = ({user, event, eventRegisterSummary, cancelCallback, refreshViewCallback}: Props) => {
    const {getAccessTokenWithPopup, getAccessTokenSilently} = useAuth0();

    const [instructor, setInstructor] = useState(null);

    const handlePayout = (eventId) => {
        getAccessTokenWithPopup()
            .then(accessToken => {
                apiClient(accessToken).post('/stripe/payouts', {eventId: eventId})
                    .then(() => refreshViewCallback());
            });
    }

    useEffect(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get(event._links?.instructor?.href)
                    .then(res => setInstructor(res.data));
            });
    }, [event._links?.instructor?.href, getAccessTokenSilently]);

    return user && event && eventRegisterSummary && instructor ? <>
            <div className="container text-center needs-validation">
                <div className="row justify-content-center m-5">
                    <div className="col-9 col-sm-6 col-md-4 col-lg-3 fw-bolder fs-5">Wypłata środków</div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-9 col-sm-6 col-md-5">
                        <div className="fs-2 fw-bold">{formatPln(eventRegisterSummary.payoutAmount)}</div>
                        <div className="mb-3">Na konto użytkownika</div>
                        <div className="fs-2 fw-bold mb-3">{instructor.displayName}</div>
                    </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                    {/************ CANCEL ************/}
                    <button type="button" className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}>Anuluj
                    </button>

                    {/************ PAYOUT FROM THE EVENT ************/}
                    <button type="button" className="form-floating btn btn-primary m-2"
                            onClick={() => handlePayout(event.id)}>Dokonaj Przelewu
                    </button>
                </div>
            </div>
        </>
        :
        <div className="container text-center placeholder-glow m-5" aria-hidden="true">
            {/*LABEL*/}
            <div className="placeholder-glow">
                <span className="placeholder col-2"></span>
            </div>
            {/*ROWS*/}
            <div className="placeholder-glow mt-5">
                <div className="placeholder col-5"/>
            </div>
            <div className="placeholder-glow">
                <div className="placeholder col-5"/>
            </div>

            {/*BUTTONS*/}
            <div className="placeholder-glow mt-5">
                <span className="btn btn-secondary disabled placeholder col-1 m-1"></span>
                <span className="btn btn-primary disabled placeholder col-1 m-1"></span>
            </div>
        </div>
}
