import * as React from 'react';

interface Props {
    condition: boolean;
    children: any;
}

export const Toggler = ({ children, condition }: Props) => {
    return condition === true && <>{children}</>;
};
