import React from "react";
import _ from 'lodash';
import {Toggler} from "../common/Toggler";
import './../../common.css';
import dayjs from "dayjs";
import {formatPln, incomeType} from "../../lib/utils";

interface Props {
    incomeData: any;
}

export const IncomeTable = ({incomeData}: Props) => {
    const sort = (paymentRecords) => {
        return _.orderBy(paymentRecords, ['paymentDate'], ['desc']);
    }

    const formatDate = (dateStr) => {
        return dayjs(dateStr).format("YYYY-MM-DD HH:mm");
    }

    return incomeData ? <>
            <Toggler condition={incomeData.incomeHistory.length !== 0}>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Data</th>
                        <th scope="col">Imię i nazwisko</th>
                        <th scope="col">Data zajęć</th>
                        <th scope="col">Kwota</th>
                        <th scope="col">Kwota (net)</th>
                        <th scope="col">Typ</th>
                    </tr>
                    </thead>
                    <tbody className="table-group-divider">

                    {sort(incomeData.incomeHistory).map((row, index) =>
                        <tr key={row.studentName + index}>
                            <td className="pt-2">{index + 1}</td>
                            <td className="pt-2">{formatDate(row.paymentDate)}</td>
                            <td className="pt-2">{row.studentName}</td>
                            <td className="pt-2">{formatDate(row.eventDate)}</td>
                            <td className="pt-2">{formatPln(row.income.value)}</td>
                            <td className="pt-2">{formatPln(row.incomeNet.value)}</td>
                            <td className="pt-2">{incomeType(row.incomeType)}</td>
                        </tr>
                    )}
                    </tbody>
                </table>

                {/* *********************** PAGE NAVIGATION *********************** */}
                {/*<div className="d-flex flex-row justify-content-center align-items-baseline gap-3">*/}
                {/*    <Toggler condition={hasPreviousPage}>*/}
                {/*        <button className="btn btn-outline-secondary" onClick={fetchPreviousPageCallback}>*/}
                {/*            <FontAwesomeIcon icon="fa-solid fa-chevron-left"/>*/}
                {/*        </button>*/}
                {/*    </Toggler>*/}

                {/*    <div className="circle">*/}
                {/*        <div className="circle-text">{currentPageNumber + 1}</div>*/}
                {/*    </div>*/}

                {/*    <Toggler condition={hasNextPage}>*/}
                {/*        <button className="btn btn-outline-secondary" onClick={fetchNextPageCallback}>*/}
                {/*            <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>*/}
                {/*        </button>*/}
                {/*    </Toggler>*/}
                {/*</div>*/}
                {/* *********************** PAGE NAVIGATION *********************** */}

                <div className="small text-muted m-4 text-center">koniec danych</div>
            </Toggler>

            <Toggler condition={incomeData.incomeHistory.length === 0}>
                <div className="parent">
                    <div className="display-6 m-3">Brak danych</div>
                </div>
            </Toggler>
        </>
        :
        //************ PLACEHOLDER ************
        <div className="placeholder-glow p-3 text-center w-100" aria-hidden="true">
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
        </div>
}
