import React, { useEffect, useState } from 'react';
import { apiClient } from '../../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import { Toggler } from '../common/Toggler';
import Spinner from '../common/Spinner';
import { SPToastContainer } from '../common/SPToastContainer';
import { toast } from 'react-toastify';
import { InputSelectDropdown } from '../common/InputSelectDropdown';

interface Props {
    student: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

const STUDENT_GROUP_SELECT_PLACEHOLDER = 'Wybierz grupę';

const initialValues = (student) => {
    return {
        id: student.id,
        firstName: student.firstName || '',
        lastName: student.lastName || '',
        phoneNumber: student.phoneNumber || '',
        selectedStudentGroup: {},
    };
};

export const EditStudent = ({ student, cancelCallback, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [pageState, setPageState] = useState('VIEW');
    const [editedStudent, setEditedStudent] = useState(initialValues(student));
    const [errors, setErrors] = useState({});
    const [studentGroups, setStudentGroups] = useState();

    useEffect(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get('/student-groups')
                .then((res) => setStudentGroups(res?.data));
        });
    }, [getAccessTokenSilently]);

    const handleSubmit = (student) => {
        const result = validateInput(student);
        setErrors(result.errors);

        if (result.isValid) {
            setPageState('LOADING');

            getAccessTokenSilently().then((accessToken) => {
                const payload = {
                    action: 'UPDATE_STUDENT',
                    studentFirstName: student.firstName.trim(),
                    studentLastName: student.lastName.trim(),
                    studentPhoneNumber: student.phoneNumber,
                };

                apiClient(accessToken)
                    .patch(`/students/${student.id}`, payload)
                    .then(() => {
                        setEditedStudent(null);
                        refreshViewCallback();
                    });
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setEditedStudent({
            ...editedStudent,
            [name]: value,
        });
    };

    const inputField = (name, placeholder, val, type) => {
        return (
            <div className="form-floating mb-3">
                <input
                    type={type}
                    className={`form-control ${errors[name] && 'is-invalid'}`}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={val}
                    onChange={handleInputChange}
                />
                <label htmlFor="floatingInput">{placeholder}</label>
            </div>
        );
    };

    function handleAddStudentToGroup(student) {
        getAccessTokenSilently().then((accessToken) => {
            const addStudentsToStudentGroupRequest = {
                action: 'ADD_STUDENTS_TO_STUDENT_GROUP',
                studentIds: [student.id],
            };

            const studentGroup = student.selectedStudentGroup;

            apiClient(accessToken)
                .patch(`/student-groups/${studentGroup.id}`, addStudentsToStudentGroupRequest)
                .then(() => toast.success('Uczeń dodany do grupy: ' + studentGroup.displayName))
                .catch(() => toast.error('Nie udało się dodać ucznia do grupy!'));
        });
    }

    return (
        editedStudent && (
            <>
                <SPToastContainer />

                <div className="container text-center mt-5">
                    <div className="row justify-content-center">
                        <div className="col-9 col-sm-6 col-md-4">
                            <Toggler condition={pageState === 'LOADING' || !studentGroups}>
                                <Spinner />
                            </Toggler>

                            <Toggler condition={!!studentGroups}>
                                <div className="d-flex gap-3 mb-3">
                                    <InputSelectDropdown
                                        optionValues={studentGroups}
                                        optionKeyFn={(sg) => sg.id}
                                        optionLabelFn={(sg) => sg.displayName}
                                        inputName="selectedStudentGroup"
                                        inputHandler={handleInputChange}
                                        inputData={editedStudent}
                                        placeholder={STUDENT_GROUP_SELECT_PLACEHOLDER}
                                        errors={errors}
                                    />

                                    <button
                                        className="btn btn-primary"
                                        disabled={JSON.stringify(editedStudent.selectedStudentGroup) === '{}'}
                                        onClick={() => handleAddStudentToGroup(editedStudent)}
                                    >
                                        Dodaj
                                    </button>
                                </div>
                            </Toggler>

                            <Toggler condition={pageState === 'VIEW'}>
                                {inputField('firstName', 'imię ucznia', editedStudent.firstName, 'text')}
                                {inputField('lastName', 'nazwisko ucznia', editedStudent.lastName, 'text')}
                                <small className="text-muted">Proszę uwzględnić nr kierunkowy, np. +48XXXyyyZZZ</small>
                                {inputField('phoneNumber', 'numer telefonu', editedStudent.phoneNumber, 'tel')}

                                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                                    {/************ CANCEL ADDING NEW STUDENT ************/}
                                    <button
                                        type="button"
                                        className="form-floating btn btn-outline-secondary m-2"
                                        onClick={cancelCallback}
                                    >
                                        Anuluj
                                    </button>

                                    {/************ ADD NEW STUDENT ************/}
                                    <button
                                        type="button"
                                        className="form-floating btn btn-primary m-2"
                                        onClick={() => handleSubmit(editedStudent)}
                                    >
                                        Potwierdź
                                    </button>
                                </div>
                            </Toggler>
                        </div>
                    </div>
                </div>
            </>
        )
    );
};

const validateInput = (input) => {
    let validationResult = { isValid: true, errors: {} };

    if (input.firstName.length < 2) {
        validationResult.errors.firstName = true;
        validationResult.isValid = false;
    }

    if (input.lastName.length < 2) {
        validationResult.errors.lastName = true;
        validationResult.isValid = false;
    }

    if (
        input.phoneNumber.trim().length > 0 &&
        (input.phoneNumber.trim().length < 12 ||
            input.phoneNumber.trim().length > 13 ||
            input.phoneNumber.trim().charAt(0) !== '+')
    ) {
        validationResult.errors.phoneNumber = true;
        validationResult.isValid = false;
    }

    return validationResult;
};
