import React from 'react';

interface Props {
    title: string;
    loadDataCallback: () => void;
}

export const EventCardNavigator = ({ title, loadDataCallback }: Props) => {
    return (
        <>
            <button className="btn btn-sm btn-outline-dark" onClick={loadDataCallback}>
                <div className="card-body text-center fs-6">{title}</div>
            </button>
        </>
    );
};
