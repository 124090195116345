import React from "react";
import _ from 'lodash';
import {EnrolmentCard} from "./EnrolmentCard";
import {ListEnd} from "../common/ListEnd";
import {Toggler} from "../common/Toggler";

interface Props {
    enrolments: any;
    refreshViewCallback: () => void;
}

export const EnrolmentCards = ({enrolments, refreshViewCallback}: Props) => {
    function filteredEnrolments(enrolmentsData) {
        return _.orderBy(enrolmentsData, ['enrolmentName'], ['asc'])
            .filter(e => e.status !== 'ARCHIVED');
    }

    return enrolments?.length !== 0 ?
        <>
            {filteredEnrolments(enrolments).map((e, index) =>
                <div className="m-3" key={index + '-' + e?.id}>
                    {/*ENROLMENT CARD*/}
                    <EnrolmentCard enrolment={e}
                                   refreshViewCallback={refreshViewCallback}/>

                    {/* SEPARATOR */}
                    <Toggler condition={index < enrolments.length - 1}>
                        <div className="text-center">::</div>
                    </Toggler>
                </div>
            )}

            <ListEnd content="koniec danych"/>
        </>
        :
        <div className="parent">
            <div className="child text-center display-6">Brak danych</div>
        </div>
}
