import React from 'react';
import { Href } from '../../common/Href';
import { Toggler } from '../../common/Toggler';

interface Props {
    consent: any;
    removeConsentCallback: (c) => void;
}

export const ConsentBox = ({ consent, removeConsentCallback }: Props) => {
    function badge(mandatory: boolean) {
        return (
            <div className={`badge rounded-pill ${mandatory ? 'text-bg-danger' : 'text-bg-success'}`}>
                {mandatory
                    ? 'akceptacja przez rodzica/opiekuna jest wymagana'
                    : 'akceptacja przez rodzica/opiekuna jest opcjonalna'}
            </div>
        );
    }

    return (
        <div className="card bg-light">
            <div className="card-header">
                <div className="d-flex justify-content-between">
                    <div>{badge(consent.isMandatory)}</div>

                    <Toggler condition={consent.id > 0}>
                        <button className="btn btn-sm btn-warning" onClick={() => removeConsentCallback(consent)}>
                            Usuń
                        </button>
                    </Toggler>
                </div>
            </div>
            <div className="card-body align-content-start">
                <div>{consent.title}</div>

                <Toggler condition={!!consent.documentUrl}>
                    <Href link={consent.documentUrl} openNewTab={true} />
                </Toggler>
            </div>
        </div>
    );
};
