import React, { useState } from 'react';
import './collectContactDetailsAndRegister.css';
import { apiClientPublic } from '../../../apiClientV1';
import { Email } from '../../common/Email';
import dayjs from 'dayjs';
import { formatDateOnly, formatPln } from '../../../lib/utils';
import { Toggler } from '../../common/Toggler';
import { Tile } from '../../common/Tile';
import Markdown from 'react-markdown';

interface Props {
    enrolment: any;
    cancelCallback: () => void;
}

const initialValues = (enrolment) => {
    return {
        enrolmentId: enrolment.id,
        studentFirstName: '',
        studentLastName: '',
        studentDateOfBirth: '',
        studentSchoolLocation: '',
        guardianFirstName: '',
        guardianLastName: '',
        guardianPhoneNumber: '+48',
        guardianEmail: '',
        consentList: [...enrolment.consentList],
    };
};

export const CollectContactDetailsAndRegister = ({ enrolment, cancelCallback }: Props) => {
    const [pageStatus, setPageStatus] = useState('COLLECT-INPUT');
    const [inputValues, setInputValues] = useState(initialValues(enrolment));
    const [errors, setErrors] = useState({});
    const [hasErrors, setHasErrors] = useState();

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };

    const handleSubmit = (data) => {
        const result = validateInput(data);
        setErrors(result.errors);
        setHasErrors(!result.isValid);

        if (result.isValid) {
            const payload = {
                ...data,
                studentDateOfBirth: dayjs(data.studentDateOfBirth, 'YYYY-MM-DDTHH:mm'),
            };

            apiClientPublic()
                .post('/enrolment-items', payload)
                .then(() => setPageStatus('REGISTRATION-SUBMITTED'))
                .catch(() => setPageStatus('REGISTRATION-SUBMITION-FAILED'));
        }
    };

    const inputField = (name, placeholder, val, type) => {
        return (
            <div className="form-floating mb-3">
                <input
                    type={type}
                    className={`form-control ${errors[name] && 'is-invalid'}`}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={val}
                    onChange={handleInputChange}
                />
                <label htmlFor="floatingInput">{placeholder}</label>
            </div>
        );
    };

    const consentSwitchField = (index, consent) => {
        const handleConsentChanged = (index, flag) => {
            inputValues.consentList[index].isAgreed = flag;
            setInputValues({ ...inputValues });
        };

        const consentName = 'consent-' + index;

        return (
            <div className="form-check form-switch">
                <input
                    className={`form-check-input ${errors[consentName] && 'is-invalid'}`}
                    type="checkbox"
                    role="switch"
                    id={'flexSwitchCheckDefault' + index}
                    onChange={() => handleConsentChanged(index, !inputValues.consentList[index].isAgreed)}
                    checked={inputValues.consentList[index].isAgreed}
                />
                <label className="form-check-label" htmlFor={'flexSwitchCheckDefault' + index}>
                    <span>
                        {consent.isMandatory && '*'} {consent.title}
                        {consent.documentUrl && (
                            <a href={consent.documentUrl} target="_blank" rel="noreferrer">
                                [otwórz]
                            </a>
                        )}
                    </span>
                </label>
            </div>
        );
    };

    function showSeparatorIfRequired(enrolment, separator) {
        return enrolment.locationName && enrolment.buildingName ? separator : '';
    }

    return (
        enrolment && (
            <>
                <div>
                    <h1 className="mt-5 mb-4">Formularz rejestracyjny</h1>

                    <hr />

                    <div className="organisation-profile">
                        <div className="fs-3">{enrolment.enrolmentName}</div>

                        <div className="card-subtitle mb-5 text-body-secondary fs-4">
                            {enrolment.locationName} {showSeparatorIfRequired(enrolment, '/')} {enrolment.buildingName}
                        </div>
                    </div>

                    <Toggler
                        condition={
                            !!enrolment.buildingName ||
                            !!enrolment.experienceLevel ||
                            !!enrolment.capacity ||
                            !!enrolment?.fee?.value
                        }
                    >
                        <div className="d-flex justify-content-center gap-2 flex-wrap">
                            <Toggler condition={!!enrolment.buildingName}>
                                <Tile caption="miejsce" text={enrolment.buildingName} />
                            </Toggler>

                            <Toggler condition={!!enrolment.experienceLevel}>
                                <Tile caption="poziom" text={enrolment.experienceLevel} />
                            </Toggler>

                            <Toggler condition={!!enrolment.capacity}>
                                <Tile caption="wielkość grupy" text={enrolment.capacity} />
                            </Toggler>

                            <Toggler condition={!!enrolment?.fee?.value}>
                                <Tile caption="cena" text={formatPln(enrolment?.fee?.value)} />
                            </Toggler>
                        </div>
                    </Toggler>

                    <hr />

                    <div className="m-4">
                        <div className="small text-muted mb-3">Informacje/opis</div>
                        <div className="display-linebreak">
                            <Markdown>{enrolment.description}</Markdown>
                        </div>
                    </div>

                    <hr />

                    {/*DATES*/}
                    <Toggler condition={!!enrolment.termStartsOn || !!enrolment.termEndsOn}>
                        <div className="m-4">
                            <div className="small text-muted mb-3">Terminy</div>

                            <Toggler condition={!!enrolment.termStartsOn}>
                                <span className="d-flex gap-2">
                                    <div>Rozpoczęcie zajęć:</div>
                                    <b>{formatDateOnly(enrolment.termStartsOn)}</b>
                                </span>
                            </Toggler>

                            <Toggler condition={!!enrolment.termEndsOn}>
                                <span className="d-flex gap-2">
                                    <div>Zakończenie zajęć:</div>
                                    <b>{formatDateOnly(enrolment.termEndsOn)}</b>
                                </span>
                            </Toggler>
                        </div>

                        <hr />
                    </Toggler>

                    {/************ COLLECT INPUT ************/}
                    {pageStatus === 'COLLECT-INPUT' && (
                        <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                            {/*ERROR MESSAGE - IF ANY*/}
                            {hasErrors && (
                                <div className="alert alert-danger" role="alert">
                                    Proszę podać wymagane informacje
                                </div>
                            )}

                            {/*<div className="text-muted mt-5 mb-3 fw-bold">Informacje o uczniu/uczestniku zajęć:</div>*/}

                            <div className="card bg-primary-subtle mt-5">
                                <div className="card-body">
                                    <div className="card-title fs-5 mb-3 fw-bold">
                                        Informacje o uczniu/uczestniku zajęć:
                                    </div>

                                    {inputField(
                                        'studentFirstName',
                                        'imię uczestnika *',
                                        inputValues.studentFirstName,
                                        'text',
                                    )}
                                    {inputField(
                                        'studentLastName',
                                        'nazwisko uczestnika *',
                                        inputValues.studentLastName,
                                        'text',
                                    )}
                                    {inputField(
                                        'studentDateOfBirth',
                                        'data ur. uczestnika *',
                                        inputValues.studentDateOfBirth,
                                        'date',
                                    )}
                                    {inputField(
                                        'studentSchoolLocation',
                                        'miejscowość zam. *',
                                        inputValues.studentSchoolLocation,
                                        'text',
                                    )}
                                </div>
                            </div>

                            <div className="card bg-primary-subtle mt-5 mb-3">
                                <div className="card-body">
                                    <div className="card-title fs-5 mb-3 fw-bold">Informacje o rodzicu/opiekunie:</div>

                                    {inputField('guardianFirstName', 'imię *', inputValues.guardianFirstName, 'text')}
                                    {inputField('guardianLastName', 'nazwisko *', inputValues.guardianLastName, 'text')}
                                    <small className="text-muted">
                                        Proszę uwzględnić nr kierunkowy, np. +48XXXyyyZZZ
                                    </small>
                                    {inputField(
                                        'guardianPhoneNumber',
                                        'numer telefonu komórkowego *',
                                        inputValues.guardianPhoneNumber,
                                        'tel',
                                    )}
                                    {inputField('guardianEmail', 'e-mail', inputValues.guardianEmail, 'email')}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-4 mt-5">
                                {inputValues.consentList?.length > 0 && (
                                    <>
                                        {inputValues.consentList.map((c, index) => (
                                            <div key={index + '-' + c.title}>
                                                {consentSwitchField(index, inputValues.consentList[index])}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>

                            <p className="text-muted fs-6 mt-3 mb-3">* Pola oznaczone gwiazdką są wymagane</p>

                            <div className="d-flex gap-2 mt-5 mb-3 justify-content-between">
                                <button className="btn btn-outline-secondary" onClick={cancelCallback}>
                                    Anuluj
                                </button>
                                <button className="btn btn-primary" onClick={() => handleSubmit(inputValues)}>
                                    Zapisz
                                </button>
                            </div>
                        </div>
                    )}

                    {/*<pre>{JSON.stringify(enrolment, null, 2)}</pre>*/}

                    {/************ REGISTRATION SUBMITTED ************/}
                    {pageStatus === 'REGISTRATION-SUBMITTED' && (
                        <div className="d-flex flex-column align-items-center">
                            <div className="alert alert-success" role="alert">
                                <div className="alert-heading fs-4 fw-bold">Świetnie!</div>
                                <p>Przyjęliśmy Twoje zgłoszenie do rejestracji.</p>
                                <hr />

                                <p className="mb-0 fs-4">{enrolment.enrolmentName}</p>
                                <p className="mb-0 fs-5">
                                    {enrolment.locationName} {enrolment.buildingName && ' / ' + enrolment.buildingName}
                                </p>
                                <hr />

                                <p className="mb-0 fw-light">
                                    Instruktor prowadzący zajęcia skontaktuje się z Tobą w niedługim czasie.
                                </p>
                            </div>

                            <div className="alert alert-primary text-center" role="alert">
                                <p className="mb-0">
                                    <span className="fw-bold">Co dalej?</span> Jeśli nie masz jeszcze konta na
                                    serwisie{' '}
                                    <span className="fw-bold">Szkolne Płatności</span>,
                                </p>
                                <p className="mb-0">
                                    możesz się zarejestrować powyżej, aby mieć dostęp do rozkładu zajęć,
                                </p>
                                <p className="mb-0">płatności online oraz monitorowania historycznych transakcji.</p>
                            </div>

                            <div>
                                {/*<button className="btn btn-outline-secondary" onClick={cancelCallback}>*/}
                                {/*    Wróć*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    )}

                    {/************ REGISTRATION FAILED ************/}
                    {pageStatus === 'REGISTRATION-SUBMITION-FAILED' && (
                        <div className="d-flex flex-column align-items-center">
                            <div className="alert alert-warning" role="alert">
                                <div className="alert-heading fs-5 fw-bold">Wystąpił błąd podczas zapisu.</div>
                                Sprawdź poprawność danych. Jeśli problem się powtarza, prosimy o kontakt <Email />
                            </div>
                            <div>
                                <button className="btn btn-primary" onClick={() => setPageStatus('COLLECT-INPUT')}>
                                    Ponów
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </>
        )
    );
};

const validateInput = (input) => {
    let validationResult = { isValid: true, errors: {} };

    if (input.studentFirstName.length < 2) {
        validationResult.errors.studentFirstName = true;
        validationResult.isValid = false;
    }

    if (input.studentLastName.length < 2) {
        validationResult.errors.studentLastName = true;
        validationResult.isValid = false;
    }

    if (!input.studentDateOfBirth) {
        validationResult.errors.studentDateOfBirth = true;
        validationResult.isValid = false;
    }

    if (input.studentSchoolLocation.length < 2) {
        validationResult.errors.studentSchoolLocation = true;
        validationResult.isValid = false;
    }

    if (input.guardianFirstName.length < 2) {
        validationResult.errors.guardianFirstName = true;
        validationResult.isValid = false;
    }

    if (input.guardianLastName < 2) {
        validationResult.errors.guardianLastName = true;
        validationResult.isValid = false;
    }

    if (
        input.guardianPhoneNumber.trim().length < 12 ||
        input.guardianPhoneNumber.trim().length > 13 ||
        input.guardianPhoneNumber.trim().charAt(0) !== '+'
    ) {
        validationResult.errors.guardianPhoneNumber = true;
        validationResult.isValid = false;
    }

    if (input.guardianEmail !== '' && (input.guardianEmail < 3 || !input.guardianEmail.includes('@'))) {
        validationResult.errors.guardianEmail = true;
        validationResult.isValid = false;
    }

    input.consentList.forEach((c, index) => {
        const consentName = 'consent-' + index;
        const isError = c.isMandatory && !c.isAgreed;

        if (isError) {
            validationResult.errors[consentName] = isError;
            validationResult.isValid = false;
        }
    });

    return validationResult;
};
