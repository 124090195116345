import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

interface Props {
    data: any;
    selectStudentGroupForEditCallback: () => void;
    selectStudentGroupToDeleteCallback: () => void;
}

export const StudentGroupsTable = ({
    data: studentGroupData,
    selectStudentGroupForEditCallback,
    selectStudentGroupToDeleteCallback,
}: Props) => {
    return studentGroupData && studentGroupData.length !== 0 ? (
        <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Rok</th>
                        <th scope="col">Grupa</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {_.orderBy(studentGroupData, ['term', 'displayName'], ['desc', 'asc']).map((row, index) => (
                        <tr key={row.id + row.term + row.displayName}>
                            <td className="pt-2">{index + 1}</td>
                            <td className="pt-2">{row.term}</td>
                            <td className="pt-2">{row.displayName}</td>
                            <td className="pt-2">
                                <div className="d-flex gap-2 fa-pull-right">
                                    <button
                                        className="btn btn-sm btn-outline-danger"
                                        onClick={() => selectStudentGroupToDeleteCallback(row)}
                                    >
                                        Usuń
                                    </button>
                                    <button
                                        className="btn btn-sm btn-outline-secondary"
                                        onClick={() => selectStudentGroupForEditCallback(row)}
                                    >
                                        Edytuj
                                    </button>
                                    <Link to={`/student-groups/${row.id}`} className="btn btn-sm btn-outline-primary">
                                        Zarządzaj
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    ) : (
        <div className="parent">
            <div className="child text-center display-6">Brak danych</div>
        </div>
    );
};
