import {useState} from "react";
import {AddUserDetails} from "../../components/onboarding/AddUserDetails";
import {SelectUserType} from "../../components/onboarding/SelectUserType";
import {AddInstructorDetails} from "../../components/onboarding/AddInstructorDetails";
import {useAuth0} from "@auth0/auth0-react";
import {refreshPage} from "../../lib/utils";
import {apiClient} from "../../apiClientV1";
import {SPToastContainer} from "../../components/common/SPToastContainer";
import {toast} from "react-toastify";

export const UserOnboardingView = () => {
    const {getAccessTokenSilently} = useAuth0();

    const [pageState, setPageState] = useState('SELECT-USER-TYPE');
    const [collectedData, setCollectedData] = useState({});

    // MAINTAIN THE USER COLLECTED DATA
    function updateCollectedData(data) {
        setCollectedData({...collectedData, ...data});
    }

    // SELECT USER TYPE AND PROCEED TO THE NEXT VIEW
    function selectUserType(userType) {
        updateCollectedData({userType: userType});

        if (userType === 'ADMIN_INSTRUCTOR') {
            setPageState('ADD-INSTRUCTOR-DETAILS');
        } else {
            setPageState('ADD-USER-DETAILS');
        }
    }

    // CALLED ONCE INSTRUCTOR ENTERED THEIR ADDITIONAL DETAILS
    function collectUserDetails(instructorData) {
        updateCollectedData(instructorData);
        setPageState('ADD-USER-DETAILS');
    }

    // CANCELL - COMES TO THE FIRST SCREEN AND RESETS DATA
    function cancelCallback() {
        setCollectedData({});
        setPageState('SELECT-USER-TYPE');
    }

    // THIS ACTUALLY CALL BACKEND WITH DATA TO CREATE A USER
    function completeUserRegistration(requestData) {
        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    ...requestData,
                    ...collectedData
                };

                apiClient(accessToken).post('/users', payload)
                    .then(() => {
                        // NB. THIS SHOULD BE USED CAREFULLY, ONLY IN SPECIAL CIRCUMSTANCES
                        // It is OK to use in this case as we want to refresh the entire page
                        // to reflect the user that has just registered
                        refreshPage();
                    })
                    .catch(() => toast.error('Wystąpił błąd podczas rejestracji. Sprawdź poprawność danych'));
            });
    }

    return <>
        <SPToastContainer />

        {pageState === 'SELECT-USER-TYPE' && <>
            <SelectUserType selectUserTypeCallback={selectUserType} />
        </>}

        {pageState === 'ADD-INSTRUCTOR-DETAILS' && <>
            <AddInstructorDetails cancelCallback={cancelCallback} proceedCallback={collectUserDetails} />
        </>}

        {pageState === 'ADD-USER-DETAILS' && <>
            <AddUserDetails cancelCallback={cancelCallback} proceedCallback={completeUserRegistration} />
        </>}

    </>
}
