import React from "react";

export const EventDraftWarningCard = () => {
    return <div className="card border-warning">
            <div className="card-body text-bg-warning">
                <div className="card-title fs-5 fw-bolder">Wersja Robocza</div>
                <div className="card-text">To znaczy, że tylko Ty widzisz te dane. Dopiero w momencie potwierdzenia zajęć,</div>
                <div className="card-text">rodzic/opiekun będzie mógł zobaczyć owe zajęcia (np. w celu zapłaty)</div>
            </div>
        </div>
}