import React from "react";
import {Email} from "../common/Email";

interface Props {
    refreshViewCallback: () => void;
}

export const EnrolmentCreatorWizardError = ({refreshViewCallback}: Props) => {
    return <>
        <div className="card border-danger mt-4">
            <div className="card-body text-danger">
                <h5 className="card-title">Błąd w tworzeniu zapisów!</h5>
                <p className="card-text">
                    Jeśli problem się powtarza, skontaktuj się z nami.
                </p>
                <Email/>
            </div>
            <div className="card-footer">
                <button className="btn btn-primary w-100" onClick={refreshViewCallback}>Wróć</button>
            </div>
        </div>
    </>
}