import React from 'react';

interface Props {
    optionValues: any;
    optionKeyFn: (o) => string;
    optionLabelFn: (o) => string;
    inputName: string;
    inputHandler: (e) => void;
    inputData: any;
    placeholder: string;
    errors: any;
}

export const InputSelectDropdown = ({
    optionValues,
    optionKeyFn,
    optionLabelFn,
    inputName,
    inputHandler,
    inputData,
    placeholder,
    errors,
}: Props) => {
    function onOptionChange(e) {
        const jsonStr = e.target.value === '' ? '{}' : e.target.value;

        const event = {
            ...e,
            target: {
                name: e.target.name,
                value: JSON.parse(jsonStr),
            },
        };

        inputHandler(event);
    }

    return (
        <div className="form-floating w-100">
            <select
                className={'form-select ' + (errors[inputName] && 'is-invalid')}
                id={`input-select-for-${inputName}`}
                name={inputName}
                value={JSON.stringify(inputData[inputName])}
                onChange={onOptionChange}
            >
                <option value="">{placeholder}</option>
                {optionValues?.map((option) => (
                    <option key={optionKeyFn(option)} value={JSON.stringify(option)}>
                        {optionLabelFn(option)}
                    </option>
                ))}
            </select>

            <label htmlFor={`input-text-for-${inputName}`}>{placeholder}</label>
        </div>
    );
};
