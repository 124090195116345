import React from "react";
import {messageStatusToClass, messageStatusToDisplayText} from "../../lib/utils";

interface Props {
    status: any;
}

export const MessageStatusBadge = ({status}: Props) => {
    return <div className={`badge rounded-pill ${messageStatusToClass(status)}`}>
        {messageStatusToDisplayText(status)}
    </div>
}