import React, { useState } from 'react';
import { apiClient } from '../../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import './addStudentGroup.css';
import { Toggler } from '../common/Toggler';

// TODO: CREATE THIS DYNAMICALLY
const CURRENT_TERM = '2024/25';
const INCORRECT_GROUP_NAME_MSG = 'Nazwa grupy jest niepoprawna';
const GROUP_NAME_ALREADY_EXISTS_MSG = (input) => `Grupa ${input.groupName} już istnieje na ${input.term}`;
const STUDENT_GROUP_NAME_PLACEHOLDER = 'nazwa grupy, np. 2a';

interface Props {
    studentGroup: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const AddStudentGroup = ({ studentGroup, refreshViewCallback, cancelCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [groupName, setGroupName] = useState('');
    const [errors, setErrors] = useState([]);
    const [hasApiError, setHasApiError] = useState(false);

    const handleSubmit = () => {
        const payload = { groupName: groupName, term: CURRENT_TERM };
        const validationErrors = validate(payload, studentGroup);
        setErrors(validationErrors);

        if (validationErrors.length === 0) {
            getAccessTokenSilently().then((accessToken) => {
                apiClient(accessToken)
                    .post('/student-groups', payload)
                    .then(() => refreshViewCallback())
                    .catch(() => setHasApiError(true));
            });
        }
    };

    return (
        <>
            <div className="container text-center needs-validation">
                <Toggler condition={hasApiError}>
                    <div className="row justify-content-center">
                        <div className="col-9 col-sm-6 fs-5">
                            <div className="alert alert-danger" role="alert">
                                Wystąpił błąd podczas tworzenia grupy
                            </div>
                        </div>
                    </div>
                </Toggler>

                <div className="row justify-content-center m-5">
                    <div className="col-9 col-sm-6 col-md-4 col-lg-3 fw-bolder fs-5">Dodaj nową grupę</div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-9 col-sm-6 col-md-4 col-lg-3">
                        <div className="form-floating mb-3">
                            {/************ STUDENT GROUP NAME INPUT ************/}
                            <input
                                type="text"
                                className={'form-control ' + (errors.length > 0 && 'is-invalid')}
                                id="groupName"
                                placeholder={STUDENT_GROUP_NAME_PLACEHOLDER}
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                aria-describedby="groupNameValidation"
                            />
                            <label htmlFor="groupName">{STUDENT_GROUP_NAME_PLACEHOLDER}</label>

                            {/************ DISPLAY ERROR BELOW ************/}
                            {errors.map((e) => (
                                <span key={e} id="groupNameValidationFeedback" className="invalid-feedback">
                                    {e}
                                </span>
                            ))}
                        </div>
                        <div className="form-floating">
                            <input type="text" className="form-control" id="term" value={CURRENT_TERM} disabled />
                            <label htmlFor="term">rok szkolny</label>
                        </div>
                    </div>
                </div>
                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                    {/************ CANCEL ************/}
                    <button
                        type="button"
                        className="form-floating btn btn-outline-secondary m-2"
                        onClick={cancelCallback}
                    >
                        Anuluj
                    </button>

                    {/************ ADD GROUP ************/}
                    <button type="button" className="form-floating btn btn-primary m-2" onClick={handleSubmit}>
                        + Dodaj grupę
                    </button>
                </div>
            </div>
        </>
    );
};

const toKey = (item) => `${item.groupName}:${item.term}`.replaceAll(/\s/g, '');
// validation
const validate = (input, existingData) => {
    const existingEntries = existingData.map((d) => toKey(d));
    const err = [];

    if (!input.groupName || input.groupName.trim().length === 0 || input.groupName.trim().length > 50) {
        err.push(INCORRECT_GROUP_NAME_MSG);
    }

    if (input.groupName && input.term && existingEntries.includes(toKey(input))) {
        err.push(GROUP_NAME_ALREADY_EXISTS_MSG(input));
    }

    return err;
};
