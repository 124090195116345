import React, { useState } from 'react';
import { apiClient } from '../../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import { removeAllWhitespace } from '../../lib/utils';

const FIRST_NAME_PLACEHOLDER = 'imię ucznia';
const LAST_NAME_PLACEHOLDER = 'nazwisko ucznia';
const PHONE_NUMBER_PLACEHOLDER = 'numer telefonu';

interface Props {
    studentGroup: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const AddSingleStudent = ({ studentGroup, cancelCallback, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('+48');

    const [errors, setErrors] = useState({});

    const handleSubmit = () => {
        const payload = {
            students: [
                {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    phoneNumber:
                        phoneNumber && (phoneNumber.trim() === '+48' || phoneNumber.trim() === '')
                            ? null
                            : phoneNumber.trim(),
                },
            ],
        };

        const err = validate(payload.students[0]);
        setErrors(err);

        if (err.isValid) {
            getAccessTokenSilently().then((accessToken) => {
                apiClient(accessToken)
                    .post('/students', payload)
                    .then((result) => {
                        const newStudentIds = result?.data?.students?.map((s) => s.id) || [];

                        const addStudentsToStudentGroupRequest = {
                            action: 'ADD_STUDENTS_TO_STUDENT_GROUP',
                            studentIds: newStudentIds,
                        };

                        apiClient(accessToken)
                            .patch(`/student-groups/${studentGroup.id}`, addStudentsToStudentGroupRequest)
                            .then(() => refreshViewCallback());
                    });
            });
        }
    };

    return (
        <>
            <div className="container text-center needs-validation">
                <div className="row justify-content-center m-5">
                    <div className="col-9 col-sm-6 col-md-4 col-lg-3 fw-bolder fs-5">Dodaj ucznia</div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-9 col-sm-6 col-md-4">
                        {/************ STUDENT FIRST NAME ************/}
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className={'form-control ' + (errors.firstName && 'is-invalid')}
                                id="firstName"
                                placeholder={FIRST_NAME_PLACEHOLDER}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                aria-describedby="firstName"
                            />
                            <label htmlFor="firstName">{FIRST_NAME_PLACEHOLDER}</label>

                            {/************ DISPLAY ERROR BELOW ************/}
                            {errors.firstName && (
                                <span className="invalid-feedback" id="firstNameFeedback">
                                    {errors.firstName}
                                </span>
                            )}
                        </div>

                        {/************ STUDENT LAST NAME ************/}
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className={'form-control ' + (errors.lastName && 'is-invalid')}
                                id="lastName"
                                placeholder={LAST_NAME_PLACEHOLDER}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                aria-describedby="lastName"
                            />
                            <label htmlFor="lastName">{LAST_NAME_PLACEHOLDER}</label>

                            {/************ DISPLAY ERROR BELOW ************/}
                            {errors.lastName && (
                                <span className="invalid-feedback" id="lastNameFeedback">
                                    {errors.lastName}
                                </span>
                            )}
                        </div>

                        {/************ STUDENT PHONE NUMBER ************/}
                        <small className="text-muted">Proszę uwzględnić nr kierunkowy, np. +48XXXyyyZZZ</small>
                        <div className="form-floating mb-3">
                            <input
                                type="tel"
                                className={'form-control ' + (errors.phoneNumber && 'is-invalid')}
                                id="phoneNumber"
                                placeholder={PHONE_NUMBER_PLACEHOLDER}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                aria-describedby="phoneNumber"
                            />
                            <label htmlFor="lastName">{PHONE_NUMBER_PLACEHOLDER}</label>

                            {/************ DISPLAY ERROR BELOW ************/}
                            {errors.phoneNumber && (
                                <span className="invalid-feedback" id="phoneNumberFeedback">
                                    {errors.phoneNumber}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                    {/************ CANCEL ADDING NEW STUDENT ************/}
                    <button
                        type="button"
                        className="form-floating btn btn-outline-secondary m-2"
                        onClick={cancelCallback}
                    >
                        Anuluj
                    </button>

                    {/************ FIND A STUDENT ************/}
                    <button type="button" className="form-floating btn btn-primary m-2" onClick={handleSubmit}>
                        Dodaj ucznia
                    </button>
                </div>
            </div>
        </>
    );
};

const validate = (student) => {
    const err = { isValid: true };

    if (!student.firstName || student.firstName.trim().length < 2 || student.firstName.trim().length > 50) {
        err.firstName = 'Proszę podać imię ucznia';
        err.isValid = false;
    }

    if (!student.lastName || student.lastName.trim().length < 2 || student.lastName.trim().length > 50) {
        err.lastName = 'Proszę podać nazwisko ucznia';
        err.isValid = false;
    }

    if (
        student.phoneNumber &&
        (removeAllWhitespace(student.phoneNumber).length !== 12 ||
            removeAllWhitespace(student.phoneNumber).charAt(0) !== '+')
    ) {
        err.phoneNumber = 'Podany numer telefonu wydaje się niepoprawny';
        err.isValid = false;
    }

    return err;
};
