import React, { useState } from 'react';
import './eventCreatorWizard.css';
import { Toggler } from '../common/Toggler';
import { CollectEventDetails } from './wizard/CollectEventDetails';
import { CollectEventSchedule } from './wizard/CollectEventSchedule';
import dayjs from 'dayjs';
import { CollectEventLocation } from './wizard/CollectEventLocation';
import { CollectEventPricing } from './wizard/CollectEventPricing';
import Spinner from '../common/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';
import { EventCreatorWizardError } from './wizard/EventCreatorWizardError';
import { SelectStudentGroupForEvent } from './wizard/SelectStudentGroupForEvent';
import { CollectEventPricingTypes } from './wizard/CollectEventPricingTypes';
import { toFee } from '../../lib/utils';

interface Props {
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

const initialValues = () => {
    return {
        eventName: '',
        eventDate: dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm'),
        eventDuration: '01:00',
        durationInMinutes: '60',
        scheduleType: 'ONE_OFF',
        scheduleEndType: 'ON_DATE',
        scheduleEndsOn: dayjs().add(1, 'month').endOf('day').format('YYYY-MM-DDTHH:mm'),
        scheduleRepeatEveryCount: '1',
        scheduleEndsAfterOccurrences: '1',
        locationId: '',
        studentGroupId: '',
        capacityType: 'OPEN_CAPACITY',
        eventCapacity: '',
        feeType: 'FIXED_FEE',
        feeCurrency: 'PLN',
        feeValue: 0,
        requiresAttendanceCheck: true,
        acceptOnlinePayment: false,
        acceptCashPayment: false,
        acceptTransferPayment: false,
        transactionFeePayableByGuardian: false,
        bankAccountNumberForPayment: '',
        preferredPaymentType: 'UNKNOWN',
    };
};

export const EventCreatorWizard = ({ cancelCallback, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    // Order of these steps matter!
    const steps = [
        'COLLECT-EVENT-DETAILS',
        'COLLECT-EVENT-SCHEDULE',
        'SELECT-STUDENT-GROUP-FOR-EVENT',
        'COLLECT-EVENT-LOCATION',
        'COLLECT-EVENT-PRICING',
        'COLLECT-EVENT-PRICING-TYPES',
    ];
    const [stepIndex, setStepIndex] = useState(0);
    const [pageState, setPageState] = useState(steps[0]);
    const [payload, setPayload] = useState(initialValues());

    const calculateDuration = (time) => {
        let parts = time.split(':');
        let hours = Number(parts[0]);
        let minutes = Number(parts[1]);
        return hours * 60 + minutes;
    };

    const handleCreateEvent = () => {
        setPageState('LOADING');
        getAccessTokenSilently().then((accessToken) => {
            const createEventRequest = {
                ...payload,
                feeValue: toFee(payload.feeValue),
                durationInMinutes: calculateDuration(payload.eventDuration),
                eventDate: dayjs(payload.eventDate, 'YYYY-MM-DDTHH:mm'),
                scheduleEndsOn: dayjs(payload.scheduleEndsOn, 'YYYY-MM-DDTHH:mm'),
                bankAccountNumberForPayment: payload.bankAccountNumberForPayment.trim(),
            };
            apiClient(accessToken)
                .post('/events', createEventRequest)
                .then(() => refreshViewCallback())
                .catch(() => setPageState('ERROR'));
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            [name]: value,
        });
    };

    const progressValue = () => {
        const percent = ((stepIndex + 1) / steps.length) * 100;
        return percent + '%';
    };

    const stepForward = () => {
        const next = stepIndex + 1;
        setStepIndex(next);
        setPageState(steps[next]);
    };

    const stepBackward = () => {
        const prev = stepIndex - 1;
        setStepIndex(prev);
        setPageState(steps[prev]);
    };

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 col-sm-8 col-md-6 col-lg-4">
                        <div className="d-flex flex-column gap-3 mt-4">
                            <div className="fs-4 fw-bold">Dodaj nowe zajęcia</div>

                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    style={{ width: `${progressValue()}` }}
                                    aria-label="Basic example"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>

                            <Toggler condition={pageState === 'LOADING'}>
                                <div className="d-flex flex-column-reverse align-items-center">
                                    <Spinner />
                                    <p className="small text-muted">Proszę czekać</p>
                                </div>
                            </Toggler>

                            <Toggler condition={pageState === 'ERROR'}>
                                <EventCreatorWizardError refreshViewCallback={refreshViewCallback} />
                            </Toggler>

                            <Toggler condition={pageState === 'COLLECT-EVENT-DETAILS'}>
                                <CollectEventDetails
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    nextStepCallback={stepForward}
                                />
                            </Toggler>

                            <Toggler condition={pageState === 'COLLECT-EVENT-SCHEDULE'}>
                                <CollectEventSchedule
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    previousStepCallback={stepBackward}
                                    nextStepCallback={stepForward}
                                />
                            </Toggler>

                            <Toggler condition={pageState === 'SELECT-STUDENT-GROUP-FOR-EVENT'}>
                                <SelectStudentGroupForEvent
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    previousStepCallback={stepBackward}
                                    nextStepCallback={stepForward}
                                />
                            </Toggler>

                            <Toggler condition={pageState === 'COLLECT-EVENT-LOCATION'}>
                                <CollectEventLocation
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    previousStepCallback={stepBackward}
                                    nextStepCallback={stepForward}
                                />
                            </Toggler>

                            <Toggler condition={pageState === 'COLLECT-EVENT-PRICING'}>
                                <CollectEventPricing
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    previousStepCallback={stepBackward}
                                    nextStepCallback={stepForward}
                                />
                            </Toggler>

                            <Toggler condition={pageState === 'COLLECT-EVENT-PRICING-TYPES'}>
                                <CollectEventPricingTypes
                                    cancelCallback={cancelCallback}
                                    inputValues={payload}
                                    inputHandler={handleInputChange}
                                    previousStepCallback={stepBackward}
                                    nextStepCallback={handleCreateEvent}
                                />
                            </Toggler>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
