import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {useState} from "react";
import {refreshPage} from "../../lib/utils";
import {Toggler} from "../../components/common/Toggler";
import {Email} from "../../components/common/Email";

interface Props {
    userPhoneNumber: string;
}

export const UserPhoneVerificationView = ({userPhoneNumber}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [verificationInProgress, setVerificationInProgress] = useState(false);
    const [pageStatus, setPageStatus] = useState('VIEW');
    const [userCode, setUserCode] = useState('');

    const handleRequestPhoneVerification = () => {
        setVerificationInProgress(true);

        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    action: 'REQUEST_PHONE_VERIFICATION'
                };

                apiClient(accessToken).patch('/users/_self/verification', payload)
                    .then(() => {
                        setPageStatus('VERIFY');
                    })
                    .finally(() => setVerificationInProgress(false));
            });
    }

    const handleCheckPhoneVerification = (userCode: string) => {
        setVerificationInProgress(true);

        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    action: 'CHECK_PHONE_VERIFICATION',
                    userProvidedCode: userCode
                };

                apiClient(accessToken).patch('/users/_self/verification', payload)
                    .then(() => {
                        // NB. THIS SHOULD BE USED CAREFULLY, ONLY IN SPECIAL CIRCUMSTANCES
                        // It is OK to use in this case as we want to refresh the entire page
                        // to reflect the user having their email verified
                        refreshPage();
                    })
                    .finally(() => setVerificationInProgress(false));
            });
    }

    return <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 text-center">

                {/* ALERT VIEW */}
                <div className="alert alert-warning text-center">
                    <Toggler condition={!verificationInProgress}>
                        <div className="fs-3">Prosimy o weryfikację Twojego numeru telefonu</div>
                        <hr/>
                        <Toggler condition={!!userPhoneNumber}>
                            <div className="fs-5">{userPhoneNumber}</div>
                            <hr/>
                        </Toggler>
                        <div>
                            Wyślemy do Ciebie SMS z kodem. Wprowadź kod poniżej.
                        </div>
                    </Toggler>

                    {/* SKELETON WHILE LOADING */}
                    <Toggler condition={verificationInProgress}>
                        <div className="placeholder-glow" aria-hidden="true">
                            <div className="placeholder col-12 fs-1"></div>
                            <hr/>
                            <Toggler condition={!!userPhoneNumber}>
                                <div className="placeholder fs-5 col-4"></div>
                                <hr/>
                            </Toggler>
                            <div className="placeholder fs-6 col-8"></div>
                        </div>
                    </Toggler>
                </div>

                {/* VIEW BUTTON ONLY TO REQUEST CODE */}
                <Toggler condition={pageStatus === 'VIEW'}>
                    <button className="btn btn-primary m-4"
                            onClick={handleRequestPhoneVerification}
                            disabled={verificationInProgress}>
                        Wyślij kod na powyższy numer
                    </button>
                </Toggler>

                {/* VERIFY SENT CODE */}
                <Toggler condition={pageStatus === 'VERIFY'}>
                    <div className="container">
                        <div className="row align-items-center">

                            {/* INPUT */}
                            <div className="col">
                                <div className="form-floating">
                                    <input type="number" min="100000" max="999999" className="form-control"
                                           onChange={(e) => setUserCode(e.target.value)}
                                           value={userCode}
                                           id="floatingInput"
                                           placeholder="Wprowadź kod z SMSa"/>
                                    <label>Wprowadź kod z SMSa</label>
                                </div>
                            </div>

                            {/* BUTTON */}
                            <div className="col">
                                <button className="btn btn-primary"
                                        onClick={() => handleCheckPhoneVerification(userCode)}
                                        disabled={verificationInProgress}>
                                    Zweryfikuj Kod
                                </button>
                            </div>
                        </div>
                    </div>
                </Toggler>

                <p className="small text-muted mt-4">
                    Jeśli nadal masz problemy z weryfikacją, napisz do nas <Email/>.</p>
            </div>
        </div>
    </div>
}
