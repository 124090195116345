import React from "react";

interface Props {
    header?: any,
    body?: any,
    footer?: any,
    theme?: string
}

export const SPCard = ({header, body, footer, theme}: Props) => {
    return <div className="container text-center">
        <div className="row justify-content-center">
            <div className="col-12 col-sm-9 col-md-8 col-lg-6 mt-3">
                <div className={`card ${theme || 'bg-light'}`}>
                    {header && <div className="card-header">{header}</div>}
                    {body && <div className="card-body">{body}</div>}
                    {footer && <div className="card-footer">{footer}</div>}
                </div>
            </div>
        </div>
    </div>
}
