import React, { useEffect, useState } from 'react';
import { apiClientPublic } from '../../../apiClientV1';
import { useNavigate, useParams } from 'react-router';
import './organisationProfileView.css';
import './../../../common.css';
import { CollectContactDetailsAndRegister } from '../../../components/enrolments/publicEnrolments/CollectContactDetailsAndRegister';
import Spinner from '../../../components/common/Spinner';

const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
export const LearnerEnrolmentView = () => {
    const navigate = useNavigate();

    const { publicIdentifier, enrolmentId } = useParams();
    const [enrolment, setEnrolment] = useState();
    const [notFoundError, setNotFoundError] = useState();

    useEffect(() => {
        apiClientPublic()
            .get(`/organisations/${publicIdentifier}/enrolments/${enrolmentId}`)
            .then((result) => {
                setEnrolment(result.data);
            })
            .catch(() => {
                // 404 = NOT FOUND
                // if (error.response.status === 404)
                setNotFoundError({ id: publicIdentifier, eId: enrolmentId });
            });
    }, [enrolmentId, publicIdentifier]);

    function cancelCallback() {
        navigate(`/discover/org/${publicIdentifier}`);
    }

    return enrolment ? (
        <div className="d-flex flex-column mb-4">
            {/*<pre>{JSON.stringify(enrolment, null, 2)}</pre>*/}
            <CollectContactDetailsAndRegister enrolment={enrolment} cancelCallback={cancelCallback} />
        </div>
    ) : notFoundError ? (
        <div className="d-flex flex-column align-items-center m-5">
            <div className="fs-2">Nie znaleziono zapisu o takim identyfikatorze</div>
            <div className="fs-5 text-muted mt-3">{notFoundError.eId}</div>
            <div className="fs-5 text-muted mt-3">(org: {notFoundError.id})</div>

            <a href={logoutUrl} className="mt-5">
                Wróć do strony głównej
            </a>
        </div>
    ) : (
        <div className="text-center m-5">
            <Spinner />
        </div>
    );
};
