import React from 'react';

interface Props {
    btnStyle?: string;
    closeStyle?: string;
    filter: any;
    removeFilterCallback: () => void;
}

export const EventFilterChip = ({
    filter,
    removeFilterCallback,
    btnStyle = 'btn-outline-dark',
    closeStyle = 'btn-primary',
}: Props) => {
    return (
        <div>
            <div className="btn-group btn-group-sm" role="group">
                <button type="button" className={`btn ${btnStyle}`}>
                    {filter.displayValue}
                </button>
                <button type="button" className={`btn ${closeStyle}`} onClick={() => removeFilterCallback(filter)}>
                    X
                </button>
            </div>
        </div>
    );
};
