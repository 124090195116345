import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatPln} from "../../lib/utils";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";

interface Props {
    learner: any;
}

export const LearnerCard = ({learner}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [unpaidEvents, setUnpaidEvents] = useState(null);

    useEffect(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get(`/event-register?learnerId=${learner.id}`)
                    .then(res => {
                        setUnpaidEvents(res.data?.items.filter(eventRegisterItem => eventRegisterItem.status === 'UNPAID'));
                    });
            });
    }, [learner.id, getAccessTokenSilently]);

    return learner && unpaidEvents ?
        <div className="card bg-light">
            <div className="card-body">
                <h5 className="card-title">{learner.displayName}</h5>

                {unpaidEvents.length === 0 ?
                    // NO EVENTS LEFT TO PAY
                    <>
                        <div className="d-flex flex-row gap-2 p-2">
                            <FontAwesomeIcon className="text-success pt-1" icon="fa-solid fa-circle-check"/>
                            <p className="card-text">Nie masz zajęć do opłacenia</p>
                        </div>
                        <div className="text-end">
                            <Link className="btn btn-sm btn-outline-primary" to={`/learners/${learner.id}`}>
                                Zobacz zajęcia
                            </Link>
                        </div>
                    </>
                    :
                    <>
                        {unpaidEvents.length === 1 ?
                            // ONE EVENT
                            <>
                                <div className="d-flex flex-row gap-2 p-2">
                                    <FontAwesomeIcon className="text-warning pt-1"
                                                     icon="fa-solid fa-circle-exclamation"/>
                                    <div className="card-text">Opłać:</div>
                                    <div>{formatPln(unpaidEvents[0].fee.value)}</div>
                                </div>
                                <div className="card-text">
                                    {unpaidEvents[0].event.displayName}
                                </div>
                            </>
                            :
                            // MULTIPLE EVENTS
                            <>
                                <div className="d-flex flex-row gap-2 p-2">
                                    <FontAwesomeIcon className="text-warning pt-1"
                                                     icon="fa-solid fa-circle-exclamation"/>
                                    <p className="card-text">
                                        Masz {unpaidEvents.length}. zajęć do opłacenia
                                    </p>
                                </div>
                            </>}

                        <div className="text-end">
                            <Link className="btn btn-sm btn-primary" to={`/learners/${learner.id}`}>Opłać zajęcia</Link>
                        </div>
                    </>
                }
            </div>
        </div>
        :
        <div className="container mt-5">
            <div className="card bg-light placeholder-glow p-3" aria-hidden="true">
                <h2 className="placeholder-glow" aria-hidden="true">
                    <span className="placeholder col-4"></span>
                </h2>
                <p className="placeholder-glow" aria-hidden="true">
                    <span className="placeholder col-5"></span>
                </p>
                <div className="text-end">
                    <span className="btn btn-primary disabled placeholder col-2 " aria-hidden="true"></span>
                </div>
            </div>
        </div>
}
