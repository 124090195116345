import React, {useState} from "react";

interface Props {
    cancelCallback: () => void;
    proceedCallback: (data) => void;
}

const ORGANISATION_NAME_PLACEHOLDER = "Nazwa Twojej szkoły/firmy";
const WEBSITE_PLACEHOLDER = "Adres swojej strony internetowej";

export const AddInstructorDetails = ({cancelCallback, proceedCallback}: Props) => {
    const [organisationName, setOrganisationName] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [errors, setErrors] = useState({});

    function validateAndProceed() {
        const result = validate(organisationName, websiteUrl);
        setErrors(result);

        if (result.isValid) {
            proceedCallback({
                organisationName: organisationName.trim(),
                websiteUrl: websiteUrl.trim()
            });
        }
    }
    
    return <>
    <h1 className="text-center m-5">Podaj dodatkowe informacje</h1>
    
    <div className="row justify-content-center align-content-center flex-column">
        <div className="col-10 col-md-5 col-lg-5 col-xl-5">

            {/************ ORGANISATION NAME ************/}
            <div className="form-floating mb-3">
                <input type="text"
                    className={"form-control " + (errors.organisationName && 'is-invalid')}
                    id="organisationName"
                    placeholder={ORGANISATION_NAME_PLACEHOLDER}
                    value={organisationName}
                    onChange={(e) => setOrganisationName(e.target.value)}
                    aria-describedby="organisationName"/>
                <label htmlFor="organisationName">{ORGANISATION_NAME_PLACEHOLDER}</label>

                {/************ DISPLAY ERROR BELOW ************/}
                {errors.organisationName &&
                    <span className="invalid-feedback" id="organisationNameFeedback">{errors.organisationName}</span>}
            </div>

            <p className="text-muted small mb-5">Nazwa która jest znana dla Twoich klientów</p>

            
            {/************ WEBSITE URL ************/}
            <div className="form-floating mb-3">
                <input type="text"
                    className={"form-control " + (errors.websiteUrl && 'is-invalid')}
                    id="websiteUrl"
                    placeholder={WEBSITE_PLACEHOLDER}
                    value={websiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                    aria-describedby="websiteUrl"/>
                <label htmlFor="websiteUrl">{WEBSITE_PLACEHOLDER}</label>
    
                {/************ DISPLAY ERROR BELOW ************/}
                {errors.websiteUrl &&
                    <span className="invalid-feedback" id="websiteUrlFeedback">{errors.websiteUrl}</span>}
            </div>
            
            <p className="text-muted small">np. link to profilu Twojej szkoły/firmy na Facebook, LinkedIn bądź swojej własnej strony internetowej</p>
        </div>
    </div>
    
    <div className="d-grid gap-2 d-flex justify-content-center m-3">
        {/************ CANCEL ************/}
        <button type="button" className="btn btn-outline-secondary" onClick={cancelCallback}>
            Anuluj
        </button>

        {/************ CLEAR THE FORM ************/}
        <button type="button" className="btn btn-primary" onClick={validateAndProceed}>
            Potwierdź
        </button>
    </div>
    </>
}

const validate = (organisationName, websiteUrl) => {
    const err = {isValid: true}

    if (organisationName.trim() === '' || organisationName.trim().length < 3) {
        err.organisationName = 'Proszę podać nazwę';
        err.isValid = false;
    }

    if (websiteUrl.trim() === '' || websiteUrl.trim().length < 3 || websiteUrl.trim().indexOf(' ') >= 0) {
        err.websiteUrl = 'Proszę podać adres strony, np. https://twoja-strona.pl';
        err.isValid = false;
    }

    return err;
}
