import React, { useCallback, useEffect, useState } from 'react';
import './../common.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiClient } from '../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import { EnrolmentCards } from '../components/enrolments/EnrolmentCards';
import { EnrolmentCreatorWizard } from '../components/enrolments/EnrolmentCreatorWizard';

export const EnrolmentsView = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [pageStatus, setPageStatus] = useState('VIEW');
    const [enrolments, setEnrolments] = useState(null);

    const fetchData = useCallback(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get('/enrolments')
                .then((res) => setEnrolments(res?.data?.enrolments));
        });
    }, [getAccessTokenSilently]);

    function fetchDataAndResetView() {
        setPageStatus('VIEW');
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return enrolments ? (
        <>
            {/************ CREATING NEW ENROLMENT FORM ************/}
            {pageStatus === 'CREATE-ENROLMENT-FORM' && (
                <EnrolmentCreatorWizard
                    cancelCallback={() => setPageStatus('VIEW')}
                    refreshViewCallback={() => fetchDataAndResetView()}
                />
            )}

            {/************ VIEWING ENROLMENTS ************/}
            {pageStatus === 'VIEW' && (
                <>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/">
                                    <FontAwesomeIcon icon="fa-solid fa-home" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Zapisy
                            </li>
                        </ol>
                    </nav>

                    <div className="d-grid gap-2 d-flex justify-content-end m-3">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setPageStatus('CREATE-ENROLMENT-FORM')}
                        >
                            + Nowy formularz rejestracyjny
                        </button>
                    </div>

                    <EnrolmentCards enrolments={enrolments} refreshViewCallback={fetchDataAndResetView} />
                </>
            )}
        </>
    ) : (
        // ************ PLACEHOLDER ************
        <>
            <div className="placeholder-glow" aria-hidden="true">
                <span className="placeholder col-1"></span>
            </div>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-end m-3 placeholder-glow" aria-hidden="true">
                <span className="btn btn-primary disabled placeholder col-1"></span>
            </div>
            <div className="placeholder-glow p-3" aria-hidden="true">
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
            </div>
        </>
    );
};
