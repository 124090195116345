import React from "react";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import './addStudentGroup.css';
import {SPToastContainer} from "../common/SPToastContainer";
import {toast} from "react-toastify";

interface Props {
    studentGroup: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const DeleteStudentGroup = ({studentGroup, refreshViewCallback, cancelCallback}: Props) => {
    
    const {getAccessTokenSilently} = useAuth0();

    const handleSubmit = () => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).delete(`/student-groups/${studentGroup.id}`)
                    .then(() => refreshViewCallback())
                    .catch(() => toast.error('Wystąpił błąd.'));
            });
    }

    return <>
        <div className="text-center">

            <div className="fw-bolder fs-5 m-5">Czy na pewno chcesz usunąć?</div>

            <div className="mb-5">
                <div className="fs-3">{studentGroup.displayName}</div>
                <div className="small text-muted">Nazwa</div>
                
                <div className="fs-5 mt-3">{studentGroup.term}</div>
                <div className="small text-muted">Rocznik</div>
            </div>
            
            <div className="d-grid gap-2 d-flex justify-content-center m-3">
                {/************ CANCEL ************/}
                <button type="button" className="form-floating btn btn-outline-secondary"
                        onClick={cancelCallback}>Anuluj
                </button>

                {/************ ADD GROUP ************/}
                <button type="button" className="form-floating btn btn-danger"
                        onClick={handleSubmit}>Potwierdź
                </button>
            </div>
            
            <SPToastContainer />
        </div>
    </>
}
