import React from "react";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {Toggler} from "../common/Toggler";

interface Props {
    learner: any;
    refreshViewCallback: () => void;
}

export const LearnerPhoneNumberAssignCard = ({learner, refreshViewCallback}: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const handleAssignPhoneNumber = () => {
        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    action: 'UPDATE_LEARNER_PHONE_FROM_GUARDIAN'
                }
                apiClient(accessToken).patch(`/learners/${learner.id}`, payload)
                    .then(() => refreshViewCallback());
            });
    }

    return <>
        <Toggler condition={learner && learner.status === 'REQUIRES_PHONE_NUMBER'}>
            <div className="d-flex flex-row justify-content-center">
                <div className="card bg-warning mt-5 w-75">
                    <div className="card-header">
                        <div className="fs-3">Brak numeru telefonu</div>
                    </div>
                    <div className="card-body text-bg-light">
                        <p className="fs-4">Numer telefonu ucznia/podopiecznego jest wymagany.</p>
                        <p className="text-muted">Jest on potrzebny do kontaktu z rodzicem/opiekunem.</p>

                        <div className="text-center">
                            <button
                                className="btn btn-primary"
                                onClick={handleAssignPhoneNumber}>Przypisz numer telefonu opiekuna
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Toggler>
    </>
}
