import React, { useState } from 'react';
import { formatPln } from '../../lib/utils';
import { EnrolmentStatusBadge } from '../common/EnrolmentStatusBadge';
import { Toggler } from '../common/Toggler';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';
import { ConfirmationModal } from '../common/ConfirmationModal';

interface Props {
    enrolment: any;
    refreshViewCallback: () => void;
}

export const EnrolmentCard = ({ enrolment, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [activeModal, setActiveModal] = useState();

    function handleStatusChange(enrolmentId, action) {
        getAccessTokenSilently().then((accessToken) => {
            const request = {
                action: action,
            };

            apiClient(accessToken)
                .patch(`/enrolments/${enrolmentId}`, request)
                .finally(() => refreshViewCallback());
        });
    }

    function showSeparatorIfRequired(enrolment, separator) {
        return enrolment.locationName && enrolment.buildingName ? separator : '';
    }

    function closeModal() {
        setActiveModal('');
    }

    function renderIfHasValue(prefix, value) {
        return (
            !!value && (
                <div>
                    {prefix}: {value}
                </div>
            )
        );
    }

    return (
        enrolment && (
            <>
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="d-flex gap-2 justify-content-end flex-wrap">
                            <Link className="btn btn-sm btn-outline-primary" to={`/enrolments/${enrolment.id}`}>
                                Zobacz Zapisy
                            </Link>

                            <Toggler condition={enrolment.metadata?.canStartEnrolment}>
                                <ConfirmationModal
                                    showModal={activeModal === 'start_enrolment'}
                                    onClose={closeModal}
                                    onConfirm={() => handleStatusChange(enrolment.id, 'MARK_IN_PROGRESS')}
                                    question="Czy na pewno chcesz rozpocząć zapisy?"
                                />

                                <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => setActiveModal('start_enrolment')}
                                >
                                    Rozpocznij Zapisy
                                </button>
                            </Toggler>

                            <Toggler condition={enrolment.metadata?.canDraftEnrolment}>
                                <ConfirmationModal
                                    showModal={activeModal === 'move_to_draft'}
                                    onClose={closeModal}
                                    onConfirm={() => handleStatusChange(enrolment.id, 'MARK_DRAFT')}
                                    question="Czy na pewno chcesz przenieść do wersji roboczej?"
                                />

                                <button
                                    className="btn btn-sm btn-warning"
                                    onClick={() => setActiveModal('move_to_draft')}
                                >
                                    Wersja Robocza
                                </button>
                            </Toggler>

                            <Toggler condition={enrolment.metadata?.canEndEnrolment}>
                                <ConfirmationModal
                                    showModal={activeModal === 'end_enrolment'}
                                    onClose={closeModal}
                                    onConfirm={() => handleStatusChange(enrolment.id, 'END_ENROLMENT')}
                                    question="Czy na pewno chcesz zakończyć zapisy?"
                                />

                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => setActiveModal('end_enrolment')}
                                >
                                    Zakończ Zapisy
                                </button>
                            </Toggler>

                            <Toggler condition={enrolment.metadata?.canArchiveEnrolment}>
                                <ConfirmationModal
                                    showModal={activeModal === 'archive_enrolment'}
                                    onClose={closeModal}
                                    onConfirm={() => handleStatusChange(enrolment.id, 'ARCHIVE_ENROLMENT')}
                                    question="Czy na pewno chcesz przenieść zapisy do archiwum?"
                                />

                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => setActiveModal('archive_enrolment')}
                                >
                                    Archiwizuj
                                </button>
                            </Toggler>

                            <Toggler condition={enrolment.metadata?.canDeleteEnrolment}>
                                <ConfirmationModal
                                    showModal={activeModal === 'delete_enrolment'}
                                    onClose={closeModal}
                                    onConfirm={() => handleStatusChange(enrolment.id, 'DELETE_ENROLMENT')}
                                    question="Czy na pewno chcesz usunąć? Nie będzie możliwości przywrócenia danych."
                                />

                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => setActiveModal('delete_enrolment')}
                                >
                                    Usuń
                                </button>
                            </Toggler>
                        </div>
                    </div>

                    <div className="card-body d-flex">
                        {/*LOGO*/}
                        <img
                            src={enrolment.logoUrl}
                            className="img-fluid img-round-100 p-3"
                            alt={enrolment.enrolmentName}
                        />

                        <div>
                            <div className="card-title fs-5 fw-bold">{enrolment.enrolmentName}</div>

                            <div className="mt-2 mb-3">
                                <EnrolmentStatusBadge status={enrolment.status} />
                            </div>

                            <div className="card-text">
                                {enrolment.locationName} {showSeparatorIfRequired(enrolment, '/')}{' '}
                                {enrolment.buildingName}
                            </div>

                            {renderIfHasValue('Cena', formatPln(enrolment?.fee?.value))}
                            {renderIfHasValue('Poziom', enrolment?.experienceLevel)}
                            {renderIfHasValue('Wielkość grupy', enrolment?.capacity)}
                        </div>
                    </div>
                </div>
            </>
        )
    );
};
