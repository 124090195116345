import React, {useCallback, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../apiClientV1";
import {formatPln, incomeType} from "../../lib/utils";
import dayjs from "dayjs";
import Spinner from "../common/Spinner";
import _ from 'lodash';
import '../../common.css';
import Chart from 'react-apexcharts'

const initialChartState = {
    options: {
        labels: [],
        plotOptions: {
            pie: {donut: {size: '50%'}}
        }
    },
    series: []
};

export const IncomeBreakdownReport = () => {
    const {getAccessTokenSilently} = useAuth0();

    const [chartState, setChartState] = useState(initialChartState);
    const [incomeData, setIncomeData] = useState(null);
    const monthAgo = dayjs().add(-1, 'month').startOf("day");
    const firstOfTheMonth = dayjs().startOf("month").startOf("day");
    const endOfDayToday = dayjs().endOf("day");

    const [startDate, setStartDate] = useState(monthAgo);
    const [endDate] = useState(endOfDayToday);

    const formatDate = (date) => {
        return date.format("YYYY-MM-DD");
    }

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                const fromDate = startDate.toISOString();
                const toDate = endDate.toISOString();

                apiClient(accessToken).get(`/reports/income-breakdown?from=${fromDate}&to=${toDate}`)
                    .then(res => {
                        const incomeBreakdown = res?.data?.incomeBreakdown || [];

                        const items = incomeBreakdown.map(item => {
                            return {
                                name: incomeType(item.name),
                                income: item.income,
                                value: item.income / 100
                            }
                        });

                        const data = {
                            totalIncome: res.data.totalIncome,
                            withdrawn: res.data.withdrawn,
                            pendingWithdrawal: res.data.pendingWithdrawal,
                            incomeBreakdown: _.orderBy(items, ['income', 'name'], ['desc', 'asc'])
                        };

                        // ApexChart data
                        const optionLabels = incomeBreakdown.map(item => incomeType(item.name))
                        const chartSeries = incomeBreakdown.map(item => item.income / 100)

                        setChartState({
                            options: {
                                labels: optionLabels,
                                plotOptions: initialChartState.options.plotOptions
                            },
                            series: chartSeries
                        });

                        setIncomeData(data);
                    });
            })
    }, [endDate, getAccessTokenSilently, startDate]);

    const onStartDateChanged = (newStartDate) => {
        // setting incomeData to null as this causes to nicely reload the chart
        setIncomeData(null);
        setStartDate(newStartDate);
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <div className="d-flex flex-column align-items-center">
        <div className="fs-1">Dochód</div>

        {/* DATE RANGE */}
        <div>{formatDate(startDate)} :: {formatDate(endDate)}</div>

        {/* BUTTONS */}
        <div className="d-flex gap-2 mt-3 mb-3">
            <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(monthAgo)}>
                Miesiąc temu
            </button>
            <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(firstOfTheMonth)}>
                Początek miesiąca
            </button>
        </div>

        <div className="d-flex flex-row justify-content-center align-items-center gap-5 mt-5">
            {incomeData && <>
                {incomeData.totalIncome > 0 &&
                    <div className="donut">
                        <Chart options={chartState.options} series={chartState.series} type="donut" width="400"/>
                    </div>}

                {/* CARDS */}
                <div className="d-flex flex-column">
                    {/* CARD */}
                    {incomeData.incomeBreakdown.map(item => (
                        <p key={item.name}>
                            {item.name} : {formatPln(item.income)} {item.name === 'Online' &&
                            <span> :: z czego wypłacono {formatPln(incomeData.withdrawn)}</span>}
                        </p>))}

                    <hr/>

                    <span>Suma: <div className="fs-3 fw-bolder">{formatPln(incomeData.totalIncome)}</div></span>
                    <hr/>

                    <small className="text-muted">Wypłacono: {formatPln(incomeData.withdrawn)}</small>
                    <small className="text-muted">Oczekuje: {formatPln(incomeData.pendingWithdrawal)}</small>
                </div>
            </>}

            {/* SPINNER */}
            {!incomeData && <div className="mt-5">
                <Spinner/>
            </div>}
        </div>
    </div>
}