import React, { useState } from 'react';
import './studentsTable.css';
import { PermissionGate } from '../common/PermissionGate';
import _ from 'lodash';
import { formatFromE164 } from '../../lib/utils';
import { Toggler } from '../common/Toggler';
import { SendStudentsCodeViaSMS } from './SendStudentsCodeViaSMS';
import { EditStudent } from './EditStudent';
import { DeleteStudentFromGroup } from './DeleteStudentFromGroup';
import { ConfirmationModal } from '../common/ConfirmationModal';

interface Props {
    studentGroup: any;
    students: any;
    user: any;
    refreshViewCallback: any;
}

export const StudentsTable = ({ studentGroup, students, user, refreshViewCallback }: Props) => {
    const [pageState, setPageState] = useState('VIEW');
    const [selectedStudent, setSelectedStudent] = useState();
    const [activeModal, setActiveModal] = useState('');

    const sortStudents = (students) => {
        const firstNameSorter = (student) => student.firstName.toLowerCase();
        const lastNameSorter = (student) => student.lastName.toLowerCase();

        return _.orderBy(students, [lastNameSorter, firstNameSorter], ['asc', 'asc']);
    };

    const handleSendStudentCode = (student) => {
        setSelectedStudent(student);
        setPageState('SEND-CODE-TO-STUDENT');
    };

    const confirmStudentRemovalFromGroup = (student) => {
        setSelectedStudent(student);
        setActiveModal('CONFIRM-STUDENT-REMOVAL');
    };

    const editStudent = (student) => {
        setSelectedStudent(student);
        setPageState('EDIT-STUDENT');
    };

    const clearStateToView = () => {
        setSelectedStudent(null);
        setActiveModal('');
        setPageState('VIEW');
    };

    const refresh = () => {
        setSelectedStudent(null);
        setPageState('VIEW');
        setActiveModal('');
        refreshViewCallback();
    };

    return user && studentGroup && students ? (
        <>
            {/************ SEND-CODE-TO-STUDENT ************/}
            {pageState === 'SEND-CODE-TO-STUDENT' && selectedStudent && (
                <>
                    <SendStudentsCodeViaSMS
                        students={[selectedStudent]}
                        refreshViewCallback={refresh}
                        cancelCallback={clearStateToView}
                    />
                </>
            )}
            {/************ EDIT-STUDENT ************/}
            {pageState === 'EDIT-STUDENT' && selectedStudent && (
                <>
                    <EditStudent
                        student={selectedStudent}
                        refreshViewCallback={refresh}
                        cancelCallback={clearStateToView}
                    />
                </>
            )}

            {/************ VIEW STUDENT ************/}
            {pageState === 'VIEW' && (
                <>
                    <DeleteStudentFromGroup
                        showModal={activeModal === 'CONFIRM-STUDENT-REMOVAL' && selectedStudent}
                        student={selectedStudent}
                        studentGroup={studentGroup}
                        refreshViewCallback={refresh}
                        cancelCallback={clearStateToView}
                    />

                    <Toggler condition={students.length !== 0}>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Uczeń</th>
                                    <th scope="col">Kod ucznia</th>
                                    <th scope="col">Numer tel.</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {sortStudents(students).map((row, index) => (
                                    <tr key={row.id}>
                                        <td className="pt-2">{index + 1}</td>
                                        <td className="pt-2">
                                            {row.lastName}, {row.firstName}
                                        </td>
                                        <td className="pt-2">{row.externalId}</td>
                                        <td className="pt-2">{formatFromE164(row.phoneNumber)}</td>
                                        <td>
                                            <PermissionGate requires={user.permissions.canUpdateStudentGroups}>
                                                <div className="d-flex gap-2 fa-pull-right">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-outline-danger"
                                                        onClick={() => confirmStudentRemovalFromGroup(row)}
                                                    >
                                                        Wykreśl
                                                    </button>

                                                    <PermissionGate requires={user.permissions.canUpdateStudents}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-outline-secondary"
                                                            onClick={() => editStudent(row)}
                                                        >
                                                            Edytuj
                                                        </button>
                                                    </PermissionGate>

                                                    <Toggler condition={row.metadata?.canSendCodeViaSMS}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-outline-primary"
                                                            onClick={() => handleSendStudentCode(row)}
                                                        >
                                                            Wyślij kod SMSem
                                                        </button>
                                                    </Toggler>
                                                </div>
                                            </PermissionGate>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="small text-muted m-4 text-center">koniec danych</div>
                    </Toggler>

                    <Toggler condition={students.length === 0}>
                        <div className="parent">
                            <div className="display-6 m-3">Brak studentów w tej grupie</div>
                            <div className="text-muted fs-5">Możesz ich dodać powyżej</div>
                        </div>
                    </Toggler>
                </>
            )}
        </>
    ) : (
        //************ PLACEHOLDER ************
        <div className="placeholder-glow p-3 text-center" aria-hidden="true">
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
        </div>
    );
};
