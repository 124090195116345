import React from "react";

const EMAIL = 'szkolne.platnosci@gmail.com';

interface Props {
    displayName?: string;
}

export const Email = ({displayName}: Props) => {
    return <a href={`mailto:${EMAIL}`}>{displayName || EMAIL}</a>
}