import React, { useState } from 'react';
import { InputTextBox } from '../../common/InputTextBox';
import { InputCheckBox } from '../../common/InputCheckBox';
import { ConsentBox } from './ConsentBox';
import { Alert } from '../../common/Alert';

const initialValues = () => {
    return {
        title: '',
        documentUrl: '',
        isMandatory: false,
    };
};

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    previousStepCallback: () => void;
    nextStepCallback: () => void;
}

export const CollectEnrolmentConsents = ({
    previousStepCallback,
    nextStepCallback,
    inputValues,
    inputHandler,
}: Props) => {
    // LOCAL STATE
    const [consentId, setConsentId] = useState(0);
    const [currentConsent, setCurrentConsent] = useState(initialValues());
    const [errors, setErrors] = useState({});

    // GENERATES CONSENT ID
    function getConsentId() {
        const newId = consentId + 1;
        setConsentId(newId);
        return newId + '';
    }

    // ADD NEW CONSTENT TO THE LIST
    function handleConsentListChange(input) {
        let validationResult = validateInput(input);
        setErrors(validationResult.errors);

        if (!validationResult.isValid) {
            return;
        }

        const values = {
            id: getConsentId(),
            title: input.title,
            isMandatory: input.isMandatory,
            documentUrl: input.documentUrl,
        };

        setCurrentConsent(initialValues());

        const updatedList = inputValues.consentList.slice();
        updatedList.push(values);
        inputHandler(toEvent('consentList', updatedList));
    }

    // REMOVE A CONSENT FROM THE LIST
    function removeConsentFromList(consent) {
        const updatedList = inputValues.consentList.filter((c) => c.id !== consent.id);
        inputHandler(toEvent('consentList', updatedList));
    }

    // LOCAL INPUT HANDLER
    function inputChangeHandler(e) {
        const { name, value } = e.target;

        setCurrentConsent({
            ...currentConsent,
            [name]: value,
        });
    }

    function toEvent(name, value) {
        return { target: { name: name, value: value } };
    }

    return (
        <>
            <div className="fs-5 fw-light">Dane do zatwierdzenia przez rodzica/opiekuna</div>

            <InputTextBox
                inputValues={currentConsent}
                inputName="title"
                inputHandler={inputChangeHandler}
                placeholder="Treść"
                errors={errors}
            />

            <InputTextBox
                inputValues={currentConsent}
                inputName="documentUrl"
                inputHandler={inputChangeHandler}
                placeholder="Link do dokumentu"
                errors={errors}
            />

            <InputCheckBox
                inputValues={currentConsent}
                inputName="isMandatory"
                inputHandler={inputChangeHandler}
                placeholder="Zaznacz, jeśli rodzic/opiekun musi zatwierdzić tą opcję"
                errors={errors}
            />

            <hr />

            <div className="d-flex justify-content-center gap-2">
                <button
                    type="button"
                    className="form-floating btn btn-success m-2"
                    onClick={() => handleConsentListChange(currentConsent)}
                >
                    Dodaj do listy
                </button>
            </div>

            <hr />

            {inputValues.consentList?.map((consent) => (
                <div key={consent.id}>
                    <ConsentBox consent={consent} removeConsentCallback={removeConsentFromList} />
                </div>
            ))}

            <hr />

            <div className="d-grid gap-2 d-flex justify-content-center">
                {/************ CANCEL ************/}
                <button
                    type="button"
                    className="form-floating btn btn-outline-secondary m-2"
                    onClick={previousStepCallback}
                >
                    Cofnij
                </button>

                {/************ CONTINUE ************/}
                <button type="button" className="form-floating btn btn-primary m-2" onClick={() => nextStepCallback()}>
                    Zapisz
                </button>
            </div>

            <hr />

            <div className="d-flex flex-column gap-3">
                <small className="text-muted">Przykład zgody do zatwierdzenia:</small>

                <Alert theme="primary">
                    Wyrażam zgodę na przetwarzanie podanych danych osobowych w celu realizacji zajęć
                </Alert>
                <Alert theme="primary">
                    Wyrażam zgodę na wykonywanie i publikację zdjęć oraz filmów zawierających wizerunek mojego dziecka
                </Alert>
            </div>
        </>
    );
};

const validateInput = (consent) => {
    let validationResult = { isValid: true, errors: {} };

    if (!consent?.title || consent.title.trim().length < 10) {
        validationResult.errors.title = 'Tytuł musi zawierać minumum 10 znaków';
        validationResult.isValid = false;
    }

    if (consent?.documentUrl) {
        if (consent.documentUrl.trim().length < 10) {
            validationResult.errors.documentUrl = 'Link musi zawierać minumum 10 znaków';
            validationResult.isValid = false;
        }

        if (!consent.documentUrl.trim().startsWith('https://')) {
            validationResult.errors.documentUrl = 'Link musi zaczynać się od https://';
            validationResult.isValid = false;
        }
    }

    return validationResult;
};
