import React from "react";
import _ from 'lodash';
import {Toggler} from "../common/Toggler";
import ClampLines from "react-clamp-lines";
import './messagesTable.css';
import './../../common.css';
import {MessageStatusBadge} from "../common/MessageStatusBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import {PermissionGate} from "../common/PermissionGate";

interface Props {
    messages: any;
    currentPageNumber: int;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    canDeleteMessages: boolean;
    deleteMessageCallback: () => void;
    fetchPreviousPageCallback: () => void;
    fetchNextPageCallback: () => void;
}

export const MessagesTable = ({
                                  messages,
                                  currentPageNumber,
                                  hasPreviousPage,
                                  hasNextPage,
                                  canDeleteMessages,
                                  deleteMessageCallback,
                                  fetchPreviousPageCallback,
                                  fetchNextPageCallback
                              }: Props) => {
    const sortMessages = (messages) => {
        return _.orderBy(messages, ['scheduledFor', 'createdOn'], ['desc', 'desc']);
    }

    const formatDate = (dateStr) => {
        return dayjs(dateStr).format("YYYY-MM-DD :: HH:mm");
    }

    return messages ? <>
            <>
                <Toggler condition={messages.length !== 0}>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Do</th>
                            {/*<th scope="col">Data</th>*/}
                            <th scope="col">Treść</th>
                            <th scope="col">Zaplanowano na</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody className="table-group-divider">

                        {sortMessages(messages).map((row, index) =>
                            <tr key={row.id}>
                                <td className="pt-2">{index + 1}</td>
                                <td className="pt-2">{row.student?.firstName} {row.student?.lastName}</td>
                                {/*<td className="pt-2">{dayjs(row.createdOn).format('YYYY-MM-DD :: HH:mm')}</td>*/}
                                <td className="pt-2 w-50">
                                    <ClampLines id="message-content-clamp"
                                                lines="1"
                                                moreText="więcej"
                                                lessText="mniej"
                                                delay="1"
                                                text={row.messageContent}/>
                                </td>
                                <td className="pt-2">{formatDate(row.scheduledFor)}</td>
                                <td className="pt-2"><MessageStatusBadge status={row.status}/></td>
                                <td className="pt-2">
                                    <PermissionGate requires={canDeleteMessages === true}>
                                        <Toggler condition={row.metadata?.canDeleteMessage === true}>
                                            <button className="btn btn-sm btn-danger fa-pull-right" onClick={() => deleteMessageCallback(row?._links?.self)}>Usuń</button>
                                        </Toggler>
                                    </PermissionGate>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                    {/* *********************** PAGE NAVIGATION *********************** */}
                    <div className="d-flex flex-row justify-content-center align-items-baseline gap-3">
                        <Toggler condition={hasPreviousPage}>
                            <button className="btn btn-outline-secondary" onClick={fetchPreviousPageCallback}>
                                <FontAwesomeIcon icon="fa-solid fa-chevron-left"/>
                            </button>
                        </Toggler>

                        <div className="circle">
                            <div className="circle-text">{currentPageNumber + 1}</div>
                        </div>

                        <Toggler condition={hasNextPage}>
                            <button className="btn btn-outline-secondary" onClick={fetchNextPageCallback}>
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>
                            </button>
                        </Toggler>
                    </div>
                    {/* *********************** PAGE NAVIGATION *********************** */}

                    <div className="small text-muted m-4 text-center">koniec danych</div>
                </Toggler>

                <Toggler condition={messages.length === 0}>
                    <div className="parent">
                        <div className="display-6 m-3">Brak wiadomości</div>
                    </div>
                </Toggler>
            </>
        </>
        :
        //************ PLACEHOLDER ************
        <div className="placeholder-glow p-3 text-center" aria-hidden="true">
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
        </div>
}
