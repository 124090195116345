import React, {useState} from "react";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {formatFromE164, removeAllWhitespace} from "../../lib/utils";
import {Toggler} from "../common/Toggler";

const ERROR_MSG = '*** błąd ***';

interface Props {
    studentGroup: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const AddMultipleStudents = ({
                                studentGroup,
                                cancelCallback,
                                refreshViewCallback
                              }: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    
    const [pageState, setPageState] = useState('TEXT-INPUT');
    const [inputText, setInputText] = useState('');
    const [students, setStudents] = useState([]);
    
    function handleSubmit(students) {
        const createStudentsRequest = {
            students: students
        };

        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).post('/students', createStudentsRequest)
                    .then((result) => {
                        const newStudentIds = result?.data?.students?.map(s => s.id) || [];
                        
                        const addStudentsToStudentGroupRequest = {
                            action: "ADD_STUDENTS_TO_STUDENT_GROUP",
                            studentIds: newStudentIds
                        }
                        apiClient(accessToken).patch(`/student-groups/${studentGroup.id}`, addStudentsToStudentGroupRequest)
                            .then(() => refreshViewCallback());
                    });
            });
    }
    
    function toLines(text) {
        const input = text || '';
        return input.split(/\r?\n|\r|\n/g).map(line => line.trim());
    }
    
    function toPhoneNumber(rawPhoneNumber) {
        const pnNoWhitespaces = removeAllWhitespace(rawPhoneNumber || '');
        
        if (pnNoWhitespaces.length < 9 || pnNoWhitespaces.length > 12) {
            return 'error';
        }
        
        if (pnNoWhitespaces.length === 9) {
            return formatFromE164('+48' + pnNoWhitespaces);
        } else if (pnNoWhitespaces.startsWith('48') && pnNoWhitespaces.length === 11) {
            return formatFromE164('+' + pnNoWhitespaces)
        } else if (pnNoWhitespaces.startsWith('+48') && pnNoWhitespaces.length === 12) {
            return formatFromE164(pnNoWhitespaces)
        } else {
            return 'error';
        }
    }
    
    function toStudent(line) {
        const input = (line || '').trim();
        const parts = input.split(',').map(part => removeAllWhitespace(part));
        
        if (parts.length < 2 || parts.length > 3) {
            return { firstName: ERROR_MSG, lastName: ERROR_MSG, phoneNumber: 'brak' }
        } else if (parts.length === 2) {
            return { firstName: removeAllWhitespace(parts[1]), lastName: removeAllWhitespace(parts[0]), phoneNumber: 'brak' }
        } else if (parts.length === 3) {
            const phoneNumberOrError = parts[2]?.trim() === ''
                ? 'brak'
                : toPhoneNumber(parts[2]);
            
            const displayPhoneNumber = phoneNumberOrError === 'error'
                ? ERROR_MSG
                : phoneNumberOrError;
            
            return { firstName: removeAllWhitespace(parts[1]), lastName: removeAllWhitespace(parts[0]), phoneNumber: displayPhoneNumber}
        }
    }
    
    function parseAndSetStudents(input) {
        const students = toLines(input).map(line => toStudent(line));
        setStudents(students);
    }

    function handlePreview(input) {
        parseAndSetStudents(input);
        setPageState('PREVIEW');
    }

    function studentsHaveNoErrors(students) {
        const concat = students.map(s => s.firstName + s.lastName + s.phoneNumber);
        return !concat.join('').includes(ERROR_MSG);
    }

    return <>
        <div className="container text-center needs-validation">
            <div className="row justify-content-center m-5">
                <div className="col-9 col-sm-6 col-md-4 col-lg-3 fw-bolder fs-5">Dodaj wielu</div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10 col-sm-8 col-md-6">
                    
                    <div className="d-flex flex-column align-items-start">
                        <div className="small text-muted">Przykłady:</div>
                        <div className="small text-muted">Kowalski, Jan, 888 777 999</div>
                        <div className="small text-muted">Przerwa-Tetmajer, Kazimierz, +48888 777 999</div>
                        <div className="small text-muted">Nowak, Janusz</div>
                    </div>
                    
                    <Toggler condition={pageState === 'TEXT-INPUT'}>
                        <textarea className="fw-light w-100"
                            placeholder="nazwisko, imię, nr telefonu"
                            rows="15"
                            maxLength="1000"
                            autoFocus={true}
                            wrap="off"
                            onChange={(e) => setInputText(e.target.value)}
                            value={inputText}></textarea>
                    </Toggler>
                    
                    <Toggler condition={pageState === 'PREVIEW'}>
                        
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nazwisko</th>
                                    <th scope="col">Imię</th>
                                    <th scope="col">Nr telefonu</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">

                                {students.map((s, index) =>
                                    <tr key={s.firstName + s.lastName + s.phoneNumber + index}>
                                        <td className="pt-2">{index + 1}</td>
                                        <td className={`pt-2 ${s.lastName === ERROR_MSG && 'text-bg-warning'}`}>{s.lastName}</td>
                                        <td className={`pt-2 ${s.firstName === ERROR_MSG && 'text-bg-warning'}`}>{s.firstName}</td>
                                        <td className={`pt-2 ${s.phoneNumber === ERROR_MSG && 'text-bg-warning'}`}>{s.phoneNumber}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        
                    </Toggler>
                </div>
            </div>
            
            <Toggler condition={pageState === 'PREVIEW' && !studentsHaveNoErrors(students)}>
                <div className="text-danger">Napraw wszystkie błędy aby dodać ucznia</div>
            </Toggler>
            
            <div className="d-grid gap-2 d-flex justify-content-center m-3">
                <Toggler condition={pageState === 'TEXT-INPUT'}>
                    {/************ CANCEL ADDING NEW STUDENT ************/}
                    <button type="button" className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}>Anuluj
                    </button>
    
                    {/************ PREVIEW STUDENTS ************/}
                    <button type="button" className="form-floating btn btn-primary m-2"
                        onClick={() => handlePreview(inputText)}>Sprawdź
                    </button>
                </Toggler>
                
                
                <Toggler condition={pageState === 'PREVIEW'}>
                    
                    <button type="button" className="form-floating btn btn-outline-secondary m-2"
                        onClick={() => setPageState('TEXT-INPUT')}>Anuluj
                    </button>
                    
                    {/************ ADD MULTIPLE STUDENTS ************/}
                    <Toggler condition={studentsHaveNoErrors(students)}>
                        <button type="button" className="form-floating btn btn-primary m-2"
                            onClick={() => handleSubmit(students)}>Dodaj wszystkich
                        </button>
                    </Toggler>
                </Toggler>
            </div>

        </div>
    </>
}