import React, { useEffect, useState } from 'react';
import { EventCard } from './EventCard';
import _ from 'lodash';
import { Toggler } from '../common/Toggler';

interface Props {
    user: any;
    events: any;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    refreshViewCallback: () => void;
}

export const EventsList = ({ user, events, hasPreviousPage, hasNextPage, refreshViewCallback }: Props) => {
    const [sortedEvents, setSortedEvents] = useState(null);

    useEffect(() => {
        const sorted = _.orderBy(events, ['eventDate', 'eventName'], ['asc', 'asc']);
        setSortedEvents(sorted);
    }, [events]);

    return (
        sortedEvents && (
            <>
                <div className="col-11 col-sm-10 col-md-9 col-lg-8">
                    <Toggler condition={events.length === 0}>
                        <div className="text-center m-5">
                            <div className="display-6 m-3">Brak nadchodzących zajęć</div>

                            <Toggler condition={hasPreviousPage || hasNextPage}>
                                <div className="text-muted fs-5">Sprawdź historyczne lub przyszłe zajęcia</div>
                            </Toggler>
                        </div>
                    </Toggler>

                    {sortedEvents.map((event, index) => (
                        <div key={`${event.eventDate}-${event.eventFrom}-${index}`}>
                            {/* EVENTS */}
                            <Toggler condition={event.eventType === 'EVENT'}>
                                <EventCard
                                    user={user}
                                    event={event}
                                    index={index}
                                    refreshViewCallback={refreshViewCallback}
                                />
                            </Toggler>
                        </div>
                    ))}

                    <Toggler condition={!hasNextPage}>
                        <div className="small text-muted m-4 text-center">koniec danych</div>
                    </Toggler>
                </div>
            </>
        )
    );
};
