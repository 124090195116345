import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const logoutUrl = process.env.REACT_APP_LOGOUT_URL

export const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <button className="btn btn-outline-secondary" onClick={() => logout({ returnTo: logoutUrl })}>
            Wyloguj
        </button>
    );
};
