import * as React from 'react';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { StudentGroupsView } from './views/StudentGroupsView';
import { StudentsView } from './views/StudentsView';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from './apiClientV1';
import { LearnersView } from './views/LearnersView';
import { LearnerEventsView } from './views/LearnerEventsView';
// import {Navigate} from "react-router";
import { PaymentSuccessView } from './views/payments/PaymentSuccessView';
import { PaymentFailedView } from './views/payments/PaymentFailedView';
import { SettingsView } from './views/settings/SettingsView';
import { UserOnboardingView } from './views/onboarding/UserOnboardingView';
import { FullScreenLayout } from './layouts/FullScreenLayout';
import { NotFoundView } from './views/NotFoundView';
import { ProtectedHomeLayout } from './layouts/ProtectedHomeLayout';
import { OrganisationDiscoveryHomeLayout } from './layouts/OrganisationDiscoveryHomeLayout';
import Spinner from './components/common/Spinner';
import { OrganisationDiscoveryView } from './views/public/OrganisationDiscoveryView';
import { UserEmailVerificationView } from './views/onboarding/UserEmailVerificationView';
import { UserPhoneVerificationView } from './views/onboarding/UserPhoneVerificationView';
import { EventsView } from './views/EventsView';
import { EventRegisterView } from './views/EventRegisterView';
import { MessagesView } from './views/MessagesView';
import { ReportsView } from './views/ReportsView';
import { EnrolmentsView } from './views/EnrolmentsView';
import { OrganisationProfileView } from './views/public/organisationView/OrganisationProfileView';
import { EnrolmentItemsView } from './views/EnrolmentItemsView';
import { LearnerEnrolmentView } from './views/public/organisationView/LearnerEnrolmentView';

export default function App() {
    const { getAccessTokenSilently } = useAuth0();
    const [loadingUser, setLoadingUser] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get('/users/_self')
                .then((res) => {
                    setUser(res.data);
                    setLoadingUser(false);
                });
        });
    }, [getAccessTokenSilently, setUser]);

    return (
        <Routes>
            <Route path="/" element={<ProtectedHomeLayout />}>
                {user && user.permissions && user.status === 'REGISTERED' && (
                    <>
                        {['GUARDIAN', 'PTA'].includes(user.userType) && <Route index element={<LearnersView />} />}

                        {user.permissions.canViewLearners && (
                            <>
                                <Route path="learners" element={<LearnersView />} />
                                <Route path="learners/:learnerId" element={<LearnerEventsView />} />
                            </>
                        )}

                        {user.permissions.canViewEvents && (
                            <>
                                {['ADMIN_INSTRUCTOR'].includes(user.userType) && (
                                    <Route index element={<EventsView user={user} />} />
                                )}
                                <Route path="events" element={<EventsView user={user} />} />
                            </>
                        )}

                        {user.permissions.canViewEventRegister && (
                            <>
                                <Route path="events/:eventId" element={<EventRegisterView user={user} />} />
                            </>
                        )}

                        {user.permissions.canViewStudentGroups && (
                            <>
                                <Route index element={<StudentGroupsView user={user} />} />
                                <Route path="student-groups" element={<StudentGroupsView user={user} />} />
                                <Route path="student-groups/:studentGroupId" element={<StudentsView user={user} />} />
                            </>
                        )}

                        {user.permissions.canReadMessages && (
                            <>
                                <Route path="messages" element={<MessagesView user={user} />} />
                            </>
                        )}

                        {user.permissions.canReadReports && (
                            <>
                                <Route path="reports" element={<ReportsView />} />
                            </>
                        )}

                        {user.permissions.canReadSettings && (
                            <>
                                <Route path="settings" element={<SettingsView />} />
                            </>
                        )}

                        {user.permissions.canReadEnrolments && user?.metadata?.hasOrganisationProfile && (
                            <>
                                <Route path="enrolments" element={<EnrolmentsView />} />
                                <Route path="enrolments/:enrolmentId" element={<EnrolmentItemsView />} />
                            </>
                        )}

                        <Route path="stripe-payment/:sessionId" element={<PaymentSuccessView />} />
                        <Route path="stripe-payment/unsuccessful" element={<PaymentFailedView />} />
                    </>
                )}

                {loadingUser ? <Route path="*" element={<Spinner />} /> : <Route path="*" element={<NotFoundView />} />}
            </Route>

            {/*Special case for a new user -> we need to gather more information from the user*/}
            {user && user.status !== 'REGISTERED' && (
                <>
                    <Route path="/" element={<FullScreenLayout />}>
                        {user.status === 'UNKNOWN' && <Route index element={<UserOnboardingView />} />}

                        {user.status === 'PENDING_EMAIL_VERIFICATION' && (
                            <Route index element={<UserEmailVerificationView userEmail={user.email} />} />
                        )}

                        {user.status === 'PENDING_PHONE_VERIFICATION' && (
                            <Route index element={<UserPhoneVerificationView userPhoneNumber={user.phoneNumber} />} />
                        )}
                    </Route>
                </>
            )}

            {/* WARNING!!! PUBLIC ROUTE!!! The below path DOES NOT REQUIRE AUTHENTICATION!!! */}
            <Route path="discover" element={<OrganisationDiscoveryHomeLayout />}>
                <Route index element={<OrganisationDiscoveryView />} />
                <Route path="org/:publicIdentifier" element={<OrganisationProfileView />} />
                <Route path="org/:publicIdentifier/e/:enrolmentId" element={<LearnerEnrolmentView />} />

                <Route path="*" element={<NotFoundView />} />
            </Route>

            <Route path="*" element={<NotFoundView />} />
        </Routes>
    );
}
