import React, { useState } from 'react';
import './eventRegister.css';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';
import { formatFromE164, formatPln } from '../../lib/utils';
import { Toggler } from '../common/Toggler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionGate } from '../common/PermissionGate';
import { UpdateInstructorNotes } from './UpdateInstructorNotes';
import { EventRegisterStatusBadge } from '../common/EventRegisterStatusBadge';

interface Props {
    event: any;
    eventRegisterItems: any;
    user: any;
    refreshViewCallback: () => void;
}

export const EventRegister = ({ event, eventRegisterItems, user, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [attendanceCheck, setAttendanceCheck] = useState(event.requiresAttendanceCheck);
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [pageState, setPageState] = useState('VIEW-EVENT-REGISTER');
    const [selectedEventRegister, setSelectedEventRegister] = useState(null);

    const getAmountSafely = (paidAmount) => {
        return paidAmount ? paidAmount.value : undefined;
    };

    const handleRemoveEventRegister = (eventRegisterId) => {
        getAccessTokenSilently().then((accessToken) =>
            apiClient(accessToken)
                .delete(`/event-register/${eventRegisterId}`)
                .then(() => refreshViewCallback()),
        );
    };

    const handleAttendanceChange = (eventRegisterId, attendance) => {
        getAccessTokenSilently().then((accessToken) => {
            const payload = {
                action: 'MARK_ATTENDANCE',
                attendanceType: attendance,
            };

            apiClient(accessToken)
                .patch(`/event-register/${eventRegisterId}`, payload)
                .then(() => refreshViewCallback());
        });
    };

    const handleMarkAsPaid = (event, eventRegisterId, status) => {
        getAccessTokenSilently().then((accessToken) => {
            const payload = {
                action: 'MARK_AS_PAID',
                status: status,
                amount: event.fee,
            };

            apiClient(accessToken)
                .patch(`/event-register/${eventRegisterId}`, payload)
                .then(() => refreshViewCallback());
        });
    };

    const handleMarkAsUnpaid = (eventRegisterId, action) => {
        getAccessTokenSilently().then((accessToken) => {
            const payload = { action: action };

            apiClient(accessToken)
                .patch(`/event-register/${eventRegisterId}`, payload)
                .then(() => refreshViewCallback());
        });
    };

    const attendanceIcon = (attendance) => {
        switch (attendance) {
            case 'PRESENT':
                return <FontAwesomeIcon icon="fa-solid fa-user" size="2x" />;
            case 'ABSENT':
                return <FontAwesomeIcon icon="fa-solid fa-user-slash" size="2x" />;
            default:
                return <FontAwesomeIcon icon="fa-solid fa-question-circle" size="2x" />;
        }
    };

    const displayPhoneNumber = (number) => {
        return number?.trim()?.length > 0 ? (
            formatFromE164(number)
        ) : (
            <div className="text-danger fst-italic">brak numeru</div>
        );
    };

    const hasTeacherNotes = (notes) => {
        return notes && notes.length > 0;
    };

    const refreshAfterInstructorNotesUpdate = () => {
        setPageState('VIEW-EVENT-REGISTER');
        refreshViewCallback();
    };

    return event && eventRegisterItems ? (
        <>
            <Toggler condition={pageState === 'MANAGE-INSTRUCTOR-NOTES' && !!selectedEventRegister}>
                <UpdateInstructorNotes
                    eventRegister={selectedEventRegister}
                    cancelCallback={() => setPageState('VIEW-EVENT-REGISTER')}
                    refreshViewCallback={refreshAfterInstructorNotesUpdate}
                />
            </Toggler>

            <Toggler condition={pageState === 'VIEW-EVENT-REGISTER'}>
                {/* SHOW MESSAGE IF NO STUDENTS */}
                <Toggler condition={eventRegisterItems.length === 0}>
                    <div className="parent">
                        <div className="child text-center display-6">Brak uczestników</div>
                        <div className="child text-center text-muted">
                            Możesz dodać uczestników przez naciśnięcie 'Dodaj studentów'
                        </div>
                    </div>
                </Toggler>

                <Toggler condition={eventRegisterItems.length !== 0}>
                    {/* TOGGLE FOR SHOWING PHONE NUMBER & ATTENDANCE CHECKING */}
                    <div className="d-flex flex-row justify-content-end gap-5 mt-3 mb-3">
                        {/* SWITCH */}
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input pointer"
                                type="checkbox"
                                role="switch"
                                id="phoneNumberSwitch"
                                onChange={() => setShowPhoneNumber(!showPhoneNumber)}
                                checked={showPhoneNumber}
                            />
                            <label className="form-check-label pointer" htmlFor="phoneNumberSwitch">
                                Pokaż numer telefonu
                            </label>
                        </div>

                        {/* SWITCH */}
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input pointer"
                                type="checkbox"
                                role="switch"
                                id="attendanceCheckSwitch"
                                onChange={() => setAttendanceCheck(!attendanceCheck)}
                                checked={attendanceCheck}
                            />
                            <label className="form-check-label pointer" htmlFor="attendanceCheckSwitch">
                                Obecność
                            </label>
                        </div>
                    </div>

                    {/* MAIN INFORMATION CARDS BELOW */}
                    <div className="container text-center cb-user-information-cards">
                        {eventRegisterItems.map((row, index) => (
                            <div key={row.id}>
                                <div className="row row-cols-2 cb-card-row">
                                    {/* COL 1 - MOST INFORMATION DISPLAYED HERE */}
                                    <div className="col-8 col-md-10 cb-user-cards">
                                        <div className="d-flex flex-row gap-4">
                                            <div>{index + 1}</div>
                                        </div>

                                        {/* SHOW STUDENT INFORMATION */}
                                        <div className="cb-user-name-card-item">
                                            <div className="fw-bold">{row.student.displayName}</div>

                                            {/* TOGGLED PHONE NUMBER */}
                                            <Toggler condition={showPhoneNumber}>
                                                <div className="fw-semibold">
                                                    {displayPhoneNumber(row.student.phoneNumber)}
                                                </div>
                                            </Toggler>

                                            <p className="card-text small text-muted">{row.studentGroup?.groupName}</p>
                                        </div>

                                        {/* SHOW NOTES WRITTEN BY THE INSTRUCTOR */}
                                        <div>
                                            <Toggler condition={hasTeacherNotes(row.instructorNotes)}>
                                                <FontAwesomeIcon icon="fa-regular fa-note-sticky" size="2x" />
                                            </Toggler>
                                        </div>

                                        {/* TOGGLED BASED ON THE POSITION OF THE SWITCH */}
                                        <Toggler condition={attendanceCheck}>
                                            <div className="cb-user-card-item flex-row gap-4">
                                                <PermissionGate requires={user.permissions.canUpdateEventRegister}>
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={() => handleAttendanceChange(row.id, 'PRESENT')}
                                                    >
                                                        Obecny
                                                    </button>
                                                    <button
                                                        className="btn btn-warning"
                                                        onClick={() => handleAttendanceChange(row.id, 'ABSENT')}
                                                    >
                                                        Nieobecny
                                                    </button>
                                                </PermissionGate>
                                            </div>

                                            <div className="cb-user-card-item">
                                                <div className="fw-bold">{attendanceIcon(row.attendanceType)}</div>
                                                <p className="card-text small text-muted"></p>
                                            </div>
                                        </Toggler>

                                        {/* TOGGLED BASED ON THE POSITION OF THE SWITCH */}
                                        <Toggler condition={!attendanceCheck}>
                                            <div className="cb-user-card-item">
                                                <div className="card-title fs-6">
                                                    {formatPln(getAmountSafely(row.feePaid), '-')}
                                                </div>
                                                <p className="card-text small text-muted">zapłata</p>
                                            </div>

                                            <div className="cb-user-card-item">
                                                <div className="card-title fs-6">
                                                    {formatPln(getAmountSafely(row.feeNet), '-')}
                                                </div>
                                                <p className="card-text small text-muted">netto</p>
                                            </div>
                                        </Toggler>
                                    </div>

                                    {/* COL 2 */}
                                    <div className="col-4 col-md-2 cb-fixed-cards">
                                        {/* PAYMENT STATUS BADGE */}
                                        <div className="text-center">
                                            <EventRegisterStatusBadge status={row.status} />
                                        </div>

                                        {/* DROPDOWN - ACTIONS BEING PERFORMED ON THE EVENT/EVENT-REGISTER */}
                                        <div className="dropdown">
                                            {/* DROPDOWN BUTTON - ... */}
                                            <PermissionGate requires={user.permissions.canUpdateEventRegister}>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <FontAwesomeIcon icon="fa-solid fa-ellipsis" />
                                                </button>
                                            </PermissionGate>

                                            {/* THE DROPDOWN MENU ITEMS  */}
                                            <ul className="dropdown-menu">
                                                {/* SHOW DROPDOWN MENU ITEMS IF THE EVENT CAN NO LONGER BE DELETED */}
                                                <Toggler condition={!row.metadata?.canDeleteEventRegister}>
                                                    {/* OPTION 1 */}
                                                    <li>
                                                        <div
                                                            className="dropdown-item"
                                                            onClick={() => handleMarkAsPaid(event, row.id, 'PAID_CASH')}
                                                        >
                                                            Płatność gotówką
                                                        </div>
                                                    </li>
                                                    {/* OPTION 2 */}
                                                    <li>
                                                        <div
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                                handleMarkAsPaid(event, row.id, 'PAID_BANK_TRANSFER')
                                                            }
                                                        >
                                                            Płatność przelewem
                                                        </div>
                                                    </li>
                                                    {/* OPTION 3 */}
                                                    <li>
                                                        <div
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                                handleMarkAsPaid(event, row.id, 'PAID_OTHER')
                                                            }
                                                        >
                                                            Płatność inna
                                                        </div>
                                                    </li>
                                                    {/* DIVIDER */}
                                                    <li>
                                                        <div className="dropdown-divider" />
                                                    </li>
                                                    {/* OPTION 4 */}
                                                    <li>
                                                        <div
                                                            className="dropdown-item"
                                                            onClick={() => handleMarkAsUnpaid(row.id, 'MARK_AS_UNPAID')}
                                                        >
                                                            Oczekuje płatność
                                                        </div>
                                                    </li>
                                                    {/* OPTION 5 */}
                                                    <li>
                                                        <div
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                                handleMarkAsUnpaid(row.id, 'MARK_AS_COMPLETED_UNPAID')
                                                            }
                                                        >
                                                            Odrzuć płatność
                                                        </div>
                                                    </li>

                                                    {/* DIVIDER */}
                                                    <li>
                                                        <div className="dropdown-divider" />
                                                    </li>
                                                </Toggler>

                                                {/* OPTION 6 */}
                                                <li>
                                                    <div
                                                        className="dropdown-item"
                                                        onClick={() => {
                                                            setSelectedEventRegister(row);
                                                            setPageState('MANAGE-INSTRUCTOR-NOTES');
                                                        }}
                                                    >
                                                        Edytuj notatkę
                                                    </div>
                                                </li>

                                                {/* OPTION 7 WITH DIVIDER */}
                                                <Toggler condition={row.metadata?.canDeleteEventRegister}>
                                                    <li>
                                                        <div className="dropdown-divider" />
                                                    </li>
                                                    <li>
                                                        <div
                                                            className="dropdown-item text-danger"
                                                            onClick={() => handleRemoveEventRegister(row.id)}
                                                        >
                                                            Wykreśl ucznia
                                                        </div>
                                                    </li>
                                                </Toggler>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="small text-muted text-center m-4">koniec danych</div>
                </Toggler>
            </Toggler>
        </>
    ) : (
        <div className="container mt-5 placeholder-glow">
            <div className="row m-2">
                <div className="col fs-1 placeholder"></div>
            </div>
            <div className="row m-2">
                <div className="col fs-1 placeholder"></div>
            </div>
            <div className="row m-2">
                <div className="col fs-1 placeholder"></div>
            </div>
        </div>
    );
};
