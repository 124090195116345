import * as React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './homeNavbar.css';
import {UserLoginControl} from "./login/UserLoginControl";

export const CourseDiscoveryNavbar = () => {
    return <>
        <nav className="home-navbar navbar sticky-top navbar-expand-sm bg-light col-span-full">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <FontAwesomeIcon icon="fa-solid fa-coins"/>
                </Link>
                <div className="d-flex">
                    <UserLoginControl promptLoginOnRender='false' />
                </div>
            </div>
        </nav>
    </>
}
