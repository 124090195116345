import React, {useCallback, useEffect, useState} from "react";
import "./studentsTable.css";
import _ from 'lodash';
import {Toggler} from "../common/Toggler";
import {useAuth0} from "@auth0/auth0-react";
import dayjs from "dayjs";
import {apiClient} from "../../apiClientV1";
import {formatDateOnly} from "../../lib/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DANGER_COLOUR, SECONDARY_COLOUR, SUCCESS_COLOUR} from "../../lib/constants";
import {StudentsPaymentEntryTable} from "./StudentsPaymentEntryTable";

interface Props {
    studentGroup: any;
    students: any;
    user: any;
    refreshViewCallback: any;
}

const PAID_ONLINE_ICON = <FontAwesomeIcon icon="fa-solid fa-coins" size="2x" style={{color: SUCCESS_COLOUR}}/>;
const PAID_CASH_ICON = <FontAwesomeIcon icon="fa-solid fa-money-bill-wave" size="2x" style={{color: SUCCESS_COLOUR}}/>;
const PAID_BANK_TRANSFER_ICON = <FontAwesomeIcon icon="fa-solid fa-money-bill-transfer" size="2x" style={{color: SUCCESS_COLOUR}}/>;
const PAID_OTHER_ICON = <FontAwesomeIcon icon="fa-solid fa-comments-dollar" size="2x" style={{color: SUCCESS_COLOUR}}/>;
const UNPAID_ICON = <FontAwesomeIcon icon="fa-solid fa-circle-xmark" size="2x" style={{color: DANGER_COLOUR}}/>;
const PAYMENT_NOT_REQUIRED_ICON = <FontAwesomeIcon icon="fa-solid fa-circle-minus" size="2x" style={{color: SECONDARY_COLOUR}}/>;

export const StudentsPaymentEntries = ({studentGroup, students, user, refreshViewCallback}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    
    const [events, setEvents] = useState();
    const [fromTime, setFromTime] = useState(dayjs().add(-1, "month").startOf("day"));
    
    const fetchEventData = useCallback((link) => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get(link.href)
                    .then(res => {
                        const resultEvents = res.data?.events || [];
                        const sortedEvents = _.orderBy(resultEvents, ['eventDate', 'eventName'], ['asc', 'asc']).slice(0, 3);
                        setEvents(sortedEvents);
                    });
            });
        }, [getAccessTokenSilently]);
    
    
    useEffect(() => {
        const link = {href: `/events?studentGroupId=${studentGroup.id}&from=${fromTime.toISOString()}&limit=3&sortBy=eventDate&sortDirection=ASC`};
        fetchEventData(link);
    }, [fetchEventData, fromTime, studentGroup.id]);
    
    return events ? <>
            <div className="d-flex gap-2 mb-3 align-items-center">
                <div className="">Od <b>{formatDateOnly(fromTime)}</b></div>
                <button className="btn btn-sm btn-primary" onClick={() => setFromTime(fromTime.add(-1, "week").startOf("day"))}>Tydzień wcześniej</button>
                <button className="btn btn-sm btn-primary" onClick={() => setFromTime(fromTime.add(1, "week").startOf("day"))}>Tydzień później</button>
            </div>

            <div className="d-flex flex-row gap-4 m-5">
                <span className="d-flex flex-column">
                    {PAID_ONLINE_ICON} Online
                </span>
                <span className="d-flex flex-column">
                    {PAID_CASH_ICON} Gotówka
                </span>
                <span className="d-flex flex-column">
                    {PAID_BANK_TRANSFER_ICON} Przelew
                </span>
                <span className="d-flex flex-column">
                    {PAID_OTHER_ICON} Inne
                </span>
                <span className="d-flex flex-column">
                    {PAYMENT_NOT_REQUIRED_ICON} Niezapłacono
                </span>
                <span className="d-flex flex-column">
                    {UNPAID_ICON} Oczekuje płatność
                </span>
            </div>

            <Toggler condition={students?.length > 0}>
                <StudentsPaymentEntryTable students={students} events={events} />
            </Toggler>

            <Toggler condition={students?.length === 0}>
                <div className="parent">
                    <div className="display-6 m-3">Brak studentów w tej grupie</div>
                    <div className="text-muted fs-5">Możesz ich dodać powyżej</div>
                </div>
            </Toggler>
        </>
        :
        //************ PLACEHOLDER ************
        <div className="placeholder-glow p-3 text-center" aria-hidden="true">
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
            <span className="placeholder col-3 m-1"></span>
        </div>
}
