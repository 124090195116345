import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';
import { InputSelectDropdown } from '../common/InputSelectDropdown';
import { EventFilterChip } from './EventFilterChip';
import { Toggler } from '../common/Toggler';

interface Props {
    refreshViewCallback: () => void;
}

const initialValues = () => {
    return {
        selectedStudentGroup: '',
        selectedInstructor: '',
        selectedStatus: '',
    };
};

export const EventFilters = ({ refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [showFilterInput, setShowFilterInput] = useState(false);
    const [eventsSummary, setEventsSummary] = useState();
    const [filters, setFilters] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [inputData] = useState(initialValues());
    const [errors] = useState(initialValues());

    useEffect(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get('/events-summary')
                .then((res) => setEventsSummary(res.data));
        });
    }, [getAccessTokenSilently]);

    function pushFilter(filter) {
        const updatedFilters = [...filters];
        updatedFilters.push(filter);

        setFilters(updatedFilters);
    }

    function applyFilters(filters) {
        refreshViewCallback(filters);
        showFilters(false);
        setAppliedFilters(filters);
    }

    function applyInstructorFilter(name, displayName, value) {
        if (name === 'selectedInstructor' && value !== '' && JSON.stringify(value) !== '{}') {
            const filter = {
                displayValue: displayName,
                filterDomain: 'EVENTS',
                filterBy: 'instructorId',
                value: value,
                filterType: 'EQUALITY',
            };

            if (!isFilterApplied(filter)) {
                pushFilter(filter);
            }
        }
    }

    function applyStudentGroupFilter(name, displayName, value) {
        if (name === 'selectedStudentGroup' && value !== '' && JSON.stringify(value) !== '{}') {
            const filter = {
                displayValue: displayName,
                filterDomain: 'EVENTS',
                filterBy: 'studentGroupId',
                value: value,
                filterType: 'EQUALITY',
            };

            if (!isFilterApplied(filter)) {
                pushFilter(filter);
            }
        }
    }

    function applyStatusFilter(name, displayName, value) {
        if (name === 'selectedStatus' && value !== '' && JSON.stringify(value) !== '{}') {
            const filter = {
                displayValue: displayName,
                filterDomain: 'EVENTS',
                filterBy: 'eventStatus',
                value: value,
                filterType: 'EQUALITY',
            };

            if (!isFilterApplied(filter)) {
                pushFilter(filter);
            }
        }
    }

    function isFilterApplied(filter) {
        const matchingFilters = filters.filter((f) => f.value === filter.value && f.filterBy === filter.filterBy);
        return matchingFilters.length > 0;
    }

    function handleInputChange(e) {
        const { name, value } = e.target;

        applyInstructorFilter(name, value.name, value.value);
        applyStudentGroupFilter(name, value.name, value.value);
        applyStatusFilter(name, translate(value.name), value.value);
    }

    function removeFilter(filter) {
        const updatedFilters = filters.filter((f) => f.value !== filter.value);
        setFilters(updatedFilters);
    }

    function removeAppliedFilter(filter) {
        const updatedFilters = appliedFilters.filter((f) => f.value !== filter.value);
        setFilters(updatedFilters);
        applyFilters(updatedFilters);
    }

    function showFilters(flag) {
        setShowFilterInput(flag);
    }

    function translate(status) {
        switch (status) {
            case 'DRAFT':
                return 'Wersja robocza';
            case 'IN_PROGRESS':
                return 'W trakcie';
            case 'COMPLETED':
                return 'Zakończone';
            case 'CANCELLED':
                return 'Anulowane';
            default:
                return 'nieznany';
        }
    }

    return (
        eventsSummary && (
            <>
                <div className="m-3">
                    <Toggler condition={showFilterInput}>
                        <button className="btn btn-sm btn-primary" onClick={() => showFilters(false)}>
                            Ukryj filtry
                        </button>
                    </Toggler>
                    <Toggler condition={!showFilterInput}>
                        <button className="btn btn-sm btn-outline-primary" onClick={() => showFilters(true)}>
                            Pokaż filtry
                        </button>
                    </Toggler>
                </div>

                <div>
                    <Toggler condition={showFilterInput}>
                        <div className="d-flex gap-3 m-3">
                            <InputSelectDropdown
                                optionValues={eventsSummary.studentGroupNames}
                                optionKeyFn={(op) => op.value}
                                optionLabelFn={(op) => op.name}
                                inputName="selectedStudentGroup"
                                inputHandler={handleInputChange}
                                inputData={inputData}
                                placeholder="Grupa/klasa"
                                errors={errors}
                            />

                            <InputSelectDropdown
                                optionValues={eventsSummary.instructorNames}
                                optionKeyFn={(op) => op.value}
                                optionLabelFn={(op) => op.name}
                                inputName="selectedInstructor"
                                inputHandler={handleInputChange}
                                inputData={inputData}
                                placeholder="Instruktor"
                                errors={errors}
                            />

                            <InputSelectDropdown
                                optionValues={eventsSummary.statuses}
                                optionKeyFn={(op) => op.value}
                                optionLabelFn={(op) => translate(op.name)}
                                inputName="selectedStatus"
                                inputHandler={handleInputChange}
                                inputData={inputData}
                                placeholder="Status"
                                errors={errors}
                            />
                        </div>

                        <div className="d-flex flex-column gap-2 m-3">
                            <div className="d-flex gap-2 flex-wrap">
                                {filters?.map((f, index) => (
                                    <div key={`${f.filterType}-${index}`}>
                                        <EventFilterChip
                                            filter={f}
                                            removeFilterCallback={removeFilter}
                                            btnStyle="btn-outline-secondary"
                                            closeStyle="btn-success"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="text-center m-3">
                            <button className="btn btn-success" onClick={() => applyFilters(filters)}>
                                Zastosuj wybrane filtry
                            </button>
                        </div>
                    </Toggler>
                </div>

                <Toggler condition={!showFilterInput}>
                    <div className="d-flex flex-column gap-2 m-3">
                        <div className="d-flex gap-2 flex-wrap">
                            {appliedFilters?.map((f, index) => (
                                <div key={`${f.filterType}-${index}`}>
                                    <EventFilterChip filter={f} removeFilterCallback={removeAppliedFilter} />
                                </div>
                            ))}
                        </div>
                    </div>
                </Toggler>
            </>
        )
    );
};
