import React, { useState } from 'react';
import { apiClient } from '../../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
    eventRegister: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const UpdateInstructorNotes = ({ eventRegister, cancelCallback, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [notes, setNotes] = useState(eventRegister.instructorNotes || '');

    const handleSaveInstructorNotes = (eventRegisterId) => {
        getAccessTokenSilently().then((accessToken) => {
            const payload = {
                action: 'UPDATE_INSTRUCTOR_NOTES',
                instructorNotes: notes,
            };

            apiClient(accessToken)
                .patch(`/event-register/${eventRegisterId}`, payload)
                .then(() => refreshViewCallback());
        });
    };

    return (
        eventRegister && (
            <>
                <div className="container text-center needs-validation">
                    <div className="row justify-content-center m-5">
                        <div className="col-10 col-md-8 col-lg-6 fw-bolder fs-2">
                            {eventRegister?.student?.displayName}
                        </div>
                        <div className="small text-muted">{eventRegister?.studentGroup?.displayName}</div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6">
                            <textarea
                                className="fw-light w-100"
                                placeholder="Wpisz notatkę..."
                                rows="4"
                                maxLength="250"
                                autoFocus={true}
                                wrap="soft"
                                onChange={(e) => setNotes(e.target.value)}
                                value={notes}
                            ></textarea>
                        </div>
                    </div>

                    <div className="d-grid gap-2 d-flex justify-content-center m-3">
                        {/************ CANCEL ************/}
                        <button
                            type="button"
                            className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}
                        >
                            Anuluj
                        </button>

                        {/************ CLEAR INPUT ************/}
                        <button
                            type="button"
                            className="form-floating btn btn-secondary m-2"
                            onClick={() => setNotes('')}
                        >
                            Wyczyść notatkę
                        </button>

                        {/************ SAVE INSTRUCTOR'S NOTE ON THE EVENT REGISTER ************/}
                        <button
                            type="button"
                            className="form-floating btn btn-primary m-2"
                            onClick={() => handleSaveInstructorNotes(eventRegister.id)}
                        >
                            Zapisz notatkę
                        </button>
                    </div>
                </div>
            </>
        )
    );
};
