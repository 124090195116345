import React, { useCallback, useEffect, useState } from 'react';
import './../common.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiClient } from '../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router';
import { EnrolmentItemCard } from '../components/enrolmentItems/EnrolmentItemCard';
import { Toggler } from '../components/common/Toggler';
import { ListEnd } from '../components/common/ListEnd';
import _ from 'lodash';

export const EnrolmentItemsView = () => {
    const { enrolmentId } = useParams();

    const { getAccessTokenSilently } = useAuth0();
    const [pageStatus] = useState('VIEW');
    const [enrolment, setEnrolment] = useState();
    const [enrolmentItems, setEnrolmentItems] = useState();
    const [studentGroups, setStudentGroups] = useState();

    const fetchData = useCallback(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get(`/enrolments/${enrolmentId}`)
                .then((res) => setEnrolment(res?.data));

            apiClient(accessToken)
                .get(`/enrolment-items?enrolmentId=${enrolmentId}`)
                .then((res) => setEnrolmentItems(res?.data?.enrolmentItems));

            apiClient(accessToken)
                .get('/student-groups')
                .then((res) => setStudentGroups(res?.data));
        });
    }, [enrolmentId, getAccessTokenSilently]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refreshData = () => {
        fetchData();
    };

    const sorted = (items) => {
        if (!items) return [];

        const firstNameSorter = (student) => student.studentFirstName.toLowerCase();
        const lastNameSorter = (student) => student.studentLastName.toLowerCase();

        return _.orderBy(items, [lastNameSorter, firstNameSorter], ['asc', 'asc']);
    };

    return enrolment && enrolmentItems ? (
        <>
            {/************ VIEWING ENROLMENTS ************/}
            {pageStatus === 'VIEW' && (
                <>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/">
                                    <FontAwesomeIcon icon="fa-solid fa-home" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/enrolments">Zapisy</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {enrolment.enrolmentName}
                            </li>
                        </ol>
                    </nav>

                    <div className="d-flex flex-column align-items-center">
                        <div className="col-11 col-sm-10 col-md-9 col-lg-8">
                            <Toggler condition={enrolmentItems?.length > 0}>
                                {sorted(enrolmentItems).map((item, index) => (
                                    <div key={item.id}>
                                        {/* CARD */}
                                        <EnrolmentItemCard
                                            enrolmentItem={item}
                                            index={index}
                                            studentGroups={studentGroups}
                                            refreshViewCallback={refreshData}
                                        />

                                        {/* SEPARATOR */}
                                        <Toggler condition={index < enrolmentItems.length - 1}>
                                            <div className="text-center">::</div>
                                        </Toggler>
                                    </div>
                                ))}

                                {/* END */}
                                <ListEnd content="koniec danych" />
                            </Toggler>

                            <Toggler condition={enrolmentItems?.length === 0}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center display-6 mt-5 mb-5">Brak zapisów</div>
                                    </div>
                                </div>
                            </Toggler>
                        </div>
                    </div>
                </>
            )}
        </>
    ) : (
        // ************ PLACEHOLDER ************
        <>
            <div className="placeholder-glow" aria-hidden="true">
                <span className="placeholder col-1"></span>
            </div>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-end m-3 placeholder-glow" aria-hidden="true">
                <span className="btn btn-primary disabled placeholder col-1"></span>
            </div>
            <div className="placeholder-glow p-3" aria-hidden="true">
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
                <span className="placeholder col-3 m-1"></span>
            </div>
        </>
    );
};
