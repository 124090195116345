import React, { useState } from 'react';
import { apiClient } from '../../../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import Spinner from '../../common/Spinner';

interface Props {
    event: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

const initialValues = (event) => {
    return {
        id: event.id,
        eventName: event.eventName,
        description: event.description,
        eventDate: dayjs(event.eventDate).format('YYYY-MM-DDTHH:mm'),
        durationInMin: event.durationInMin,
        requiresAttendanceCheck: event.requiresAttendanceCheck,
    };
};

export const EditEvent = ({ event, cancelCallback, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [pageState, setPageState] = useState('EDIT');
    const [inputValues, setInputValues] = useState(initialValues(event));
    const [errors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };

    const handleSubmit = (updatedEvent) => {
        setPageState('LOADING');

        getAccessTokenSilently().then((accessToken) => {
            const updateEventRequest = {
                ...updatedEvent,
                action: 'UPDATE_EVENT',
                durationInMin: Math.max(0, updatedEvent.durationInMin),
                eventDate: dayjs(updatedEvent.eventDate, 'YYYY-MM-DDTHH:mm'),
            };

            apiClient(accessToken)
                .patch(`/events/${updatedEvent.id}`, updateEventRequest)
                .finally(() => refreshViewCallback());
        });
    };

    const inputField = (name, placeholder, val, type) => {
        return (
            <div className="form-floating mb-3">
                <input
                    type={type}
                    className={`form-control ${errors[name] && 'is-invalid'}`}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={val}
                    onChange={handleInputChange}
                />
                <label htmlFor="floatingInput">{placeholder}</label>
            </div>
        );
    };

    const toEvent = (name, flag) => {
        return { target: { name: name, value: flag } };
    };

    return (
        event && (
            <>
                <div className="container text-center needs-validation">
                    <div className="row justify-content-center m-5">
                        <div className="col-9 col-sm-6 col-md-4 col-lg-3 fw-bolder fs-5">Edytuj dane</div>
                    </div>

                    {pageState === 'LOADING' && (
                        <>
                            <div className="d-flex flex-column-reverse align-items-center">
                                <Spinner />
                                <p className="small text-muted">Proszę czekać</p>
                            </div>
                        </>
                    )}

                    {pageState === 'EDIT' && (
                        <>
                            <div className="row justify-content-center">
                                <div className="col-9 col-sm-6 col-md-5">
                                    {inputField('eventName', 'tytuł', inputValues.eventName, 'text')}
                                    {inputField('eventDate', 'data & czas', inputValues.eventDate, 'datetime-local')}
                                    {inputField(
                                        'durationInMin',
                                        'długość (minuty)',
                                        inputValues.durationInMin,
                                        'number',
                                    )}

                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input pointer"
                                            type="checkbox"
                                            role="switch"
                                            id="requiresAttendanceCheckSwitch"
                                            name="requiresAttendanceCheck"
                                            onChange={() =>
                                                handleInputChange(
                                                    toEvent(
                                                        'requiresAttendanceCheck',
                                                        !inputValues.requiresAttendanceCheck,
                                                    ),
                                                )
                                            }
                                            checked={inputValues.requiresAttendanceCheck}
                                        />
                                        <label
                                            className="form-check-label pointer fa-pull-left"
                                            htmlFor="requiresAttendanceCheckSwitch"
                                        >
                                            Sprawdzaj obecność
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="d-grid gap-2 d-flex justify-content-center m-3">
                                {/************ CANCEL ************/}
                                <button
                                    type="button"
                                    className="form-floating btn btn-outline-secondary m-2"
                                    onClick={cancelCallback}
                                >
                                    Anuluj
                                </button>

                                {/************ CONTINUE ************/}
                                <button
                                    type="button"
                                    className="form-floating btn btn-primary m-2"
                                    onClick={() => handleSubmit(inputValues)}
                                >
                                    Zapisz
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </>
        )
    );
};
