import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../apiClientV1";
import {LearnerEvents} from "../components/learners/LearnerEvents";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Toggler} from "../components/common/Toggler";
import {LearnerPhoneNumberAssignCard} from "../components/learners/LearnerPhoneNumberAssignCard";
import {formatFromE164} from "../lib/utils";
import Spinner from "../components/common/Spinner";

export const LearnerEventsView = () => {
    const {learnerId} = useParams();

    const {getAccessTokenSilently} = useAuth0();
    const [learner, setLearner] = useState(null);

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get(`/learners/${learnerId}`)
                    .then(res => setLearner(res.data));
            });
    }, [learnerId, getAccessTokenSilently]);

    useEffect(() => {
        fetchData();
    }, [learnerId, fetchData, getAccessTokenSilently]);

    const toDisplayName = (learner) => {
        const firstAndLastName = `${learner.firstName} ${learner.lastName}`;

        return firstAndLastName !== learner.displayName
            ? `${firstAndLastName} (${learner.displayName})`
            : firstAndLastName;
    }

    return learner ? <>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item" aria-current="page">
                    <Link to="/"><FontAwesomeIcon icon="fa-solid fa-home"/></Link>
                </li>
                <li className="breadcrumb-item" aria-current="page"><Link to="/learners">Uczniowie</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{learner.displayName}</li>
            </ol>
        </nav>

        <div className="fs-1 fw-semibold">{toDisplayName(learner)}</div>
        <div className="text-muted">Numer kontaktowy: {formatFromE164(learner.phoneNumber, 'brak')}</div>

        <Toggler condition={learner.status === 'REQUIRES_PHONE_NUMBER'}>
            <LearnerPhoneNumberAssignCard learner={learner} refreshViewCallback={fetchData}/>
        </Toggler>

        <Toggler condition={learner.status !== 'REQUIRES_PHONE_NUMBER' && learner.status !== 'UNKNOWN'}>
            <LearnerEvents learner={learner}/>
        </Toggler>
    </>
    :
    <div className="parent">
        <Spinner />
    </div>
}
