import React from "react";

interface Props {
    link: string;
    text?: string;
    openNewTab: boolean;
}

export const Href = ({link, text, openNewTab}: Props) => {
    const target = openNewTab
        ? '_blank'
        : '_self';

    const url = link?.startsWith('https://')
        ? link
        : `https://${link}`;

    const label = text
        ? text
        : url;

    return (
        <a href={url} target={target} rel="noreferrer">{label}</a>
    )
}