import React from 'react';
import ReactModal from 'react-modal';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const CONFIRMATION_TEXT = 'Potwierdzenie';

interface Props {
    showModal: boolean;
    onClose: () => void;
    onConfirm: () => void;
    confirmLabel?: string;
    cancelLabel?: string;
    question?: string;
    children?: any;
}

export const ConfirmationModal = ({
    showModal,
    onClose,
    onConfirm,
    confirmLabel = 'Potwierdź',
    cancelLabel = 'Anuluj',
    question,
    children,
}: Props) => {
    Modal.setAppElement('#root');

    return (
        <ReactModal isOpen={showModal} onRequestClose={onClose} contentLabel="tests" style={customStyles}>
            <div className="d-flex flex-column">
                <div className="fs-2 fw-bold m-3 text-muted">{CONFIRMATION_TEXT}</div>

                {question && <p className="m-3 text-danger fw-bold fs-5">{question}</p>}

                {children && <div className="m-3">{children}</div>}

                <div className="d-flex gap-2 mt-4 justify-content-around">
                    <button className="btn btn-outline-secondary" onClick={onClose}>
                        {cancelLabel}
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                    >
                        {confirmLabel}
                    </button>
                </div>
            </div>
        </ReactModal>
    );
};
