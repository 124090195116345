import React from 'react';
import { formatPln } from '../../lib/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';

interface Props {
    eventRegisterItem: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const LearnerRejectPaymentCard = ({ eventRegisterItem, cancelCallback, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const handleRejectPayment = (eventRegisterItem) => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .patch(`/event-register/${eventRegisterItem.id}`, {
                    action: 'WONT_PAY_FOR_EVENT',
                })
                .then(() => refreshViewCallback());
        });
    };

    return (
        eventRegisterItem && (
            <>
                <div className="container text-center">
                    <div className="row justify-content-center mt-5">
                        <div className="fw-bolder fs-5">Czy napewno chcesz odrzucić płatność?</div>

                        <div className="col-12 col-sm-9 col-md-8 col-lg-6 mt-3">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <h5 className="card-title">{eventRegisterItem.event.eventName}</h5>
                                    <p className="card-text">{eventRegisterItem.event.description}</p>
                                    <p className="fs-3">{formatPln(eventRegisterItem.fee?.value)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-grid gap-2 d-flex justify-content-center m-3">
                        {/************ CANCEL ************/}
                        <button
                            type="button"
                            className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}
                        >
                            Anuluj
                        </button>

                        {/************ REJECT PAYMENT ************/}
                        <button
                            type="button"
                            className="form-floating btn btn-danger m-2"
                            onClick={() => handleRejectPayment(eventRegisterItem)}
                        >
                            Odrzuć płatność
                        </button>
                    </div>
                </div>
            </>
        )
    );
};
