import * as React from "react";

interface Props {
    requires: boolean;
    children: any;
}

export const PermissionGate = ({children, requires}: Props) => {
    return requires === true && <>{children}</>
};
