import React from "react";
import {formatDate, formatPln, navigateToExternalUrl} from "../../lib/utils";
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../apiClientV1";
import {Toggler} from "../common/Toggler";

interface Props {
    eventRegisterItem: any;
    cancelCallback: () => void;
}

export const LearnerConfirmPaymentCard = ({eventRegisterItem, cancelCallback}: Props) => {
    const {isAuthenticated, getAccessTokenWithPopup} = useAuth0();

    const handleAttemptingPayment = (eventRegisterItem) => {
        getAccessTokenWithPopup()
            .then(accessToken => {
                const payload = {
                    eventRegisterId: eventRegisterItem.id
                };

                apiClient(accessToken).post('/stripe/payment-sessions', payload)
                    .then(res => navigateToExternalUrl(res.data.url));
            });
    }

    return <>
        <Toggler condition={!!eventRegisterItem && isAuthenticated}>
            <div className="container text-center">
                <div className="row justify-content-center mt-5">
                    <div className="fw-bolder fs-5">Opłacasz zajęcia</div>

                    <div className="col-12 col-sm-9 col-md-8 col-lg-6 mt-3">
                        <div className="card bg-light">
                            <div className="card-body">
                                <h5 className="card-title">{eventRegisterItem.event.eventName}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    data: {formatDate(eventRegisterItem.event.eventDate)}</h6>
                                <p className="card-text">{eventRegisterItem.event.description}</p>
                                <p className="fs-1">{formatPln(eventRegisterItem.fee.value)}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="text-warning mt-2">Po naciśnięciu 'Opłać zajęcia' otworzy się witryna płatności</p>

                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                    {/************ CANCEL ************/}
                    <button type="button" className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}>Anuluj
                    </button>

                    {/************ PAY EVENT ************/}
                    <button type="button" className="form-floating btn btn-primary m-2"
                            onClick={() => handleAttemptingPayment(eventRegisterItem)}>Opłać zajęcia
                    </button>
                </div>
            </div>
        </Toggler>
    </>
}
