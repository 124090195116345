import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import {SPCard} from "../common/SPCard";

export const AccountPaymentStatus = () => {
    const {getAccessTokenSilently} = useAuth0();
    const [stripeAccountStatus, setStripeAccountStatus] = useState(null)

    useEffect(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/stripe/account-status')
                    .then(res => setStripeAccountStatus(res.data)
                    );
            });
    }, [getAccessTokenSilently])

    return stripeAccountStatus ?
        <div>
            {stripeAccountStatus.hasChargesEnabled ?
                <>
                    <SPCard body={<div className="d-flex flex-column align-items-center">
                        <h3><span className="badge text-bg-success">Bezpiecznie zapisane</span></h3>
                        <span className="small text-muted">Status</span>
                    </div>}/>
                </>
                :
                <>
                    <SPCard body={<div className="d-flex flex-column align-items-center">
                        <h3><span className="badge text-bg-warning">Brak danych bankowych</span></h3>
                        <span className="small text-muted">Status</span>
                    </div>}/>
                </>}
        </div>
        :
        <SPCard body={<div className="placeholder-glow mb-4" aria-hidden="true">
            <div className="d-flex flex-column align-items-center gap-2">
                <span className="placeholder bg-secondary col-md-6 fs-1"></span>
                <span className="placeholder bg-secondary col-md-2"></span>
            </div>
        </div>}/>
}
