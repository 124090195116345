import React from "react";
import dayjs from "dayjs";

interface Props {
    event: any;
}

export const EventTime = ({event}: Props) => {
    const dayjsDateFrom = dayjs(new Date(event?.eventDate));
    const dayjsDateTo = dayjsDateFrom.add(event?.durationInMin, 'minute');

    const displayTime = (date) => {
        const hours = date.format('HH');
        const minutes = date.format('mm');

        return `${hours}:${minutes}`;
    }

    return <>{displayTime(dayjsDateFrom)} - {displayTime(dayjsDateTo)}</>
}