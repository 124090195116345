import React from "react";

export const OrganisationDiscoveryView = () => {
    return <>
        <div className="container mt-5">
            <div className="row fs-2 justify-content-center">
                Już wkrótce będzie możliwość szukania różnych zajęć/kursów na naszej stronie.
            </div>
        </div>
    </>
}
