import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { apiClient } from "../apiClientV1";
import { Outlet } from "react-router-dom";
import { ProtectedHomeNavbar } from "../components/ProtectedHomeNavbar";
import { OrganisationSwitcher } from "../components/OrganisationSwitcher";
import "./homeLayout.css";
import Spinner from "../components/common/Spinner";

export const ProtectedHomeLayout = () => {
    const {
        getAccessTokenSilently,
        loginWithRedirect,
        isAuthenticated,
        handleRedirectCallback,
    } = useAuth0();
    const [user, setUser] = useState(null);
    const isLoggedIn = useRef(false);
    const isAuthInProgress = useRef(false);
    const counter = useRef(0);

    useEffect(() => {
        if (!isAuthInProgress.current || isLoggedIn.current) {
            counter.current = counter.current + 1;

            getAccessTokenSilently()
                .then((accessToken) => {
                    handleRedirectCallback();

                    isAuthInProgress.current = false;
                    isLoggedIn.current = true;

                    apiClient(accessToken)
                        .get("/users/_self")
                        .then((res) => {
                            setUser(res.data);
                        });
                })
                .catch(() => {
                    if (!isAuthInProgress.current) {
                        isAuthInProgress.current = true;

                        loginWithRedirect();
                    }
                });
        }
    }, [
        getAccessTokenSilently,
        loginWithRedirect,
        setUser,
        handleRedirectCallback,
        isAuthenticated,
    ]);

    return user ? (
        <>
            <div className="layout-container">
                <ProtectedHomeNavbar
                    userPermissions={user.permissions}
                    userName={user.displayName}
                    userMetadata={user.metadata}
                />

                <OrganisationSwitcher user={user} />

                {/* An <Outlet> renders whatever child route is currently active,
                so you can think about this <Outlet> as a placeholder for
                the child routes we defined above. */}
                <div className="outlet">
                    <Outlet />
                </div>
            </div>
        </>
    ) : (
        <>
            <div className="box-container">
                <div className="box">
                    <Spinner />
                </div>
            </div>
        </>
    );
};
