import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {useState} from "react";
import {refreshPage} from "../../lib/utils";
import {Toggler} from "../../components/common/Toggler";
import {Email} from "../../components/common/Email";

interface Props {
    userEmail: string;
}

export const UserEmailVerificationView = ({userEmail}: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [verificationInProgress, setVerificationInProgress] = useState(false);

    const handleEmailVerification = () => {
        setVerificationInProgress(true);

        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    action: 'VERIFY_EMAIL'
                };

                apiClient(accessToken).patch('/users/_self/verification', payload)
                    .then(() => {
                        // NB. THIS SHOULD BE USED CAREFULLY, ONLY IN SPECIAL CIRCUMSTANCES
                        // It is OK to use in this case as we want to refresh the entire page
                        // to reflect the user having their email verified
                        refreshPage();
                    })
                    .finally(() => setVerificationInProgress(false));
            });
    }

    return <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
                <div className="alert alert-warning text-center">
                    <div className="fs-2">Prosimy o weryfikację Twojego e-maila</div>
                    <hr/>
                    <Toggler condition={!!userEmail}>
                        <div className="fs-5">{userEmail}</div>
                        <hr/>
                    </Toggler>
                    <div>
                        Sprawdź skrzynkę odbiorczą (i zawartość folderu spam). Został do Ciebie
                        wysłany e-mail - należy otworzyć link w nim podany w celu weryfikacji Twojego e-maila.
                    </div>
                </div>

                <div className="text-center">
                    <button className="btn btn-primary m-4"
                            onClick={handleEmailVerification}
                            disabled={verificationInProgress}>
                        E-mail zweryfikowany, przejdź dalej
                    </button>
                    <p className="small text-muted">
                        Jeśli nadal masz problemy z weryfikacją, napisz do nas <Email />.</p>
                </div>
            </div>
        </div>
    </div>
}
