import {useCallback, useEffect, useState} from "react";
import {formatPln} from "../../lib/utils";
import Spinner from "../common/Spinner";
import '../../common.css';
import {useAuth0} from "@auth0/auth0-react";
import Chart from 'react-apexcharts';
import {apiClient} from "../../apiClientV1";
import dayjs from "dayjs";
import _ from 'lodash';

const initialChartState = {
    options: {
        labels: [],
        plotOptions: {
            pie: {donut: {size: '50%'}}
        }
    },
    series: []
};

export const CostBreakdownReport = () => {
    const {getAccessTokenSilently} = useAuth0();

    const [chartState, setChartState] = useState(initialChartState);
    const [costData, setCostData] = useState(null);
    const monthAgo = dayjs().add(-1, 'month').startOf("day");
    const firstOfTheMonth = dayjs().startOf("month").startOf("day");
    const endOfDayToday = dayjs().endOf("day");

    const [startDate, setStartDate] = useState(monthAgo);
    const [endDate] = useState(endOfDayToday);

    const convertName = (name) => {
        switch (name) {
            case 'SMS':
                return 'SMS wiadomości';
            case 'SUBSCRIPTION':
                return 'Subskrypcja';
            case 'TRANSACTION':
                return 'Bramka płatności';
            default:
                return 'inne';
        }
    }

    const formatDate = (date) => {
        return date.format("YYYY-MM-DD");
    }

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                const fromDate = startDate.toISOString();
                const toDate = endDate.toISOString();

                apiClient(accessToken).get(`/reports/cost-breakdown?from=${fromDate}&to=${toDate}`)
                    .then(res => {
                        const items = res.data?.costBreakdown.map(item => {
                            return {
                                name: item.name,
                                cost: item.cost.value
                            }
                        });

                        const data = {
                            totalCost: res.data?.totalCost,
                            costBreakdown: _.orderBy(items, ['cost', 'name'], ['desc', 'asc'])
                        };

                        // ApexChart data
                        const optionLabels = data.costBreakdown.map(item => convertName(item.name));
                        const chartSeries = data.costBreakdown.map(item => item.cost / 100);

                        const chartData = {
                            options: {
                                labels: optionLabels,
                                plotOptions: initialChartState.options.plotOptions
                            },
                            series: chartSeries
                        };

                        setChartState(chartData);
                        setCostData(data);
                    });
            });
    }, [endDate, getAccessTokenSilently, startDate]);

    const onStartDateChanged = (newStartDate) => {
        // setting costBreakdown to null as this causes to nicely reload the chart
        setCostData(null);
        setStartDate(newStartDate);
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <div className="d-flex flex-column align-items-center">
        <div className="fs-1">Koszty</div>

        {/* DATE RANGE */}
        <div>{formatDate(startDate)} :: {formatDate(endDate)}</div>

        {/* BUTTONS */}
        <div className="d-flex gap-2 mt-3 mb-3">
            <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(monthAgo)}>
                Miesiąc temu
            </button>
            <button className="btn btn-sm btn-secondary" onClick={() => onStartDateChanged(firstOfTheMonth)}>
                Początek miesiąca
            </button>
        </div>

        <div className="d-flex flex-row justify-content-center align-items-center gap-5 mt-5">
            {costData && <>
                {costData.totalCost?.value > 0 && <div className="donut">
                    <Chart options={chartState.options} series={chartState.series} type="donut" width="400"/>
                </div>}

                {/* CARDS */}
                <div className="d-flex flex-column">
                    {/* CARD */}
                    {costData.costBreakdown.map(item => (
                        <p key={item.name}>
                            {convertName(item.name)}: {formatPln(item.cost)}
                        </p>))}

                    <hr/>
                    
                    <span>Suma: <div className="fs-3 fw-bolder">{formatPln(costData.totalCost?.value)}</div></span>
                    
                    <hr/>
                    
                    <small className="text-muted">*Nie wszystkie koszty mogą być pokazane powyżej</small>
                </div>
            </>}

            {/* SPINNER */}
            {!costData && <div className="mt-5">
                <Spinner/>
            </div>}
        </div>

    </div>
}
