import React, {useState} from "react";
import {Toggler} from "../../common/Toggler";

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    nextStepCallback: () => void;
    previousStepCallback: () => void;
}

export const CollectEventPricingTypes = ({
                                        cancelCallback,
                                        inputValues,
                                        inputHandler,
                                        previousStepCallback,
                                        nextStepCallback
                                    }: Props) => {
    const [errors, setErrors] = useState({});

    const validateInputAndCallNextStep = () => {
        let validationResult = validateInput(inputValues);
        setErrors(validationResult.errors);

        if (validationResult.isValid) {
            nextStepCallback();
        }
    }

    // this is a workaround to set the correct boolean value
    const toEvent = (name, flag) => {
        return {target: {name: name, value: flag}}
    }
    
    const inputField = (name, placeholder, val, type) => {
        return <div className="form-floating mb-3">
            <input type={type} className={`form-control ${errors[name] && 'is-invalid'}`}
                id={name}
                name={name}
                placeholder={placeholder}
                value={val}
                onChange={inputHandler}/>
            <label htmlFor="floatingInput">{placeholder}</label>
            
            {/************ DISPLAY ERROR BELOW ************/}
            {errors[name] &&
                <span id={name + 'Id'} className="invalid-feedback">{errors[name]}</span>}
        </div>
    }

    return <>
        <div className="fs-5 fw-light">Płatność 2/2</div>
    
        {/************ EVENT PAYMENT TYPES ************/}
        <Toggler condition={!!errors.acceptPaymentError}>
            <small className="text-danger">{errors.acceptPaymentError}</small>
        </Toggler>
    
        <div className="form-check form-switch">
            <input className="form-check-input pointer"
                type="checkbox"
                role="switch"
                id="acceptOnlinePaymentSwitch"
                name="acceptOnlinePayment"
                onChange={() => inputHandler(toEvent('acceptOnlinePayment', !inputValues.acceptOnlinePayment))}
                checked={inputValues.acceptOnlinePayment}
            />
            <label className="form-check-label pointer" htmlFor="acceptOnlinePaymentSwitch">
                Online (BLIK, karta)
            </label>
        </div>
     
        {/* DISABLED FOR NOW */}
        <Toggler condition={false}>
            <div className="form-check form-switch">
                <input className="form-check-input pointer"
                    type="checkbox"
                    role="switch"
                    disabled={!inputValues.acceptOnlinePayment}
                    id="transactionFeePayableByGuardianSwitch"
                    name="transactionFeePayableByGuardian"
                    onChange={() => inputHandler(toEvent('transactionFeePayableByGuardian', !inputValues.transactionFeePayableByGuardian))}
                    checked={inputValues.transactionFeePayableByGuardian}
                />
                <label className="form-check-label pointer" htmlFor="transactionFeePayableByGuardianSwitch">
                    Koszt transakcji pokrywa rodzic/opiekun
                </label>
            </div>
        </Toggler>
    
        <div className="form-check form-switch">
            <input className="form-check-input pointer"
                type="checkbox"
                role="switch"
                id="acceptCashPaymentSwitch"
                name="acceptCashPayment"
                onChange={() => inputHandler(toEvent('acceptCashPayment', !inputValues.acceptCashPayment))}
                checked={inputValues.acceptCashPayment}
            />
            <label className="form-check-label pointer" htmlFor="acceptCashPaymentSwitch">
                Gotówka
            </label>
        </div>
    
        <div className="form-check form-switch">
            <input className="form-check-input pointer"
                type="checkbox"
                role="switch"
                id="acceptTransferPaymentSwitch"
                name="acceptTransferPayment"
                onChange={() => inputHandler(toEvent('acceptTransferPayment', !inputValues.acceptTransferPayment))}
                checked={inputValues.acceptTransferPayment}
            />
            <label className="form-check-label pointer" htmlFor="acceptTransferPaymentSwitch">
                Przelew/Transfer bankowy
            </label>
        </div>
    
        <Toggler condition={inputValues.acceptTransferPayment}>
            {inputField('bankAccountNumberForPayment', 'Numer konta', inputValues.bankAccountNumberForPayment, 'text')}
        </Toggler>
            
        <hr/>
        
        <div className="form-floating">
            <select className="form-select"
                id="preferredPaymentTypeSelect"
                name="preferredPaymentType"
                value={inputValues.preferredPaymentType}
                onChange={inputHandler}>
                <option value="UNKNOWN">brak preferencji</option>
                {inputValues.acceptOnlinePayment && <option value="ONLINE">Online</option>}
                {inputValues.acceptCashPayment && <option value="CASH">Gotówka</option>}
                {inputValues.acceptTransferPayment && <option value="TRANSFER">Przelew</option>}
            </select>
            <label htmlFor="studentGroupIdSelect">Wybierz preferencyją metodę</label>
        </div>
    
        <hr/>
    
        <div className="d-grid gap-2 d-flex justify-content-center">
            {/************ BACK ************/}
            <button type="button" className="form-floating btn btn-secondary m-2"
                    onClick={() => previousStepCallback()}>Cofnij
            </button>

            {/************ CONTINUE ************/}
            <button type="button" className="form-floating btn btn-primary m-2"
                    onClick={validateInputAndCallNextStep}>Zapisz i dodaj zajęcia
            </button>
        </div>
    </>
}

const validateInput = (input) => {
    let validationResult = {isValid: true, errors: {}};

    if (!input.acceptOnlinePayment && !input.acceptCashPayment && !input.acceptTransferPayment) {
        validationResult.errors.acceptPaymentError = 'Proszę wybrać przynajmniej jedną opcję płatności';
        validationResult.isValid = false;
    }
    
    if (input.acceptTransferPayment && input.bankAccountNumberForPayment.trim() === '') {
        validationResult.errors.bankAccountNumberForPayment = 'Proszę podać numer konta';
        validationResult.isValid = false;
    }

    return validationResult;
}