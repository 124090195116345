import React from 'react';

interface Props {
    inputType?: string;
    inputValues: any;
    inputName: string;
    inputHandler: (e) => void;
    placeholder: string;
    errors: any;
}

export const InputTextArea = ({ inputValues, inputName, inputHandler, placeholder, errors }: Props) => {
    return (
        <div className="form-floating">
            <textarea
                id={`input-text-for-${inputName}`}
                name={inputName}
                style={{ height: '100px' }}
                className={'form-control fw-light w-100 ' + (errors[inputName] && 'is-invalid')}
                placeholder={placeholder}
                maxLength="1024"
                wrap="soft"
                onChange={inputHandler}
                value={inputValues[inputName]}
            ></textarea>

            <label htmlFor={`input-text-for-${inputName}`}>{placeholder}</label>

            {/************ DISPLAY ERROR BELOW ************/}
            {errors[inputName] && (
                <span id={`${inputName}-error`} className="invalid-feedback">
                    {errors[inputName]}
                </span>
            )}
        </div>
    );
};
