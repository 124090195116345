import * as React from "react";
import {Link} from "react-router-dom";
import {UserLoginControl} from "./login/UserLoginControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './genericNavbar.css';

export const GenericNavbar = () => {
    return (
        <nav className="home-navbar navbar sticky-top navbar-expand-sm bg-light col-span-full">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <FontAwesomeIcon icon="fa-solid fa-coins"/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarToggler" aria-controls="navbarToggler"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarToggler">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-4">
                    </ul>
                    <div className="d-flex m-3">
                        <UserLoginControl promptLoginOnRender='true' />
                    </div>
                </div>
            </div>
        </nav>
    )
}
