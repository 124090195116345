import React, { useCallback, useEffect, useState } from 'react';
import { apiClient } from '../../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaymentSettings } from '../../components/settings/PaymentSettings';
import { SubscriptionDetails } from '../../components/settings/SubscriptionDetails';
import { SubscriptionInvoicesDetails } from '../../components/settings/SubscriptionInvoicesDetails';
import { OrganisationProfileSettings } from '../../components/settings/OrganisationProfileSettings';
import { ListEnd } from '../../components/common/ListEnd';
import { SPToastContainer } from '../../components/common/SPToastContainer';

export const SettingsView = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [user, setUser] = useState(null);

    const fetchUser = useCallback(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get('/users/_self')
                .then((res) => setUser(res.data));
        });
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" aria-current="page">
                        <Link to="/">
                            <FontAwesomeIcon icon="fa-solid fa-home" />
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Ustawienia
                    </li>
                </ol>
            </nav>

            <h1>Ustawienia</h1>

            <p className="small text-muted">Jeżeli masz uprawnienia, poniżej mogą znaleźć się dostępne ustawienia</p>

            <hr />

            <div className="d-flex flex-column gap-4">
                <OrganisationProfileSettings pageTitle="Profil publiczny" user={user} refreshViewCallback={fetchUser} />
                <PaymentSettings pageTitle="Wypłata środków" user={user} refreshViewCallback={fetchUser} />
                <SubscriptionDetails pageTitle="Subskrypcja" />
                <SubscriptionInvoicesDetails pageTitle="Rachunki" />
            </div>

            <ListEnd content="koniec sekcji ustawień" />

            <SPToastContainer />
        </>
    );
};
