import React, { useEffect, useState } from 'react';
import { EventStatusBadge } from '../common/EventStatusBadge';
import { Link } from 'react-router-dom';
import { PermissionGate } from '../common/PermissionGate';
import { Toggler } from '../common/Toggler';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';
import { EventDate } from '../common/EventDate';
import { EventTime } from '../common/EventTime';
import { ConfirmationModal } from '../common/ConfirmationModal';
import dayjs from 'dayjs';

interface Props {
    user: any;
    event: any;
    index: int;
    refreshViewCallback: () => void;
}

export const EventCard = ({ user, event, index, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [eventRegisterSummary, setEventRegisterSummary] = useState();
    const [activeModal, setActiveModal] = useState();

    const handleDeleteEvent = (eventId) => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .delete(`/events/${eventId}`)
                .then(() => refreshViewCallback());
        });
    };

    useEffect(() => {
        getAccessTokenSilently().then((accessToken) => {
            apiClient(accessToken)
                .get(event._links.eventRegisterSummary.href)
                .then((res) => setEventRegisterSummary(res.data));
        });
    }, [event._links.eventRegisterSummary.href, getAccessTokenSilently]);

    function closeModal() {
        setActiveModal('');
    }

    function deriveCardClassName(eventStatus, eventDate) {
        const dday = dayjs(new Date(eventDate));
        const isInPast = dday.isBefore(dayjs());

        if (!isInPast) {
            return event.status === 'DRAFT' ? 'border-warning' : 'border-dark';
        }

        return 'text-secondary';
    }

    function deriveCardHeaderClassName(eventDate) {
        const dday = dayjs(new Date(eventDate));
        const isInPast = dday.isBefore(dayjs());

        return !isInPast ? 'bg-secondaryS-subtle' : '';
    }

    return (
        event && (
            <>
                <div className="d-flex flex-column align-items-center text-muted mt-4">
                    <EventDate date={event.eventDate} cn="mb-2 text-muted" separator=" :: " />
                </div>

                <div className={`card ${deriveCardClassName(event.status, event.eventDate)}`}>
                    <div className={`card-header ${deriveCardHeaderClassName(event.eventDate)}`}>
                        <div className="d-flex align-items-center p-2 gap-2">
                            <div className="fs-5 fw-light">
                                <EventTime event={event} />
                            </div>

                            <EventStatusBadge status={event.status} />

                            <div className="ms-auto"></div>

                            {/* ACTION BUTTONS */}
                            <Link className="btn btn-sm btn-outline-primary" to={`/events/${event.id}`}>
                                Zarządzaj
                            </Link>
                            {/*<Toggler condition={event.metadata.canCancelEvent}>*/}
                            {/*    <Link className="btn btn-sm btn-outline-danger" to={`/events/${event.id}`}>Odwołaj</Link>*/}
                            {/*</Toggler>*/}

                            <PermissionGate requires={user.permissions.canDeleteEvents}>
                                <Toggler condition={event.metadata.canDeleteEvent}>
                                    <ConfirmationModal
                                        showModal={activeModal === 'delete_event'}
                                        onClose={closeModal}
                                        onConfirm={() => handleDeleteEvent(event.id)}
                                        question="Czy na pewno chcesz usunąć zajęcia?"
                                    />

                                    <button
                                        className="btn btn-sm btn-outline-danger"
                                        onClick={() => setActiveModal('delete_event')}
                                    >
                                        Usuń
                                    </button>
                                </Toggler>
                            </PermissionGate>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="card-text">{event.eventName}</div>

                        <div className="small text-muted fst-italic">
                            {eventRegisterSummary?.instructorName || '...'} ::{' '}
                            {eventRegisterSummary?.studentGroupName || '...'}
                        </div>

                        <div className="d-flex justify-content-end gap-2">
                            <div className="badge rounded-pill text-bg-success">{eventRegisterSummary?.paidCount}</div>
                            <div className="badge rounded-pill text-bg-secondary">
                                {eventRegisterSummary?.noPaymentRequiredCount}
                            </div>
                            <div className="badge rounded-pill text-bg-danger">{eventRegisterSummary?.unpaidCount}</div>
                        </div>
                    </div>
                </div>
            </>
        )
    );
};
