import React, {useCallback, useEffect, useState} from "react";
import './../common.css';
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../apiClientV1";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MessagesTable} from "../components/messages/MessagesTable";
import Spinner from "../components/common/Spinner";

interface Props {
    user: any;
}

export const MessagesView = ({user}: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const [messages, setMessages] = useState(null);
    const [links, setLinks] = useState(null);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [pageState, setPageState] = useState('LOADING');


    const fetchData = useCallback((link) => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get(link.href)
                    .then(res => {
                        setMessages(res.data?.messages);
                        setCurrentPageNumber(res.data?.currentPageNumber);
                        setLinks(res.data?._links);
                        
                        setPageState('VIEW');
                    });
            });
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const sortBy = 'createdOn';
        const sortDirection = 'DESC';
        const pageNumber = currentPageNumber;
        const pageSize = 50;

        fetchData({href: `/messages?sortBy=${sortBy}&sortDirection=${sortDirection}&pageNumber=${pageNumber}&pageSize=${pageSize}`});
    }, [currentPageNumber, fetchData]);

    const deleteMessage = (link, refreshLink) => {
        setPageState('LOADING');
        
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).delete(link.href)
                    .then(() => fetchData(refreshLink));
            });
    };

    return <>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item" aria-current="page">
                    <Link to="/"><FontAwesomeIcon icon="fa-solid fa-home"/></Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Wiadomości</li>
            </ol>
        </nav>

        {pageState === 'LOADING' && <div className="text-center m-5">
            <Spinner/>
        </div>
        }

        {/************ VIEWING MESSAGES ************/}
        {pageState === 'VIEW' && <>
            {/*
            <div className="d-grid gap-2 d-flex justify-content-end m-3">
                <button className="btn btn-primary" onClick={() => setPageState('CREATE-SMS')}>Nowy SMS
                </button>
            </div>
            */}

            <MessagesTable messages={messages}
                           currentPageNumber={currentPageNumber}
                           hasPreviousPage={!!links?.previousPage}
                           hasNextPage={!!links?.nextPage}
                           canDeleteMessages={user?.permissions?.canDeleteMessages}
                           deleteMessageCallback={(link) => deleteMessage(link, links.self)}
                           fetchPreviousPageCallback={() => fetchData(links.previousPage)}
                           fetchNextPageCallback={() => fetchData(links.nextPage)}/>

        </>
        }
    </>
}
