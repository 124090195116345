import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../../apiClientV1";
import {Toggler} from "../../common/Toggler";

const STUDENT_GROUP_SELECT_PLACEHOLDER = "Wybierz Grupę";

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    nextStepCallback: () => void;
    previousStepCallback: () => void;
}

export const SelectStudentGroupForEvent = ({
                                               cancelCallback,
                                               inputValues,
                                               inputHandler,
                                               previousStepCallback,
                                               nextStepCallback
                                           }: Props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [studentGroups, setStudentGroups] = useState(null);
    const [errors, setErrors] = useState({});

    const validateInputAndCallNextStep = () => {
        let validationResult = validateInput(inputValues);
        setErrors(validationResult.errors);

        if (validationResult.isValid) {
            nextStepCallback();
        }
    }

    useEffect(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/student-groups')
                    .then(res => setStudentGroups(res?.data));
            })
    }, [getAccessTokenSilently]);

    return <>
        <div className="fs-5 fw-light">Wybierz grupę</div>

        {/************ STUDENT GROUPS ************/}
        <div className="form-floating">
            <select className={"form-select " + (errors.studentGroupId && 'is-invalid')}
                    id="studentGroupIdSelect"
                    name="studentGroupId"
                    value={inputValues.studentGroupId}
                    onChange={inputHandler}>
                <option value="" disabled={true}>{STUDENT_GROUP_SELECT_PLACEHOLDER}</option>
                <Toggler condition={!!studentGroups}>
                    {studentGroups?.map(studentGroup =>
                        <option key={studentGroup.id} value={studentGroup.id}>{studentGroup.displayName}</option>
                    )}
                </Toggler>
            </select>
            <label htmlFor="studentGroupIdSelect">{STUDENT_GROUP_SELECT_PLACEHOLDER}</label>

            {/************ DISPLAY ERROR BELOW ************/}
            {errors.studentGroupId &&
                <span id="studentGroupIdError" className="invalid-feedback">{errors.studentGroupId}</span>}
        </div>

        <hr/>

        <div className="d-grid gap-2 d-flex justify-content-center">
            {/************ CANCEL ************/}
            <button type="button" className="form-floating btn btn-outline-secondary m-2"
                    onClick={cancelCallback}>Anuluj
            </button>

            {/************ BACK ************/}
            <button type="button" className="form-floating btn btn-secondary m-2"
                    onClick={previousStepCallback}>Cofnij
            </button>

            {/************ CONTINUE ************/}
            <button type="button" className="form-floating btn btn-primary m-2"
                    onClick={validateInputAndCallNextStep}>Dalej
            </button>
        </div>
    </>
}

const validateInput = (input) => {
    let validationResult = {isValid: true, errors: {}};

    if (!input.studentGroupId || input.studentGroupId.trim().length === 0) {
        validationResult.errors.studentGroupId = 'Porszę wybrać grupę';
        validationResult.isValid = false;
    }

    return validationResult;
}