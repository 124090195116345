import React, {useCallback, useEffect, useState} from "react";
import {apiClient} from "../../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {InviteStudentsTable} from "./InviteStudentsTable";

const STUDENT_GROUP_SELECT_PLACEHOLDER = "wybierz grupę";

interface Props {
    event: any;
    user: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const AddStudentsToEvent = ({event, user, cancelCallback, refreshViewCallback}: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const [studentGroups, setStudentGroups] = useState(null);
    const [students, setStudents] = useState(null);
    const [selectedStudentGroupId, setSelectedStudentGroupId] = useState(undefined);

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/student-groups')
                    .then(result => setStudentGroups(result.data));
            })
    }, [getAccessTokenSilently])

    useEffect(() => {
        fetchData();
    }, [fetchData, getAccessTokenSilently]);

    const retrieveStudents = (studentGroupId) => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get(`/students?student-group=${studentGroupId}`)
                    .then(result => setStudents(result.data?.students));
            })
    };

    return studentGroups && user && event ? <>
            <div className="container text-center needs-validation">
                <div className="row justify-content-center m-5">
                    <div className="col-9 col-sm-6 col-md-4 col-lg-3 fw-bolder fs-5">Zaproś uczniów na zajęcia</div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-9 col-sm-6 col-md-5">

                        {/************ STUDENT GROUP SELECT ************/}
                        <div className="form-floating mb-3">
                            <select className="form-select"
                                    id="studentGroupSelect"
                                    defaultValue={"DEFAULT"}
                                    value={selectedStudentGroupId}
                                    onChange={(e) => setSelectedStudentGroupId(e.target.value)}>
                                <option value="DEFAULT" disabled>{STUDENT_GROUP_SELECT_PLACEHOLDER}</option>
                                {studentGroups && studentGroups.map(group =>
                                    <option key={group.id} value={group.id}>{group.displayName}</option>
                                )}
                            </select>
                            <label htmlFor="studentGroupSelect">{STUDENT_GROUP_SELECT_PLACEHOLDER}</label>
                        </div>
                    </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                    {/************ CANCEL ************/}
                    <button type="button" className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}>Anuluj
                    </button>

                    {/************ RETRIEVE STUDENTS FOR GROUP ************/}
                    <button type="button" className="form-floating btn btn-primary m-2"
                            disabled={!selectedStudentGroupId}
                            onClick={() => retrieveStudents(selectedStudentGroupId)}>Pokaż uczniów
                    </button>
                </div>

                {students && event && <>
                    <div className="row justify-content-center">
                        <div className="col-9 col-sm-6 col-md-5">
                            <InviteStudentsTable event={event}
                                                 studentGroupId={selectedStudentGroupId}
                                                 students={students}
                                                 refreshViewCallback={refreshViewCallback}/>
                        </div>
                    </div>
                </>}
            </div>
        </>
        :
        <div className="container text-center placeholder-glow m-5" aria-hidden="true">
            {/*LABEL*/}
            <div className="placeholder-glow">
                <span className="placeholder col-2"></span>
            </div>
            {/*DROPDOWN*/}
            <div className="placeholder-glow mt-5">
                <div className="placeholder col-5 fs-1"/>
            </div>

            {/*BUTTONS*/}
            <div className="placeholder-glow mt-5">
                <span className="btn btn-secondary disabled placeholder col-1 m-1"></span>
                <span className="btn btn-primary disabled placeholder col-1 m-1"></span>
            </div>
        </div>
}
