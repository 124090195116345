import React, {useState} from "react";
import './addUserDetails.css';
import {SPCard} from "../common/SPCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const errorState = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    termsAndConditionsConsent: false,
    dataProcessingConsent: false
};

const FIRST_NAME_PLACEHOLDER = 'imię';
const LAST_NAME_PLACEHOLDER = 'nazwisko';
const PHONE_NUMBER = 'numer telefonu';
const DATA_PROCESSING_PLACEHOLDER = 'Wyrażam zgodę na przetwarzanie danych osobowych';
const TERMS_AND_CONDITIONS_URL = 'https://drive.google.com/file/d/1T9m0ed1KgcH1kRKEHWLCsaBxrGNlFsk9/view';

interface Props {
    cancelCallback: () => void,
    proceedCallback: () => void
}

export const AddUserDetails = ({cancelCallback, proceedCallback}: Props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('PL');
    const [termsAndConditionsConsent, setTermsAndConditionsConsent] = useState(false);
    const [dataProcessingConsent, setDataProcessingConsent] = useState(false);
    const [errors, setErrors] = useState(errorState);

    const clearForm = () => {
        setErrors(errorState);

        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setTermsAndConditionsConsent(false);
        setDataProcessingConsent(false);
    }

    const handleSubmit = () => {
        const payload = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            regionCode: countryCode,
            termsAndConditionsConsent: termsAndConditionsConsent,
            dataProcessingConsent: dataProcessingConsent
        };

        const err = validate(payload);
        setErrors(err);

        if (err.isValid) {
            proceedCallback({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                phoneNumber: phoneNumber.trim(),
                regionCode: countryCode.trim(),
                termsAndConditionsConsent: termsAndConditionsConsent,
                dataProcessingConsent: dataProcessingConsent
            });
        }
    }

    return <>
        <div className="container text-center needs-validation">
            <div className="row justify-content-center m-5">
                <SPCard body={<div>Witaj! Poznajmy się bliżej.</div>}/>
                <div className="col-10 col-md-5 col-lg-4 col-xl-3 fw-bolder fs-5 mt-4">
                    Podaj swoje dane
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-10 col-md-5 col-lg-4 col-xl-3">

                    {/************ FIRST NAME ************/}
                    <div className="form-floating mb-3">
                        <input type="text"
                               className={"form-control " + (errors.firstName && 'is-invalid')}
                               id="firstName"
                               placeholder={FIRST_NAME_PLACEHOLDER}
                               value={firstName}
                               onChange={(e) => setFirstName(e.target.value)}
                               aria-describedby="firstName"/>
                        <label htmlFor="firstName">{FIRST_NAME_PLACEHOLDER}</label>

                        {/************ DISPLAY ERROR BELOW ************/}
                        {errors.firstName &&
                            <span className="invalid-feedback" id="firstNameFeedback">{errors.firstName}</span>}
                    </div>

                    {/************ LAST NAME ************/}
                    <div className="form-floating mb-3">
                        <input type="text"
                               className={"form-control " + (errors.lastName && 'is-invalid')}
                               id="lastName"
                               placeholder={LAST_NAME_PLACEHOLDER}
                               value={lastName}
                               onChange={(e) => setLastName(e.target.value)}
                               aria-describedby="lastName"/>
                        <label htmlFor="lastName">{LAST_NAME_PLACEHOLDER}</label>

                        {/************ DISPLAY ERROR BELOW ************/}
                        {errors.lastName &&
                            <span className="invalid-feedback" id="lastNameFeedback">{errors.lastName}</span>}
                    </div>

                    {/************ PHONE NUMBER ************/}
                    <div className="row mb-3">
                        <div className="col-5">
                            <div className="form-floating">
                                <input type="text"
                                       className={"form-control " + (errors.countryCode && 'is-invalid')}
                                       id="countryCode"
                                       placeholder="PL (+48)"
                                       value={countryCode}
                                       onChange={(e) => setCountryCode(e.target.value)}
                                       disabled={true}
                                       aria-describedby="countryCode"/>
                                <label htmlFor="countryCode">PL (+48)</label>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="form-floating">
                                <input type="tel"
                                       className={"form-control " + (errors.phoneNumber && 'is-invalid')}
                                       id="phoneNumber"
                                       pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                       placeholder={PHONE_NUMBER}
                                       value={phoneNumber}
                                       onChange={(e) => setPhoneNumber(e.target.value)}
                                       aria-describedby="phoneNumber"/>
                                <label htmlFor="phoneNumber">{PHONE_NUMBER}</label>

                                {/************ DISPLAY ERROR BELOW ************/}
                                {errors.phoneNumber &&
                                    <span className="invalid-feedback"
                                          id="phoneNumberFeedback">{errors.phoneNumber}</span>}
                            </div>
                        </div>

                    </div>

                    {/* TOGGLE FOR TERMS AND CONDITIONS  */}
                    <div className="form-check form-switch m-4">
                        <input className="form-check-input pointer"
                               type="checkbox"
                               role="switch"
                               id="termsAndConditionsInput"
                               onChange={() => setTermsAndConditionsConsent(!termsAndConditionsConsent)}
                               checked={termsAndConditionsConsent}
                               required={true}
                        />
                        <div className="d-flex flex-row gap-1 align-items-center">
                            <label className="form-check-label pointer" htmlFor="termsAndConditionsInput">
                                Akceptuję <a href={TERMS_AND_CONDITIONS_URL} target="_blank"
                                             rel="noreferrer">Regulamin</a>
                            </label>
                            <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square"/>
                        </div>


                        {/************ DISPLAY ERROR BELOW ************/}
                        {errors.termsAndConditionsConsent &&
                            <div className="small text-danger"
                                 id="termsAndConditionsFeedback">{errors.termsAndConditionsConsent}
                            </div>}
                    </div>

                    {/* TOGGLE FOR DATA PROCESSING  */}
                    <div className="form-check form-switch m-4">
                        <input className="form-check-input pointer"
                               type="checkbox"
                               role="switch"
                               id="dataProcessingInput"
                               onChange={() => setDataProcessingConsent(!dataProcessingConsent)}
                               checked={dataProcessingConsent}
                        />
                        <div className="">
                            <label className="form-check-label pointer tc"
                                   htmlFor="dataProcessingInput">{DATA_PROCESSING_PLACEHOLDER}</label>
                        </div>

                        {/************ DISPLAY ERROR BELOW ************/}
                        {errors.dataProcessingConsent &&
                            <div className="small text-danger"
                                 id="dataProcessingFeedback">{errors.dataProcessingConsent}
                            </div>}
                    </div>
                </div>
            </div>

            <div className="d-grid gap-2 d-flex justify-content-center m-3">
                {/************ CANCEL ************/}
                <button type="button" className="form-floating btn btn-outline-secondary m-2"
                        onClick={cancelCallback}>Anuluj
                </button>

                {/************ CLEAR THE FORM ************/}
                <button type="button" className="form-floating btn btn-outline-secondary m-2"
                        onClick={clearForm}>Wyczyść formę
                </button>

                {/************ ADD NEW STUDENT ************/}
                <button type="button" className="form-floating btn btn-primary m-2"
                        onClick={handleSubmit}>Dokończ rejestrację
                </button>
            </div>

        </div>
    </>
}

const validate = (payload) => {
    const err = {isValid: true}

    if (!payload.firstName || payload.firstName.trim().length < 2 || payload.firstName.trim().length > 50) {
        err.firstName = 'Proszę podać imię';
        err.isValid = false;
    }

    if (!payload.lastName || payload.lastName.trim().length < 2 || payload.lastName.trim().length > 50) {
        err.lastName = 'Proszę podać nazwisko';
        err.isValid = false;
    }

    if (!payload.phoneNumber || payload.phoneNumber.trim().length !== 9) {
        err.phoneNumber = 'Proszę podać poprawny numer telefonu';
        err.isValid = false;
    }

    if (!payload.termsAndConditionsConsent || payload.termsAndConditionsConsent !== true) {
        err.termsAndConditionsConsent = 'Proszę zaakceptować regulamin';
        err.isValid = false;
    }

    if (!payload.dataProcessingConsent || payload.dataProcessingConsent !== true) {
        err.dataProcessingConsent = 'Proszę zaakceptować zgodę na przetwarzanie danych osobowych';
        err.isValid = false;
    }

    return err;
}
