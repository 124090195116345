import React, {useEffect, useState} from "react";
import {apiClient} from "../../apiClientV1";
import {useAuth0} from "@auth0/auth0-react";
import {Toggler} from "../common/Toggler";
import Spinner from "../common/Spinner";
import dayjs from "dayjs";
import {EventRegisterStatusBadge} from "../common/EventRegisterStatusBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SendTimeWarningCard} from "./SendTimeWarningCard";

interface Props {
    eventRegisterItems: any;
    cancelCallback: () => void;
    refreshViewCallback: () => void;
}

export const CustomMessageSender = ({eventRegisterItems, cancelCallback, refreshViewCallback}: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const [pageState, setPageState] = useState('VIEW');
    const [selectedItems, setSelectedItems] = useState(null);
    const [students, setStudents] = useState([]);
    const [messageContent, setMessageContent] = useState('');
    const [messageSendDate, setMessageSendDate] = useState(dayjs().add(15, 'minutes').format('YYYY-MM-DDTHH:mm'));
    const [showStudents, setShowStudents] = useState(false);

    useEffect(() => {
        const filteredItems = eventRegisterItems.filter(item => item.metadata.studentHasPhoneNumber === true);
        setSelectedItems(filteredItems);

        const filteredStudents = filteredItems.map(item => item.student);
        setStudents(filteredStudents);
    }, [eventRegisterItems]);

    const handleSendMessage = (students, messageContent, sendDate) => {
        setPageState('LOADING');

        getAccessTokenSilently()
            .then(accessToken => {
                const schedule = isSchedulePastOrNext10Minutes(sendDate)
                    ? dayjs().add(10, 'minutes') // schedule in 10 minutes if currently sendDate is in the past of within 10 mins
                    : dayjs(sendDate); // if in the fufure, use the sendDate

                const formattedSchedule = dayjs(schedule, 'YYYY-MM-DDTHH:mm');

                const payload = {
                    studentIds: students.map(s => s.id),
                    deliveryMethod: 'SMS',
                    messageContent: messageContent,
                    scheduleFor: formattedSchedule,
                    messageType: 'CUSTOM_MESSAGE'
                }

                apiClient(accessToken).post('/messages', payload)
                    .then(() => setPageState('SEND-SUCCESS'));
            });
    }

    const isReadyToSend = () => {
        return students.length > 0
        && messageContent?.trim().length > 0
        && messageContent?.trim().length <= 480
        && messageSendDate?.trim() !== '' ? "true" : "";
    }

    const attendanceIcon = (attendance) => {
        switch (attendance) {
            case 'PRESENT':
                return <FontAwesomeIcon icon="fa-solid fa-user"/>
            case 'ABSENT':
                return <FontAwesomeIcon icon="fa-solid fa-user-slash"/>
            default:
                return <FontAwesomeIcon icon="fa-solid fa-question-circle"/>
        }
    }

    const isEligibleForSms = (item) => {
        return students.filter(s => s.id === item.student.id).length === 1;
    }

    const addOrRemoveStudent = (item) => {
        const isSelected = isEligibleForSms(item);
        if (isSelected) {
            setStudents(students.filter(s => s.id !== item.student.id));
        } else {
            setStudents([...students, item.student]);
        }
    }

    const addAllStudents = () => {
        setStudents(selectedItems.map(item => item.student));
    }

    const removeAllStudents = () => {
        setStudents([]);
    }

    const isSchedulePastOrNext10Minutes = (timestamp) => {
        const timestampIn15Mins = dayjs().add(10, 'minutes');
        return dayjs(timestamp).isBefore(timestampIn15Mins);
    }

    return selectedItems && <>
        <div className="container text-center mt-5">
            <div className="row justify-content-center m-5">
                <div className="col-9 col-sm-6 col-md-4 fw-bolder fs-5">Wysyłka SMS</div>
            </div>

            {/* LOADER */}
            <Toggler condition={pageState === 'LOADING'}>
                <div className="text-muted m-5">Wysyłanie...</div>
                <Spinner/>
            </Toggler>

            {/* VIEWING */}
            <Toggler condition={pageState === 'VIEW'}>

                <div>Liczba adresatów: {students.length}</div>
                <button className="btn btn-sm btn-primary m-3" onClick={() => setShowStudents(!showStudents)}>
                    Pokaż/Ukryj
                </button>

                {/* SHOW TABLE WITH MESSAGE RECIPIENTS */}
                <Toggler condition={showStudents}>
                    <hr/>

                    <div className="row justify-content-center">
                        <div className="col-9 col-sm-6 col-md-5 m-3">
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Uczeń</th>
                                    <th scope="col">Obecność</th>
                                    <th scope="col">Płatność</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                {selectedItems.map((item, index) =>
                                    <tr key={item.id}
                                        className={`${isEligibleForSms(item) ? 'table-success' : 'table-danger'}`}>
                                        <td className="pt-2">{index + 1}</td>
                                        <td className="pt-2">{item.student.firstName} {item.student.lastName}</td>
                                        <td className="pt-2">{attendanceIcon(item.attendanceType)}</td>
                                        <td className="pt-2"><EventRegisterStatusBadge status={item.status}/></td>
                                        <td className="pt-2">
                                            <button className="btn btn-sm btn-secondary"
                                                    onClick={() => addOrRemoveStudent(item)}>
                                                {isEligibleForSms(item) ? 'Wykreśl' : 'Dodaj'}
                                            </button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <button className="btn btn-sm btn-success m-1" onClick={addAllStudents}>Dodaj wszystkich</button>
                    <button className="btn btn-sm btn-danger m-1" onClick={removeAllStudents}>
                        Wykreśl wszystkich
                    </button>

                    <hr/>
                </Toggler>

                <div className="row justify-content-center">
                    <div className="col-9 col-sm-6 col-md-5 m-3">
                        <textarea className="fw-light w-100"
                                  placeholder="Wpisz treść wiadomości..."
                                  rows="4"
                                  maxLength="480"
                                  autoFocus={true}
                                  wrap="soft"
                                  onChange={(e) => setMessageContent(e.target.value)}
                                  value={messageContent}></textarea>

                        <div className="form-floating">
                            <input type="datetime-local"
                                   className="form-control"
                                   id="messageSendDate"
                                   placeholder="data & czas wysyłki"
                                   name="messageSendDate"
                                   value={messageSendDate}
                                   onChange={(e) => setMessageSendDate(e.target.value)}
                                   aria-describedby="messageSendDate"/>
                            <label htmlFor="messageSendDate">data & czas wysyłki</label>
                        </div>

                        <Toggler condition={isSchedulePastOrNext10Minutes(messageSendDate)}>
                            <div className="mt-3 mb-3">
                                    <SendTimeWarningCard />
                            </div>
                        </Toggler>
                    </div>

                    <div className="small text-muted">
                        {messageContent?.trim().length} | {Math.floor((messageContent?.trim().length - 1) / 160) + 1}
                    </div>
                </div>

                <div className="d-grid gap-2 d-flex justify-content-center m-3">
                    {/************ CANCEL ************/}
                    <button type="button" className="form-floating btn btn-outline-secondary m-2"
                            onClick={cancelCallback}>Anuluj
                    </button>

                    {/************ HANDLE SEND MESSAGE ************/}
                    <button type="button" className="form-floating btn btn-primary m-2"
                            disabled={!isReadyToSend()}
                            onClick={() => handleSendMessage(students, messageContent, messageSendDate)}>
                        Dodaj do kolejki
                    </button>
                </div>
            </Toggler>

            {/* MESSAGE SENT SUCCESSFULLY */}
            <Toggler condition={pageState === 'SEND-SUCCESS'}>
                <div className="fs-3">SMSy zostały zapisane i zostaną wysłane przy najbliższej okazji.</div>

                <button type="button" className="form-floating btn btn-primary m-2"
                        onClick={refreshViewCallback}>OK
                </button>
            </Toggler>
        </div>
    </>
}
