import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const apiClient = (accessToken) => {
    return axios.create({
        baseURL: `${baseApiUrl}/v1`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-type": "application/json"
        }
    });
};

export const apiClientPublic = () => {
    return axios.create({
        baseURL: `${baseApiUrl}/v1/public`,
        headers: {
            "Content-type": "application/json"
        }
    });
};