import React, {useCallback, useEffect, useState} from "react";
import {LearnerCard} from "../components/learners/LearnerCard";
import './../common.css';
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../apiClientV1";
import {LearnerLookup} from "../components/learners/LearnerLookup";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Toggler} from "../components/common/Toggler";

export const LearnersView = () => {
    const {getAccessTokenSilently} = useAuth0();

    const [learners, setLearners] = useState(null);
    const [pageState, setPageState] = useState('VIEW');

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/learners')
                    .then(res => setLearners(res.data))
                    .catch(() => setLearners([]));
            });
    }, [getAccessTokenSilently])

    const refreshView = () => {
        fetchData();
        setPageState('VIEW');
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <>
        {pageState === 'ADD-LEARNER' && <LearnerLookup cancelCallback={() => setPageState('VIEW')}
                                                       refreshViewCallback={() => refreshView()}/>}

        {/************ VIEWING LEARNERS ************/}
        {pageState === 'VIEW' && <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" aria-current="page">
                        <Link to="/"><FontAwesomeIcon icon="fa-solid fa-home"/></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Uczniowie</li>
                </ol>
            </nav>

            <div className="d-grid gap-2 d-flex justify-content-end m-3">
                <button className="btn btn-primary" onClick={() => setPageState('ADD-LEARNER')}>+ Dodaj ucznia
                </button>
            </div>

            {/*SHOW IF WE HAVE LEARNERS*/}
            <Toggler condition={learners?.length > 0}>
                <div className="container mt-5">
                    <div className="row">
                        {learners && learners.map(learner =>
                            <div className="col-md-6 mb-4" key={learner.id}>
                                <LearnerCard learner={learner}/>
                            </div>)}
                    </div>
                </div>
            </Toggler>

            {/*SHOW IF NO LEARNERS YET*/}
            <Toggler condition={learners?.length === 0}>
                <div className="parent">
                    <div className="child text-center display-6">Brak danych</div>
                    <div className="child text-center">możesz dodać ucznia podając jego dane personalne</div>
                </div>
            </Toggler>

            {/*SKELETON DURING DATA LOADING*/}
            {!learners && <>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card bg-light placeholder-glow p-3" aria-hidden="true">
                                <h2 className="placeholder-glow" aria-hidden="true">
                                    <span className="placeholder col-4"></span>
                                </h2>
                                <p className="placeholder-glow" aria-hidden="true">
                                    <span className="placeholder col-5"></span>
                                </p>
                                <div className="text-end">
                            <span className="btn btn-primary disabled placeholder col-2 "
                                  aria-hidden="true"></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card bg-light placeholder-glow p-3" aria-hidden="true">
                                <h2 className="placeholder-glow" aria-hidden="true">
                                    <span className="placeholder col-4"></span>
                                </h2>
                                <p className="placeholder-glow" aria-hidden="true">
                                    <span className="placeholder col-5"></span>
                                </p>
                                <div className="text-end">
                                    <span className="btn btn-primary disabled placeholder col-2"
                                          aria-hidden="true"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>}
        </>
        }
    </>
}
