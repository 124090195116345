import React from "react";
import {eventStatusToClass, eventStatusToDisplayText} from "../../lib/utils";

interface Props {
    status: any;
}

export const EventStatusBadge = ({status}: Props) => {
    return <div className={`badge rounded-pill ${eventStatusToClass(status)}`}>
        {eventStatusToDisplayText(status)}
    </div>
}