import React, { useCallback, useEffect, useState } from 'react';
import { apiClient } from '../apiClientV1';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PermissionGate } from '../components/common/PermissionGate';
import { EventCreatorWizard } from '../components/events/EventCreatorWizard';
import { EventsList } from '../components/events/EventsList';
import dayjs from 'dayjs';
import { EventDate } from '../components/common/EventDate';
import { EventCardNavigator } from '../components/events/EventCardNavigator';
import { Toggler } from '../components/common/Toggler';
import { EventFilters } from '../components/events/EventFilters';

interface Props {
    user: any;
}

export const EventsView = ({ user }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { getAccessTokenSilently } = useAuth0();

    const [eventsPage, setEventsPage] = useState(null);
    const [pageStatus, setPageStatus] = useState('VIEW-EVENTS');
    const [period] = useState('month');
    const [eventFilters, setEventFilters] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const fetchData = useCallback(
        (url) => {
            getAccessTokenSilently().then((accessToken) => {
                const payload = {
                    filters: eventFilters,
                };

                apiClient(accessToken)
                    .post(url, payload)
                    .then((res) => {
                        setEventsPage(res.data);
                    });
            });
        },
        [eventFilters, getAccessTokenSilently],
    );

    function getLink(fromDate, toDate) {
        return `/events?from=${fromDate.toISOString()}&to=${toDate.toISOString()}`;
    }

    function refreshView() {
        setPageStatus('VIEW-EVENTS');
        triggerRefresh();
    }

    function refreshViewWithFilters(filters) {
        setEventFilters(filters);
    }

    function fromDatejs() {
        return dayjs(new Date(searchParams.get('from')));
    }

    function toDatejs() {
        return dayjs(new Date(searchParams.get('to')));
    }

    function moveDate(datejs, number, period) {
        return datejs.startOf('day').add(number, period);
    }

    function updateParams(fromDate, toDate) {
        const search = {
            from: fromDate.format('YYYY-MM-DD'),
            to: toDate.format('YYYY-MM-DD'),
        };

        setSearchParams(search, { replace: true });
    }

    function triggerRefresh() {
        setRefresh(!refresh);
    }

    useEffect(() => {
        const fromParam = searchParams.get('from');
        const from = fromParam ? new Date(fromParam) : new Date();
        const fromDate = dayjs(from).startOf('day');

        const toParam = searchParams.get('to');
        const to = toParam ? new Date(toParam) : dayjs(new Date()).add(1, 'month');
        const toDate = dayjs(to).startOf('day');

        const search = {
            from: fromDate.format('YYYY-MM-DD'),
            to: toDate.format('YYYY-MM-DD'),
        };

        setSearchParams(search, { replace: true });

        fetchData(getLink(fromDate, toDate));
    }, [fetchData, searchParams, setSearchParams, refresh]);

    return eventsPage && user ? (
        <>
            {/************ CREATE EVENT ************/}
            {pageStatus === 'CREATE-EVENT' && (
                <PermissionGate requires={user.permissions.canCreateEvents}>
                    <EventCreatorWizard
                        cancelCallback={() => setPageStatus('VIEW-EVENTS')}
                        refreshViewCallback={refreshView}
                    />
                </PermissionGate>
            )}

            {/************ VIEWING EVENTS ************/}
            {pageStatus === 'VIEW-EVENTS' && (
                <>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/">
                                    <FontAwesomeIcon icon="fa-solid fa-home" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Zajęcia
                            </li>
                        </ol>
                    </nav>

                    <div className="d-grid gap-2 d-flex justify-content-end m-3">
                        <PermissionGate requires={user.permissions.canCreateEvents}>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => setPageStatus('CREATE-EVENT')}
                            >
                                + Nowe zajęcia
                            </button>
                        </PermissionGate>
                    </div>
                    {/* SHOW EVENTS DATE RANGE */}
                    <div className="container">
                        <div className="row">
                            <div className="d-flex justify-content-center text-uppercase text-muted gap-2 fw-bold fs-4">
                                <EventDate date={fromDatejs()} showWeekday={false} />
                                <div> -</div>
                                <EventDate date={toDatejs()} showWeekday={false} />
                            </div>
                        </div>

                        {/* SHOW EVENT FILTERS */}
                        <div>
                            <EventFilters refreshViewCallback={refreshViewWithFilters} />
                        </div>

                        {/* SHOW NAVIGATION BUTTON TO LOAD EARLIER EVENTS */}
                        <div className="d-flex justify-content-center m-4">
                            <Toggler condition={eventsPage.hasPreviousPage}>
                                <EventCardNavigator
                                    title="Zobacz wcześniejsze wydarzenia"
                                    loadDataCallback={() =>
                                        updateParams(moveDate(fromDatejs(), -1, period), toDatejs())
                                    }
                                />
                            </Toggler>
                        </div>

                        {/* SHOW EVENTS */}
                        <div className="row justify-content-center">
                            <EventsList
                                user={user}
                                events={eventsPage.events}
                                hasPreviousPage={!!eventsPage.hasPreviousPage}
                                hasNextPage={!!eventsPage.hasNextPage}
                                refreshViewCallback={refreshView}
                            />
                        </div>

                        {/* SHOW NAVIGATION BUTTON TO LOAD LATER EVENTS */}
                        <div className="d-flex justify-content-center m-4">
                            <Toggler condition={eventsPage.hasNextPage}>
                                <EventCardNavigator
                                    title="Zobacz późniejsze wydarzenia"
                                    loadDataCallback={() => updateParams(fromDatejs(), moveDate(toDatejs(), 1, period))}
                                />
                            </Toggler>
                        </div>
                    </div>
                </>
            )}
        </>
    ) : (
        // ************ PLACEHOLDER ************
        <>
            <div className="placeholder-glow" aria-hidden="true">
                <span className="placeholder col-1"></span>
            </div>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-end m-3 placeholder-glow" aria-hidden="true">
                <span className="btn btn-primary disabled placeholder col-1"></span>
            </div>
            <div className="placeholder-glow p-3 text-center" aria-hidden="true">
                <span className="placeholder col-7 fs-1 p-5 m-1"></span>
                <span className="placeholder col-7 fs-1 p-5 m-1"></span>
                <span className="placeholder col-7 fs-1 p-5 m-1"></span>
            </div>
        </>
    );
};
