import React, {useCallback, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {apiClient} from "../../../apiClientV1";
import {Toggler} from "../../common/Toggler";
import {SPToastContainer} from "../../common/SPToastContainer";
import {toast} from "react-toastify";

const EVENT_LOCATION_PLACEHOLDER = "lokacja";
const EVENT_CAPACITY_TYPE_PLACEHOLDER = "miejsca";

const capacityTypes = [
    {display: "bez limitu miejsc", value: 'OPEN_CAPACITY'},
//    {display: "limitowana ilość miejsc", value: 'MAX_CAPACITY'} <-- ENABLE THIS WHEN READY AND IMPLEMENTED
];

interface Props {
    cancelCallback: () => void;
    inputValues: any;
    inputHandler: (e) => void;
    nextStepCallback: () => void;
    previousStepCallback: () => void;
}

export const CollectEventLocation = ({
                                         cancelCallback,
                                         inputValues,
                                         inputHandler,
                                         previousStepCallback,
                                         nextStepCallback
                                     }: Props) => {
    const {getAccessTokenSilently} = useAuth0();

    const [pageState, setPageState] = useState('SELECT-LOCATION');
    const [locationName, setLocationName] = useState('');
    const [locations, setLocations] = useState(null);
    const [errors, setErrors] = useState({});

    const validateInputAndCallNextStep = () => {
        let validationResult = validateInput(inputValues);
        setErrors(validationResult.errors);

        if (validationResult.isValid) {
            nextStepCallback();
        }
    }

    const fetchData = useCallback(() => {
        getAccessTokenSilently()
            .then(accessToken => {
                apiClient(accessToken).get('/locations')
                    .then(res => setLocations(res?.data?.locations));
            })
    }, [getAccessTokenSilently]);

    const handleAddingNewLocation = (name) => {
        // TODO: maybe add some validation here? though we validate on backend
        getAccessTokenSilently()
            .then(accessToken => {
                const payload = {
                    locationName: name,
                    locationType: 'VENUE'
                }

                apiClient(accessToken).post('/locations', payload)
                    .then(() => fetchData())
                .catch(() => toast.error('Dodawanie nowej lokacji nie powiodło się'))
                .finally(() => {
                    setLocationName('');
                    setPageState('SELECT-LOCATION');
                });
            })
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <>
        <div className="fs-5 fw-light">Lokacja i miejsca</div>

        {/************ EVENT LOCATION ************/}
        <Toggler condition={pageState === 'SELECT-LOCATION'}>

            <div className="form-floating">
                <select className={"form-select " + (errors.locationId && 'is-invalid')}
                        id="eventLocation"
                        name="locationId"
                        value={inputValues.locationId}
                        onChange={inputHandler}>
                    <option value="" disabled>{EVENT_LOCATION_PLACEHOLDER}</option>
                    <Toggler condition={!!locations}>
                        {locations?.map(location =>
                            <option key={location.id} value={location.id}>{location.displayName}</option>
                        )}
                    </Toggler>
                </select>
                <label htmlFor="eventLocation">{EVENT_LOCATION_PLACEHOLDER}</label>

                {/************ DISPLAY ERROR BELOW ************/}
                {errors.locationId &&
                    <span id="locationError" className="invalid-feedback">{errors.locationId}</span>}

                <div className="mt-2">
                    <button className="btn btn-sm btn-outline-primary" onClick={() => setPageState('ENTER-LOCATION')}>Dodaj nową lokację</button>
                </div>
            </div>

        </Toggler>


        {/************ NEW LOCATION ************/}
        <Toggler condition={pageState === 'ENTER-LOCATION'}>

            <div className="d-flex gap-2">
                <div className="form-floating w-100">
                    <input type="text"
                        className={"form-control " + (errors.locationName && 'is-invalid')}
                        id="locationName"
                        placeholder="Nazwa lokacji, np. klasa"
                        value={locationName}
                        onChange={(e) => setLocationName(e.target.value)}
                        aria-describedby="locationName"/>
                    <label htmlFor="locationName">Nazwa lokacji, np. klasa</label>

                    {/************ DISPLAY ERROR BELOW ************/}
                    {errors.locationName &&
                        <span className="invalid-feedback" id="locationNameFeedback">{errors.locationName}</span>}
                </div>

                <button className="btn btn-sm btn-primary" onClick={() => handleAddingNewLocation(locationName)}>Dodaj</button>
            </div>

        </Toggler>


        {/************ EVENT CAPACITY ************/}
        <div className="form-floating">
            <select className={"form-select " + (errors.capacityType && 'is-invalid')}
                    id="capacityType"
                    name="capacityType"
                    value={inputValues.capacityType}
                    onChange={inputHandler}>
                <option value="" disabled>{EVENT_CAPACITY_TYPE_PLACEHOLDER}</option>
                {capacityTypes?.map(option =>
                    <option key={option.value} value={option.value}>{option.display}</option>
                )}
            </select>
            <label htmlFor="capacityType">{EVENT_CAPACITY_TYPE_PLACEHOLDER}</label>

            {/************ DISPLAY ERROR BELOW ************/}
            {errors.capacityType &&
                <span id="capacityTypeError" className="invalid-feedback">{errors.capacityType}</span>}
        </div>

        <Toggler condition={inputValues.capacityType === 'MAX_CAPACITY'}>
            <div className="d-flex flex-row align-items-center gap-2">
                <div>Limit miejsc:</div>
                <input type="number"
                       min={1} step={1}
                       name="eventCapacity"
                       value={inputValues.eventCapacity}
                       onChange={inputHandler}/>
            </div>
        </Toggler>

        <hr/>

        <div className="d-grid gap-2 d-flex justify-content-center">
            {/************ CANCEL ************/}
            <button type="button" className="form-floating btn btn-outline-secondary m-2"
                    onClick={cancelCallback}>Anuluj
            </button>

            {/************ BACK ************/}
            <button type="button" className="form-floating btn btn-secondary m-2"
                    onClick={previousStepCallback}>Cofnij
            </button>

            {/************ CONTINUE ************/}
            <Toggler condition={pageState === 'SELECT-LOCATION'}>
                <button type="button" className="form-floating btn btn-primary m-2"
                        onClick={validateInputAndCallNextStep}>Dalej
                </button>
            </Toggler>
        </div>

        <SPToastContainer />
    </>
}

const validateInput = (input) => {
    let validationResult = {isValid: true, errors: {}};

    if (!input.locationId || input.locationId.trim().length === 0) {
        validationResult.errors.locationId = 'Porszę wybrać miejsce zajęć';
        validationResult.isValid = false;
    }

    return validationResult;
}