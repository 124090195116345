import React, { useState } from 'react';
import { formatDateOnly, formatFromE164 } from '../../lib/utils';
import { Toggler } from '../common/Toggler';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../apiClientV1';
import dayjs from 'dayjs';
import { SPToastContainer } from '../common/SPToastContainer';
import { toast } from 'react-toastify';

interface Props {
    enrolmentItem: any;
    index: int;
    studentGroups: [];
    refreshViewCallback: () => void;
}

const STUDENT_GROUP_SELECT_PLACEHOLDER = 'Wybierz i dodaj do grupy';

export const EnrolmentItemCard = ({ enrolmentItem: item, index, studentGroups, refreshViewCallback }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [selectedStudentGroupId, setSelectedStudentGroupId] = useState('');

    function handleStudentUpdate(enrolmentItem) {
        getAccessTokenSilently().then((accessToken) => {
            const student = {
                firstName: enrolmentItem.studentFirstName,
                lastName: enrolmentItem.studentLastName,
                dateOfBirth: dayjs(enrolmentItem.studentDateOfBirth, 'YYYY-MM-DDTHH:mm'),
                phoneNumber: enrolmentItem.guardianPhoneNumber,
            };

            // CREATE THE STUDENT
            const createStudentRequest = { students: [student] };

            apiClient(accessToken)
                .post('/students', createStudentRequest)
                .then((response) => {
                    // AND UPDATE THE ENROLMENT ITEM WITH STUDENT ID
                    const students = response?.data?.students;

                    const request = {
                        action: 'UPDATE_STUDENT_ID',
                        studentId: students[0].id,
                    };

                    apiClient(accessToken)
                        .patch(`/enrolment-items/${enrolmentItem.id}`, request)
                        .then(() => refreshViewCallback());
                });
        });
    }

    const handleStatusUpdate = (enrolmentItem, status) => {
        getAccessTokenSilently().then((accessToken) => {
            const payload = {
                action: 'UPDATE_STATUS',
                status: status,
            };

            apiClient(accessToken)
                .patch(`/enrolment-items/${enrolmentItem.id}`, payload)
                .then(() => refreshViewCallback());
        });
    };

    function refresh() {
        setSelectedStudentGroupId('');
        refreshViewCallback();
        toast.success('Dodano ucznia do grupy');
    }

    function handleAddStudentToGroup(studentGroupId, studentId) {
        getAccessTokenSilently().then((accessToken) => {
            const addStudentsToStudentGroupRequest = {
                action: 'ADD_STUDENTS_TO_STUDENT_GROUP',
                studentIds: [studentId],
            };

            apiClient(accessToken)
                .patch(`/student-groups/${studentGroupId}`, addStudentsToStudentGroupRequest)
                .then(() => refresh())
                .catch(() => toast.error('Nie udało się dodać ucznia do grupy!'));
        });
    }

    return (
        <div className="card m-3 enrolment-item-card">
            <SPToastContainer />

            <div className="card-header d-flex justify-content-end gap-2">
                <Toggler condition={!item.metadata?.isStudent}>
                    <button className="btn btn-sm btn-outline-primary" onClick={() => handleStudentUpdate(item)}>
                        Dodaj jako ucznia
                    </button>
                </Toggler>

                {/*<pre>{JSON.stringify(selectedStudentGroupId, null, 2)}</pre>*/}

                <Toggler condition={item.metadata?.isStudent && !!studentGroups}>
                    <div className="d-flex gap-2">
                        <select
                            className="form-select"
                            id="studentGroupIdSelect"
                            name="studentGroupId"
                            value={selectedStudentGroupId}
                            onChange={(e) => setSelectedStudentGroupId(e.target.value)}
                        >
                            <option value="">{STUDENT_GROUP_SELECT_PLACEHOLDER}</option>
                            {studentGroups?.map((studentGroup) => (
                                <option key={studentGroup.id} value={studentGroup.id}>
                                    {studentGroup.displayName}
                                </option>
                            ))}
                        </select>

                        <button
                            className="btn btn-primary"
                            disabled={selectedStudentGroupId === ''}
                            onClick={() => handleAddStudentToGroup(selectedStudentGroupId, item.studentId)}
                        >
                            Dodaj
                        </button>
                    </div>
                </Toggler>

                {/*DISABLE FOR THE TIME BEING. ENABLE ONCE WE IMPLEMENT THE OPTION TO VIEW ARCHIVED ITEMS*/}
                <Toggler condition={item.metadata?.canArchive && false}>
                    <button
                        className="btn btn-sm btn-outline-warning"
                        onClick={() => handleStatusUpdate(item, 'ARCHIVED')}
                    >
                        Archiwizuj
                    </button>
                </Toggler>
            </div>

            <div className="card-body">
                <div className="card-title fs-5 fw-bold d-flex gap-2">
                    <div className="text-muted fw-light">{index + 1}.</div>
                    <div>
                        {item.studentLastName}, {item.studentFirstName} (ur. {formatDateOnly(item.studentDateOfBirth)})
                    </div>
                </div>

                <div className="text-muted fw-light mb-3">
                    <div>
                        Rodzic/Opiekun: {item.guardianFirstName} {item.guardianLastName}
                    </div>
                    <div>
                        {formatFromE164(item.guardianPhoneNumber)} {item.guardianEmail && ':: ' + item.guardianEmail}
                    </div>
                </div>

                <div>Miejscowość: {item.studentSchoolLocation}</div>
            </div>
        </div>
    );
};
